import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { throwFirstError } from '../../../utils/error';
import * as DashboardDucks from '../../Ducks';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';
import constants from '../../../shared/constants';
import SituacoesEnergia from './components/dashboard';
import SituacoesEnergiaLoading from './components/loading';

class Screen extends Component {
  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.fetchSituacoesEnergia();
  };

  fetchSituacoesEnergia = async () => {
    const {
      dataInicial,
      dataFinal,
      fetchSituacoesEnergia,
      throwSnackbar,
    } = this.props;

    try {
      return await fetchSituacoesEnergia(
        {
          dataInicial,
          dataFinal,
        },
        constants.SETUP.defaultDelay.slow,
      );
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.errorOnLoadRecords('situaçōes'));
      }
    }
    return null;
  };

  render() {
    const {
        situacoesEnergia: { data, loading },
    } = this.props;

    const hasData = Boolean(data);
    const isLoading = loading && !hasData;

    return isLoading || !hasData ? <SituacoesEnergiaLoading /> : <SituacoesEnergia data={data} />;
  }
}

Screen.propTypes = {
  fetchSituacoesEnergia: PropTypes.func.isRequired,
  situacoesEnergia: PropTypes.object.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  dataInicial: PropTypes.string.isRequired,
  dataFinal: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  situacoesEnergia: state.dashboard.situacoesEnergia,
  dataInicial: state.dashboard.dataInicial,
  dataFinal: state.dashboard.dataFinal,
});

const mapDispatchToProps = {
  fetchSituacoesEnergia: DashboardDucks.fetchSituacoesEnergia,
  resetSituacoesEnergia: DashboardDucks.resetSituacoesEnergia,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Screen);
