import { CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '../../../../components';

const styles = () => ({
  title: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  text: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
    textAlign: 'left',
    display: 'block',
  },
});

class Success extends PureComponent {
  render() {
    const { onBack, classes } = this.props;
    return (
      <CardBody>
        <Typography align="center" gutterBottom className={classes.title}>
          Verifique seu email
        </Typography>

        <Typography variant="ghost" gutterBottom className={`${classes.text} mt-2`}>
          Enviamos a você um email com um link para concluir a redefinição de sua senha.
        </Typography>

        <Typography variant="ghost" gutterBottom className={`${classes.text} mt-2`}>
          Não localizou o email? Verifique sua pasta de spam.
        </Typography>

        <Typography variant="ghost" gutterBottom className={`${classes.text} mt-2`}>
          Caso ainda não consiga fazer login, reenvie-nos o email ou entre em contato com a
          equipe de atendimento da PetMap.
        </Typography>

        <Row>
          <Col xs="12">
            <div className="text-center mt-2 mb-2">
              <Button
                onClick={onBack}
                color="secondary"
                type="submit"
                gutter="right"
                block
              >
                Retornar ao login
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    );
  }
}

Success.propTypes = {
  onBack: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Success);
