export const defaultDelay = {
  fast: 250,
  normal: 350,
  slow: 1000,
  slow2x: 2000,
  slow3x: 3000,
};

export const dateFormat = 'DD/MM/YYYY';
export const horaFormat = 'HH:mm';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm';

export const __version__ = process.env.REACT_APP_VERSION;

export const __dev__ = process.env.NODE_ENV === 'development';

export const __env__ = process.env.REACT_APP_ENV;

export const __prod__ = __env__ === 'PRODUCAO';

export const appName = 'Rastreador';

export const diasMesCompleto = 31;

export const SEXO_OPTIONS = [
  { label: 'MASCULINO', value: 'MASCULINO' },
  { label: 'FEMININO', value: 'FEMININO' },
  { label: 'PREFIRO NÃO INFORMAR', value: 'PREFIRO_NAO_INFORMAR' },
];

export const TIPO_VISUALIZACAO = [
  { label: 'LOCALIZAÇÃO ATUAL', value: 'LOCALIZACAO_ATUAL' },
  { label: 'HISTÓRICO DE LOCALIZAÇÃO', value: 'HISTORICO_LOCALIZACAO' },
];

export const PROTOCOLOS_RASTREADORES = [
  { label: 'TOPIN', value: 'TOPIN' },
  { label: 'OUTRO', value: 'OUTRO' },
];

export const PERIODO_ULTRA_ECONOMIA_BATERIA = [
  { label: '30 MINUTOS', value: 'TRINTA_MIN' },
  { label: '1 HORA', value: 'UMA_HORA' },
  { label: '2 HORAS', value: 'DUAS_HORAS' },
  { label: '6 HORAS', value: 'SEIS_HORAS' },
  { label: '12 HORAS', value: 'DOZE_HORAS' },
  { label: '24 HORAS', value: 'VINTE_QUATRO_HORAS' },
];
