import { Card, Row, Col } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ItemPlaceholder } from '../../../../../../components/Placeholder';

const styles = theme => ({
  item: {
    flex: 1,
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  item2: {
    height: 43,
    marginRight: theme.spacing.unit * 2,
    width: 43,
  },
  item3: {
    height: 12,
    marginBottom: theme.spacing.unit,
    width: 60,
  },
  item4: {
    height: 12,
    marginBottom: theme.spacing.unit,
    width: '100%',
  },
});

class Loading extends PureComponent {
  renderItem = () => {
    const { classes } = this.props;
    return (
      <Col lg="3" sm="6">
        <Card className="card-stats">
          <Card.Body>
            <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <ItemPlaceholder className={classes.item2} />
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category"><ItemPlaceholder className={classes.item3} /></p>
                    <Card.Title as="h4"><ItemPlaceholder className={classes.item4} /></Card.Title>
                  </div>
                </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  render() {
    const Item = this.renderItem;
    return (
      <>
        <Item />
        <Item />
        <Item />
        <Item />
      </>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
