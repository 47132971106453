import { stringToDate } from '../helpers/date';
import * as dateValidate from './date';
import * as messages from '../messages';
import * as validators from '../validators';

import validateDateTime, {
  isDateValid as checkValidDateTime,
} from './datetime';

export const validateRule = (ruleName, ruleValue, value, values) => {
  switch (ruleName) {
    case 'required': {
      const hasError = validators.isEmpty(value);
      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_REQUIRED : '',
      };
    }
    case 'email': {
      const hasError = !validators.isEmail(value);
      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_EMAIL_INVALID : '',
      };
    }
    case 'cpf': {
      const hasError = !validators.isCpf(value);
      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_CPF_INVALID : '',
      };
    }
    case 'date': {
      const rule = dateValidate.getRuleName(ruleName, ruleValue, value, values);

      if (typeof rule === 'string') {
        return validateRule(rule, ruleValue, value, values);
      }

      return validateRule(rule.ruleName, rule.ruleValue, value, values);
    }
    case 'datetime': {
      const message = validateDateTime(value, ruleValue, values);
      const hasError = Boolean(message);
      const isValidDate = checkValidDateTime(value);
      return {
        hasError,
        ruleValue: isValidDate ? ruleValue : 'isDate',
        message: isValidDate ? message : messages.FIELD_DATE_INVALID,
      };
    }
    case 'isDate': {
      const hasError = !validators.isDate(value);
      return {
        hasError,
        ruleValue: typeof ruleValue === 'boolean' ? ruleValue : true,
        message: hasError ? messages.FIELD_DATE_INVALID : '',
      };
    }
    case 'isDateGreaterThanToday': {
      const hasError = !validators.isDateGreaterThan(
        stringToDate(value),
        new Date()
      );

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_GREATER_THAN_TODAY : '',
      };
    }
    case 'isDateGreaterThanTodayOrEqualToday': {
      const hasError =
        !validators.isDateGreaterThan(stringToDate(value), new Date()) &&
        !validators.isDateEqualTo(stringToDate(value), new Date());

      return {
        hasError,
        ruleValue,
        message: hasError
          ? messages.FIELD_DATE_GREATER_THAN_OR_EQUAL_TODAY
          : '',
      };
    }
    case 'isDateLessThanToday': {
      const hasError = !validators.isDateLessThan(
        stringToDate(value),
        new Date()
      );

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_LESS_THAN_TODAY : '',
      };
    }
    case 'isDateLessThanTodayOrEqualToday': {
      const hasError =
        !validators.isDateLessThan(stringToDate(value), new Date()) &&
        !validators.isDateEqualTo(stringToDate(value), new Date());

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_LESS_THAN_OR_EQUAL_TODAY : '',
      };
    }
    case 'isDateGreaterThanField': {
      const { context } = dateValidate.getOperatorSetup(ruleValue);
      const valueField = values[context];

      if (!validators.isDate(valueField)) {
        return validateRule('isDate', ruleValue, value, values);
      }

      const hasError = !validators.isDateGreaterThan(
        stringToDate(value),
        stringToDate(valueField)
      );

      return {
        hasError,
        ruleValue,
        message: hasError
          ? messages.FIELD_DATE_GREATER_THAN_FIELD_X(context)
          : '',
      };
    }
    case 'isDateGreaterThanFieldOrEqualField': {
      const { context } = dateValidate.getOperatorSetup(ruleValue);
      const valueField = values[context];

      if (!validators.isDate(valueField)) {
        return validateRule('isDate', ruleValue, value, values);
      }

      const hasError =
        !validators.isDateGreaterThan(
          stringToDate(value),
          stringToDate(valueField)
        ) &&
        !validators.isDateEqualTo(
          stringToDate(value),
          stringToDate(valueField)
        );

      return {
        hasError,
        ruleValue,
        message: hasError
          ? messages.FIELD_DATE_GREATER_THAN_OR_EQUAL_FIELD_X(context)
          : '',
      };
    }
    case 'isDateLessThanField': {
      const { context } = dateValidate.getOperatorSetup(ruleValue);
      const valueField = values[context];

      if (!validators.isDate(valueField)) {
        return validateRule('isDate', ruleValue, value, values);
      }

      const hasError = !validators.isDateLessThan(
        stringToDate(value),
        stringToDate(valueField)
      );

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_LESS_THAN_FIELD_X(context) : '',
      };
    }
    case 'isDateLessThanFieldOrEqualField': {
      const { context } = dateValidate.getOperatorSetup(ruleValue);
      const valueField = values[context];

      if (!validators.isDate(valueField)) {
        return validateRule('isDate', ruleValue, value, values);
      }

      const hasError =
        !validators.isDateLessThan(
          stringToDate(value),
          stringToDate(valueField)
        ) &&
        !validators.isDateEqualTo(
          stringToDate(value),
          stringToDate(valueField)
        );

      return {
        hasError,
        ruleValue,
        message: hasError
          ? messages.FIELD_DATE_LESS_THAN_OR_EQUAL_FIELD_X(context)
          : '',
      };
    }
    case 'notWeekend': {
      const hasError = validators.isDateWeekend(stringToDate(value));

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_SHOULD_BE_WORKING_DAY : '',
      };
    }
    case 'notSunday': {
      const hasError = validators.isDateSunday(stringToDate(value));

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_SHOULD_NOT_BE_SUNDAY : '',
      };
    }
    case 'match': {
      const hasError = !validators.isMatch(ruleValue, value);
      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_NOT_MATCH : '',
      };
    }
    case 'hour': {
      const hasError = !validators.isHour(value, values[ruleValue]);
      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_HOUR_INVALID : '',
      };
    }
    case 'underAge': {
      const { context: underAge } = dateValidate.getOperatorSetup(ruleValue);
      const hasError = !validators.isUnderAge(value, underAge);

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_UNDER_THAN_AGE(underAge) : '',
      };
    }
    case 'overAge': {
      const { context: overAge } = dateValidate.getOperatorSetup(ruleValue);
      const hasError = !validators.isOverAge(value, overAge);

      return {
        hasError,
        ruleValue,
        message: hasError ? messages.FIELD_DATE_OVER_THAN_AGE(overAge) : '',
      };
    }
    default:
      throw new TypeError(`Invalid rule type ${ruleName}.`);
  }
};

const generateError = (error, schemaMessages) => {
  const { ruleName, ruleValue, ruleType, hasError, message } = error;

  let schemaMessage = message;
  const ruleMessages = schemaMessages[ruleName];
  if (ruleMessages) {
    const ruleMessageValue = ruleMessages[ruleType];
    if (typeof ruleMessageValue === 'string') {
      schemaMessage = ruleMessageValue;
    }
    if (Array.isArray(ruleMessageValue)) {
      const msg = ruleMessageValue.find((rm) => rm[ruleValue] !== undefined);
      schemaMessage = msg ? msg[ruleValue] : message;
    }
  }

  return {
    errorStatus: hasError,
    errorText: schemaMessage,
  };
};

const validateSchema = (schema, values) => {
  if (schema == null || typeof schema !== 'object') {
    throw new TypeError('Schema should be an object.');
  }

  const { rules } = schema;
  const errors = [];

  Object.keys(rules).forEach((item) => {
    // one field
    const fieldIsRequired = rules[item].required;

    const ruleName = item;
    const ruleTypes = rules[item];
    const value = values[ruleName] || '';
    let error = { errorStatus: false, errorText: '' };

    const ruleTypesKeys = Object.keys(ruleTypes);
    let ruleHasError = false;
    for (let j = 0; !ruleHasError && j < ruleTypesKeys.length; j += 1) {
      const ruleType = ruleTypesKeys[j];
      const ruleValue = ruleTypes[ruleType];
      const isRuleEnable = ruleValue !== false;

      if (
        (isRuleEnable && value.length !== 0) ||
        (fieldIsRequired && isRuleEnable)
      ) {
        const validation = validateRule(ruleType, ruleValue, value, values);
        const { hasError, message, ruleValue: ruleValueValidated } = validation;
        const schemaMessages = Object.assign({}, schema.messages); // eslint-disable-line

        if (hasError) {
          error = generateError(
            {
              ruleName,
              ruleValue: ruleValueValidated,
              ruleType,
              hasError,
              message,
            },
            schemaMessages
          );
          ruleHasError = true;
        }
      }
    }
    errors[ruleName] = error;
  });

  return errors;
};

export default validateSchema;
