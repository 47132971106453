import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import React from 'react';

import Select from './Select';

const ReactSelectWithReduxForm = ({
  input: { onBlur, value, ...inputProps },
  meta: { touched, error },
  ...props
}) => {
  const hasError = Boolean(touched && error);

  return (
    <>
      <Select
        creatable={false}
        error={hasError}
        value={value}
        onBlur={() => onBlur(value)}
        {...inputProps}
        {...props}
      />
      {hasError && (
        <FormHelperText className="x-error" error>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

ReactSelectWithReduxForm.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ReactSelectWithReduxForm;
