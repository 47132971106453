import moment from 'moment';

import * as messages from '../messages';

const FORMAT = 'DD/MM/YYYY HH:mm';

const validate = (validation, message) => (validation ? '' : message);

const isWeekend = date => [6, 0].includes(moment(date).day());

export const isDateValid = date => {
  const dateTimeMoment = moment(date, FORMAT, true);
  return dateTimeMoment.isValid() && dateTimeMoment.year() >= 1000 && dateTimeMoment.year() <= 3000;
};

const validateDateTime = (dateTime, rule, values) => {
  if (!rule || !dateTime) {
    return '';
  }

  if (Array.isArray(rule)) {
    return rule.map(r => validateDateTime(dateTime, r, values)).find(Boolean) || '';
  }

  if (typeof rule === 'string') {
    if (!isDateValid(dateTime)) {
      return messages.FIELD_DATE_INVALID;
    }

    const dateTimeMoment = moment(dateTime, FORMAT, true);

    const [operator, context] = rule.split(' ');

    const isNow = context === 'now';
    const otherDate = isNow ? moment() : moment(values[context], FORMAT);

    if (otherDate.isValid()) {
      switch (operator) {
        case '>':
          return validate(
            dateTimeMoment.isAfter(otherDate),
            isNow
              ? messages.FIELD_DATE_GREATER_THAN_NOW
              : messages.FIELD_DATE_GREATER_THAN_FIELD_X(context),
          );
        case '>=':
          return validate(
            dateTimeMoment.isSameOrAfter(otherDate),
            isNow
              ? messages.FIELD_DATE_GREATER_THAN_OR_EQUAL_NOW
              : messages.FIELD_DATE_GREATER_THAN_OR_EQUAL_FIELD_X(context),
          );
        case '<':
          return validate(
            dateTimeMoment.isBefore(otherDate),
            isNow
              ? messages.FIELD_DATE_LESS_THAN_NOW
              : messages.FIELD_DATE_LESS_THAN_FIELD_X(context),
          );
        case '<=':
          return validate(
            dateTimeMoment.isSameOrBefore(otherDate),
            isNow
              ? messages.FIELD_DATE_LESS_THAN_OR_EQUAL_NOW
              : messages.FIELD_DATE_LESS_THAN_OR_EQUAL_FIELD_X(context),
          );
        default:
      }
    } else {
      switch (operator) {
        case 'notWeekend':
          return validate(!isWeekend(dateTimeMoment), messages.FIELD_DATE_SHOULD_BE_WORKING_DAY);
        case 'notSunday':
          return validate(dateTimeMoment.day() !== 0, messages.FIELD_DATE_SHOULD_NOT_BE_SUNDAY);
        default:
      }
    }

    const age = +context;
    if (!Number.isNaN(age)) {
      switch (operator) {
        case 'underAge':
          return validate(
            moment().diff(dateTimeMoment, 'years') < age,
            messages.FIELD_DATE_UNDER_THAN_AGE(age),
          );
        case 'overAge':
          return validate(
            moment().diff(dateTimeMoment, 'years') >= age,
            messages.FIELD_DATE_OVER_THAN_AGE(age),
          );
        default:
      }
    }

    return '';
  }

  return validate(isDateValid(dateTime), messages.FIELD_DATE_INVALID);
};

export default validateDateTime;
