import { Container } from 'react-bootstrap';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const year = new Date().getFullYear();

const styles = () => ({
  footer: {
    height: 60,
    background: 'none',
    float: 'none',
  },
  copyright: {
    float: 'none !important',
  },
  text: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
  },
  textLink: {
    color: '#1877f2',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
    '&:hover': {
      color: '#1877f2',
      textDecoration: 'underline',
    },
  },
});

class FooterLogin extends Component {
  render() {
    const { classes } = this.props;
    return (
      <footer className={`footer ${classes.footer} px-0 px-lg-3 mt-3`}>
        <Container fluid>
          <nav>
            <p className={`copyright ${classes.copyright} text-center`}>
              Copyright © {year}{' '}
              <a
                href="https://petmap.com.br"
                target="blank"
                className={classes.textLink}
              >
                PetMap
              </a>
              . Todos os Direitos Reservados
            </p>
          </nav>
        </Container>
      </footer>
    );
  }
}

FooterLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FooterLogin);
