import 'moment/locale/pt-br';
import * as moment from 'moment';

import constants from '../shared/constants';

export const dateMaskedToISO = (dateMasked) =>
  moment(dateMasked, constants.SETUP.dateFormat).toISOString();

export const dateTimeMaskedToISO = (dateMasked) =>
  moment(dateMasked, constants.SETUP.dateTimeFormat).toISOString(true);

export const currentDateMasked = (pattener = constants.SETUP.dateFormat) =>
  moment().format(pattener);

export const currentHoraMasked = (pattener = constants.SETUP.horaFormat) =>
  moment().format(pattener);

export const dateISOToDateMasked = (ISOString) =>
  moment(ISOString).format(constants.SETUP.dateFormat);

export const dateTimeISOToDateMasked = (ISOString) =>
  moment(ISOString).format(constants.SETUP.dateTimeFormat);

export const dateISOToDateTime = (ISOString) =>
  ISOString && moment(ISOString).format(constants.SETUP.dateTimeFormat);

export const beginDate = (
  dias = constants.SETUP.diasMesCompleto,
  pattener = constants.SETUP.dateFormat
) => moment().subtract(dias, 'days').format(pattener);
