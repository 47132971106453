import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Row, Col, Spinner } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Typography, Button } from '../../../components';

import IconBike from '../../../assets/img/icon_map/local.png';
import IconDesligar from '../../../assets/img/icon_map/desligar.png';
import IconDesligarHover from '../../../assets/img/icon_map/desligar_hover.png';
import IconLocalEnd from '../../../assets/img/icon_map/local_end.png';
import IconLocalStart from '../../../assets/img/icon_map/local_start.png';

const styles = () => ({
  infos: {
    fontFamily: 'Roboto,Arial',
    lineHeight: '17px',
    fontSize: '13px',
  },
  text: {
    fontWeight: 'bold',
  },
  icon_desligar: {
    backgroundImage: `url(${IconDesligar})`,
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    float: 'left',
    width: 27,
    height: 30,

    '&:hover': {
      backgroundImage: `url(${IconDesligarHover})`,
    },
  },
});

const Mapa = ({
  loading,
  rota,
  rastreadores,
  defaultCenter,
  classes,
  tipoVisualizacao,
  onOpenDesligar,
  onSincronizar,
}) => {
  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCaYYCBXQGDeH0A1j0sSowpW2KLq-mgKiQ',
  });

  const handleActiveMarker = (marker) => {
    if (tipoVisualizacao === 'LOCALIZACAO_ATUAL') {
      setActiveMarker(marker);
    }
  };

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds(); // eslint-disable-line
    rastreadores.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  const renderBotaoDesligar = (rastreador) => {
    const { desligar } = rastreador;
    return (
      desligar && (
        <div
          alt="Desligar"
          onClick={() => onOpenDesligar(rastreador)}
          aria-hidden="true"
          className={classes.icon_desligar}
        />
      )
    );
  };

  const renderInfos = (rastreador) => {
    const { position, name, bateria, situacaoEnergia } = rastreador;
    const { lat, lng } = position;
    return (
      <div className={classes.infos}>
        <div className={classes.text}>{name}</div>
        <div>Latitude: {lat || '-'}</div>
        <div>Longitude: {lng || '-'}</div>
        <div>
          Bateria: {bateria != null && bateria >= 0 ? `${bateria} %` : '-'}
        </div>
        {/* <div>Precisão: {precisao || '-'}</div> */}
        <div>Situação: {situacaoEnergia}</div>

        <div className="mt-2">{renderBotaoDesligar(rastreador)}</div>
      </div>
    );
  };

  const renderLoading = () => (
    <Row align="center">
      <Col md="12">
        <Spinner animation="border" role="status">
          <span className="sr-only">Sincronizando...</span>
        </Spinner>
      </Col>
      <Col md="12">
        <Typography variant="caption">
          Sincronização solicitada, aguarde e busque novamente...
        </Typography>
      </Col>
    </Row>
  );

  const renderEmpty = () => (
    <Row>
      <Col xs="12">
        <Typography variant="body2" align="center" gutterTop="2x">
          Nenhum rastreador encontrado
        </Typography>
      </Col>
    </Row>
  );

  const getIconBike = (index) => {
    if (tipoVisualizacao === 'HISTORICO_LOCALIZACAO' && rota.length) {
      if (index === 0) {
        return IconLocalStart;
      } else if (index === rastreadores.length - 1) {
        return IconLocalEnd;
      }
    }
    return IconBike;
  };

  const renderMarker = () =>
    rastreadores.map((rastreador, index) => (
      <Marker
        key={`${rastreador.id}-${index}`}
        position={rastreador.position}
        icon={getIconBike(index)}
        onClick={() => handleActiveMarker(rastreador.id)}
      >
        {activeMarker === rastreador.id && (
          <InfoWindow
            position={rastreador.position}
            onCloseClick={() => setActiveMarker(null)}
          >
            {renderInfos(rastreador)}
          </InfoWindow>
        )}
      </Marker>
    ));

  const renderPolyline = () =>
    rota.length && (
      <Polyline
        path={rota}
        defaultVisible
        visible
        geodesic
        options={{
          travelMode: 'TRANSIT',
          strokeColor: '#000000',
          strokeOpacity: 0.75,
          strokeWeight: 2,
          icons: [
            {
              icon: IconBike,
              offset: '0',
              repeat: '20px',
            },
          ],
        }}
      />
    );

  const renderBotaoSincronizar = () => (
    <Button
      className="nc-icon nc-refresh-02 mb-3 ml-3"
      variant="outline-secondary"
      loading={loading}
      displayText={false}
      onClick={onSincronizar}
    />
  );

  const renderMap = () => (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ height: '60vh' }}
      center={defaultCenter[0]}
      zoom={10}
    >
      {renderMarker()}

      {renderPolyline()}
    </GoogleMap>
  );

  const render = () => {
    if (Array.isArray(rastreadores) && rastreadores.length) {
      const BotaoSincronizar = renderBotaoSincronizar;

      return (
        <Row>
          <Col xs="12">
            <BotaoSincronizar />
            {loading && renderLoading()}
            {(!loading || !isLoaded) && renderMap()}
          </Col>
        </Row>
      );
    }
    return renderEmpty();
  };

  return render();
};

Mapa.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  rastreadores: PropTypes.array.isRequired,
  defaultCenter: PropTypes.array.isRequired,
  rota: PropTypes.array,
  tipoVisualizacao: PropTypes.string.isRequired,
  onOpenDesligar: PropTypes.func.isRequired,
  onSincronizar: PropTypes.func.isRequired,
};

Mapa.defaultProps = {
  rota: [],
};

export default withStyles(styles)(Mapa);
