import toString from '../helpers/toString';
import { escapeArrayRegExp } from '../helpers/escapeRegExp';

const isMatch = (regExp, value) => {
  try {
    if (Array.isArray(regExp)) {
      const valueArray = value.split('');

      return escapeArrayRegExp(regExp).every((item, index) => {
        const currentValue = valueArray[index];
        return new RegExp(item).test(currentValue);
      });
    }
    return regExp.test(toString(value));
  } catch (error) {
    throw Error('Match validation requires a valid regExp expression.');
  }
};

export default isMatch;
