import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { toString } from '../../modules/utils/text';
import Typography from '../Typography';

const styles = (theme) => ({
  textRoot: {
    alignItems: 'center',
    display: 'flex',
    minHeight: 36,
  },
  small: {
    marginTop: theme.spacing.unit * 1.5,
  },
  normal: {
    marginTop: theme.spacing.unit * 2,
  },
  large: {
    marginTop: theme.spacing.unit * 3,
  },
  text: {
    flex: 1,
    width: '100%',
    wordBreak: 'break-all',
    color: 'rgba(69, 90, 100, 0.87)'
  },
  label: {
    textTransform: 'none !important',
    flex: 1,
    width: '100%',
    wordBreak: 'break-all',
  },
  startAdornment: {
    display: 'inline-flex',
    marginRight: theme.spacing.unit / 2,
  },
  endAdornment: {
    display: 'inline-flex',
    marginLeft: theme.spacing.unit / 2,
  },
});

class DisplayInfo extends PureComponent {
  renderStartAdornment = ({ startAdornment, classes }) =>
    startAdornment && (
      <Typography component="div" className={classes.startAdornment}>
        {startAdornment}
      </Typography>
    );

  renderEndAdornment = ({ endAdornment, classes }) =>
    endAdornment && (
      <Typography component="div" className={classes.endAdornment}>
        {endAdornment}
      </Typography>
    );

  render() {
    const {
      classes,
      formControlProps,
      fullWidth,
      label,
      margin,
      size,
      uppercase,
      value,
    } = this.props;

    return (
      <FormControl fullWidth={fullWidth} margin={margin} {...formControlProps}>
        <Typography className={classNames(classes.label)}>{label}</Typography>
        <div className={classNames(classes.textRoot, classes[size])}>
          {this.renderStartAdornment(this.props)}
          <Typography className={classes.text}>
            {uppercase ? toString(value).toUpperCase() : value}
          </Typography>
          {this.renderEndAdornment(this.props)}
        </div>
      </FormControl>
    );
  }
}

DisplayInfo.defaultProps = {
  endAdornment: null,
  formControlProps: null,
  fullWidth: true,
  inputLabelProps: null,
  margin: 'dense',
  size: 'normal',
  startAdornment: null,
  uppercase: false,
};

DisplayInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  endAdornment: PropTypes.node,
  formControlProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  inputLabelProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  startAdornment: PropTypes.node,
  uppercase: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withStyles(styles, { name: 'DisplayInfo' })(DisplayInfo);
