import { axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';

const urlRest = '/api/relatorios';

export const fetchTotalizadores = (params, delay = 0) => {
    const request = axiosPrivate.get(`${urlRest}/totalizadores`, {
      params,
  });
  return pMinDelay(request, delay);
};

export const fetchSituacoes = (params, delay = 0) => {
  const request = axiosPrivate.get(`${urlRest}/situacoes`, {
    params,
  });
  return pMinDelay(request, delay);
};

export const fetchSituacoesEnergia = (params, delay = 0) => {
  const request = axiosPrivate.get(`${urlRest}/situacoes-energia`, {
    params,
  });
  return pMinDelay(request, delay);
};
