import { axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';

const urlRest = '/api/usuarios';

export const fetchUsuariosByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlRest, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};

export const getUsuario = (id, delay = 0) => {
  const request = axiosPrivate.get(`${urlRest}/${id}`);
  return pMinDelay(request, delay);
};

export const saveUsuario = (usuario, delay = 0) => {
  const request = axiosPrivate.post(urlRest, usuario);
  return pMinDelay(request, delay);
};

export const getUsuarioMinhaConta = (id, delay = 0) => {
  const request = axiosPrivate.get(`/users/${id}`);
  return pMinDelay(request, delay);
};

export const saveMinhaConta = (usuario, delay = 0) => {
  const request = axiosPrivate.post('/users', usuario);
  return pMinDelay(request, delay);
};

export const toggleAtivoInativo = (data, delay = 0) => {
  if (data.situacao) {
    return pMinDelay(axiosPrivate.delete(`${urlRest}/inativar/${data.id}`), delay);
  }
  return pMinDelay(axiosPrivate.put(`${urlRest}/ativar/${data.id}`), delay);
};
