import { store } from '../../../store';
import roles from './roles';

import { hasPermission } from './helper';

export const getSelfAuthorities = () => {
  const { login } = store.getState();

  if (login) {
    const { usuario } = login;
    if (usuario) {
      const { authorities } = usuario;
      return Array.isArray(authorities) ? authorities : [];
    }
  }

  return [];
};

export const hasSelfPermission = routePermissions =>
  hasPermission(getSelfAuthorities(), routePermissions);

export { roles };
