import { CardBody } from 'reactstrap';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TextField, Button, Typography, MaskTextField } from '../../../../components';
import paths from '../../paths';

const styles = () => ({
  title: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  text: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
  },
  textLink: {
    color: '#1877f2',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
    '&:hover': {
      color: '#1877f2',
      textDecoration: 'underline',
    },
  },
});

class Form extends PureComponent {
  render() {
    const { submitting, handleSubmit, onSubmit, classes } = this.props;
    return (
      <CardBody>
        <Typography align="center" gutterBottom className={classes.title}>
          Cadastre-se
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormControl error fullWidth>
            <Field
              name="nome"
              type="text"
              label="Nome"
              component={TextField}
              inputProps={{ maxLength: 80 }}
              textTransform="any"
              autoFocus
              required
            />
          </FormControl>
          <FormControl error fullWidth>
            <Field
              name="email"
              type="text"
              label="Email"
              component={TextField}
              inputProps={{ maxLength: 80 }}
              textTransform="any"
              required
            />
          </FormControl>
          <FormControl error fullWidth>
            <Field
              component={MaskTextField}
              mask="(99) 9 9999-9999"
              name="telefoneCelular"
              type="text"
              label="Telefone Celular"
              textTransform="any"
              required
            />
          </FormControl>
          <FormControl error fullWidth>
            <Field
              name="senha"
              type="password"
              label="Senha"
              component={TextField}
              inputProps={{ maxLength: 8 }}
              textTransform="any"
              required
            />
          </FormControl>

          <div className="text-center">
            <Button
              className="btn-fill my-4"
              color="primary"
              type="submit"
              loading={submitting}
              disabled={submitting}
            >
              Cadastre-se
            </Button>
          </div>
        </form>
        <p className={classes.text}>
          Ao clicar em &quot;Cadastre-se&quot;, você concorda com nossa{' '}
          <a
            href="https://petmap.com.br/privacy-policy.html"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.textLink}
          >
            Política de Privacidade
          </a>. Você receberá um email com os dados para seu primeiro acesso.
        </p>
        <p className={classes.text}>
          Já tem uma conta?{' '}
          <a href={paths.LOGIN.fullPath} className={classes.textLink}>
            Entrar
          </a>
        </p>
      </CardBody>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Form);
