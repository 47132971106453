import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';

import AdminLayout from './layouts/Admin';
import components from './templates/components';
import MainLayout from './layouts/MainLayout';
import paths from './modules/auth/paths';
import PrivateRoute from './modules/auth/authenticator/Auth';
import Security from './modules/auth/checkToken';

// Auth
import LoginScreen from './modules/auth/login/Screen';
import LogoutScreen from './modules/auth/logout/Screen';

const Routes = () => (
  <MainLayout>
    <Switch>
      <Route {...paths.LOGIN} exact component={LoginScreen} />
      <Route {...paths.ESQUECEU_SUA_SENHA} exact component={LoginScreen} />
      <Route {...paths.REDEFINE_SUA_SENHA} exact component={LoginScreen} />
      <Route {...paths.CRIAR_CONTA} exact component={LoginScreen} />
      <Security>
        <Switch>
          <PrivateRoute {...paths.LOGOUT} exact component={LogoutScreen} />
          <AdminLayout>
            <Switch>
              {components.map((route) => (
                <PrivateRoute key={route.path} {...route} />
              ))}
              <Redirect from="/" to="/admin/dashboard" />
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </AdminLayout>
        </Switch>
      </Security>
    </Switch>
  </MainLayout>
);

export default Routes;
