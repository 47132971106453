import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import SharedLayout from './shared/SharedLayout';

const PrivateLayout = React.lazy(() => import('./private/PrivateLayout'));
const PublicLayout = React.lazy(() => import('./public/PublicLayout'));

class MainLayout extends PureComponent {
  renderPublicLayout = () => {
    const { children } = this.props;
    return <PublicLayout>{children}</PublicLayout>;
  };

  renderPrivateLayout = () => {
    const { children } = this.props;
    return <PrivateLayout>{children}</PrivateLayout>;
  };

  renderLayout() {
    const { authenticated } = this.props;
    return (
      <React.Suspense fallback={<></>}>
        {authenticated && this.renderPrivateLayout()}
        {!authenticated && this.renderPublicLayout()}
      </React.Suspense>
    );
  }

  render() {
    return (
      <>
        {this.renderLayout()}
        <SharedLayout />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.login.authenticated,
});

MainLayout.propTypes = {
  children: PropTypes.node,
  authenticated: PropTypes.bool.isRequired,
};

MainLayout.defaultProps = {
  children: null,
};

export default connect(mapStateToProps)(MainLayout);
