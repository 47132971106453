import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../components';

const Dialog = ({ text, show, onClose, onConfirm }) => (
  <>
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmação</Modal.Title>
      </Modal.Header>
      <Modal.Body>Deseja realmente desligar o rastreador {text}?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Não
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

Dialog.propTypes = {
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default Dialog;
