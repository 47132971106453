import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

// Reducers
import AuthDucks, { LOGIN_LOGOUT } from './modules/auth/Ducks';
import SnackbarDucks from './modules/shared/snackbar/SnackbarDucks';
import UsuarioDucks from './modules/usuario/Ducks';
import RastreadorDucks from './modules/rastreador/Ducks';
import ChipsDucks from './modules/chip/Ducks';
import DashboardDucks from './modules/dashboard/Ducks';

const appReducer = (history) =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    login: AuthDucks,
    snackbar: SnackbarDucks,
    usuario: UsuarioDucks,
    rastreador: RastreadorDucks,
    chip: ChipsDucks,
    dashboard: DashboardDucks,
  });

const rootReducer = (history) => (state, action) => {
  const { router } = state;
  if (action.type === LOGIN_LOGOUT) {
    return appReducer(history)({ router }, action);
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
