import { validateSchema } from '../../utils/validate';

const schema = {
  rules: {
    imei: {
      required: true,
    },
    chipId: {
      required: true
    },
    protocolo: {
      required: true
    }
  },
};

export default (values) => validateSchema(values, schema);
