import { validateSchema } from '../../utils/validate';

const historicoLocalizacaoRequired = (values) => {
  const { tipoVisualizacao } = values;
  return tipoVisualizacao === 'HISTORICO_LOCALIZACAO';
};

const schema = (values) => ({
  rules: {
    tipoVisualizacao: {
      required: true,
    },
    usuarioRastreadorId: {
      required: historicoLocalizacaoRequired(values),
    },
    dataInicial: {
      required: historicoLocalizacaoRequired(values),
      date: '<= today',
    },
    horaInicial: {
      required: historicoLocalizacaoRequired(values),
      hour: true,
    },
    dataFinal: {
      required: historicoLocalizacaoRequired(values),
      date: '<= today',
    },
    horaFinal: {
      required: historicoLocalizacaoRequired(values),
      hour: true,
    },
  },
});

export default (values) => validateSchema(values, schema(values));
