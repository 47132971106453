import { axiosPublic, axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';
import constants from '../shared/constants';

export const login = async (credentials) => {
  const { email, password, reCaptchaValue } = credentials;
  const form = new FormData();

  form.append('username', email);
  form.append('password', password);
  form.append('grant_type', 'password');
  form.append('g-recaptcha-response', reCaptchaValue);

  const fetch = axiosPublic.post('/oauth/token', form, {
    headers: { Authorization: 'Basic eGJyYWluLWFwcC1jbGllbnQ6eGJyYWlu' },
  });

  return pMinDelay(fetch, constants.SETUP.defaultDelay.normal);
};

export const logout = () => {
  const fetch = axiosPrivate.get('/api/logout');
  return pMinDelay(fetch, constants.SETUP.defaultDelay.slow * 1.5);
};

export const checkToken = (token) => {
  const form = new FormData();
  form.append('token', token);
  const fetch = axiosPublic.post('/oauth/check_token', form);
  const timeDelay = 3000;
  return pMinDelay(fetch, timeDelay);
};

export const enviaEmailLinkToken = (values, delay = 0) => {
  const request = axiosPublic.put('/users/pass/send-link', values);
  return pMinDelay(request, delay);
};

export const validarToken = (token, delay = 0) =>
  pMinDelay(
    axiosPublic.get('/users/pass/valida-token', { params: { token } }),
    delay
  );

export const resetarSenha = (values, delay = 0) => {
  const request = axiosPublic.post(
    '/users/pass/reseta-senha',
    values
  );
  return pMinDelay(request, delay);
};

export const userSignup = (values, delay = 0) => {
  const request = axiosPublic.post('/users/sign-up', values);
  return pMinDelay(request, delay);
};

export const emularUsuario = (idUsuario, delay) => {
  const request = axiosPrivate.get('/api/emular/usuario', {
    params: { id: idUsuario },
  });
  return pMinDelay(request, delay);
};
