import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const progressHeight = 2;

const LoadingProgress = ({ image }) => {
  const styles = (theme) => {
    const gutter = theme.spacing.unit * 2;

    return {
      root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: image ? 'auto' : 250,
        height: image ? image.height + progressHeight + gutter : progressHeight,
      },
    };
  };

  /* eslint react/no-this-in-sfc: 0 */
  class LogoProgress extends PureComponent {
    timer = null;

    state = {
      loadingLogo: false,
      // loadingProgress: false,
    };

    componentDidMount() {
      this.startAnimation();
    }

    componentWillUnmount() {
      clearTimeout(this.timer);
    }

    startAnimation = () => {
      const { theme } = this.props;

      const {
        transitions: { duration },
      } = theme;

      this.timer = setTimeout(() => {
        this.setState({
          loadingLogo: true,
        });
      }, duration.enteringScreen * 2);
    };

    // handleEndAnimation = () => {
    //   this.setState({ loadingProgress: true });
    // };

    render() {
      const {
        classes,
        theme: { transitions },
      } = this.props;
      const { loadingLogo } = this.state;
      const { short } = transitions.duration;

      return (
        <div className={classes.root}>
          <Fade
            appear
            in={loadingLogo}
            timeout={short}
            unmountOnExit
            // onEntered={this.handleEndAnimation}
          >
            <div>
              {image && (
                <img alt={image.alt} src={image.src} height={image.height} />
              )}
            </div>
          </Fade>
        </div>
      );
    }
  }

  LogoProgress.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };

  const LogoProgressContainer = withStyles(styles, { withTheme: true })(
    LogoProgress
  );

  return <LogoProgressContainer />;
};

LoadingProgress.defaultProps = {
  image: null,
  // progressColor: '#d62027',
  // barColor: '#df8184',
};

LoadingProgress.propTypes = {
  // progressColor: PropTypes.string,
  // barColor: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    src: PropTypes.node.isRequired,
  }),
};

export default LoadingProgress;
