import includes from 'lodash/includes';

/**
 * Sorts an array based on another array
 *
 * @method sortByArray
 * @param {arrayToSort} Array to be sorted
 * @param {arrayOrdered} Array that serves as reference
 * @return {array}
 */
export const sortByArray = (arrayToSort, arrayOrdered) =>
  arrayToSort.sort((a, b) => {
    const x = arrayOrdered.indexOf(a);
    const y = arrayOrdered.indexOf(b);

    if (x === -1 || y === -1) return 0;

    if (x > y) return 1;

    if (x < y) return -1;

    return 0;
  });

/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export const arrayContainsArray = (superset, subset) =>
  subset.every((value) => superset.indexOf(value) >= 0);

/**
 * Returns TRUE if the first specified array contains some of all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export const arrayContainsSomeFromArray = (superset, subset) =>
  superset.some((item) => includes(subset, item));

/**
 * Returns a string separated by a separator
 *
 * @param {array} array to be striped
 * @param {array} separator character to be the separator
 *
 * @returns {boolean}
 */
export const arrayGetStripedValues = (array, separator = ',') =>
  Array.isArray(array) && array.length ? array.join(separator) : undefined;

export const arrayStripedToNormal = (string, separator = ',') =>
  string ? string.split(separator) : undefined;
