import { CardBody } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Recaptcha from 'react-google-recaptcha';

import { withStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '../../../../components';
import * as AuthDucks from '../../Ducks';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';
import constants from '../../../shared/constants';
import paths from '../../paths';
import validate from './validate';

const formName = 'login';

const styles = () => ({
  title: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  recuperarSenha: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
    '&:hover': {
      color: 'rgba(69, 90, 100, 0.87)',
      textDecoration: 'underline',
    },
  },
  signUp: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
  },
  signUpLink: {
    color: '#1877f2',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
    '&:hover': {
      color: '#1877f2',
      textDecoration: 'underline',
    },
  },
});
class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.reCaptcha = null;
    this.state = {
      reCaptchaValue: '',
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    this.handleResetCaptcha();
  };

  handleChangeCaptcha = (value) => {
    this.setState({ reCaptchaValue: value });
  };

  handleResetCaptcha = () => {
    this.setState({ reCaptchaValue: '' });
    if (this.reCaptcha) {
      this.reCaptcha.reset();
    }
  };

  handleExpireCaptcha = () => {
    this.handleResetCaptcha();
  };

  handleSubmit = (values) => {
    const { onSubmit, throwSnackbar } = this.props;
    const { reCaptchaValue } = this.state;

    let valid = true;

    if (this.reCaptcha != null && reCaptchaValue === '') {
      valid = false;
      throwSnackbar(constants.MESSAGES.captchaFormError);
    }

    return valid && onSubmit({ ...values, reCaptchaValue }, this.reCaptcha);
  };

  render() {
    const { handleSubmit, error, submitting, classes } = this.props;
    return (
      <CardBody>
        <Typography align="center" gutterBottom className={classes.title}>
          Fazer login
        </Typography>

        <form onSubmit={handleSubmit(this.handleSubmit)} noValidate>
          <FormControl error fullWidth margin="normal">
            <Field
              name="email"
              label="Email"
              placeholder="Digite seu email"
              component={TextField}
              inputProps={{ maxLength: 80 }}
              textTransform="any"
              fullWidth
              autoFocus
            />
          </FormControl>
          <FormControl error fullWidth margin="normal">
            <Field
              name="password"
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              textTransform="any"
              component={TextField}
              inputProps={{ maxLength: 255 }}
              autoComplete="off"
              fullWidth
            />
          </FormControl>
          <FormControl error fullWidth margin="normal">
            <Recaptcha
              sitekey="6LfaDp0hAAAAALkp4LMc4uzv6YmerJRwg9hwB30Q"
              onChange={this.handleChangeCaptcha}
              onExpired={this.handleExpireCaptcha}
              style={{ margin: '0 auto' }}
              ref={(recaptcha) => {
                this.reCaptcha = recaptcha;
              }}
            />
          </FormControl>
          <div className="text-center mt-2 mb-2">
            <Button
              type="submit"
              loading={submitting}
              disabled={submitting}
              className="btn-fill"
              block
            >
              Fazer Login
            </Button>
          </div>

          <a
            className={classes.recuperarSenha}
            href={paths.ESQUECEU_SUA_SENHA.fullPath}
          >
            Esqueceu sua senha?
          </a>
          <p className={classes.signUp}>
            Não possui uma conta?{' '}
            <a className={classes.signUpLink} href="/auth/sign-up">
              Cadastre-se
            </a>
          </p>
          {error && <strong>{error}</strong>}
        </form>
      </CardBody>
    );
  }
}

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

Login.defaultProps = {
  error: null,
};

const form = reduxForm({
  form: formName,
  validate,
});

const mapStateToProps = (state) => ({
  initialValues: {},
  authenticated: state.login.authenticated,
});

const mapDispatchToProps = {
  login: AuthDucks.login,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(form(Login));
