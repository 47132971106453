import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { getAuthToken } from '../Helper';
import { LogoProgress } from '../../../components';
import * as AuthDucks from '../Ducks';
import paths from '../paths';

import Loading from '../../../assets/img/three-dots.svg';

const styles = () => ({
  loading: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
});

class Security extends PureComponent {
  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.handleCheckToken();
  };

  handleCheckToken = async () => this.props.checkToken(getAuthToken());

  handleLogout = () => {
    const { tokenChecked, authenticated, handleLogout, history } = this.props;
    if (tokenChecked && !authenticated) {
      handleLogout();
      history.push(paths.LOGIN.fullPath);
    }
  };

  renderLoading = () => (
    <div className={this.props.classes.loading}>
      <LogoProgress
        image={{
          src: Loading,
          width: 273,
          height: 40,
          alt: 'Rastreador',
        }}
      />
    </div>
  );

  render() {
    const { tokenChecked, children } = this.props;
    this.handleLogout();
    return tokenChecked ? children : this.renderLoading();
  }
}

Security.propTypes = {
  history: PropTypes.object.isRequired,
  checkToken: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  tokenChecked: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.login.authenticated,
  tokenChecked: state.login.tokenChecked,
});

const mapDispatchToProps = {
  handleLogout: AuthDucks.handleLogout,
  checkToken: AuthDucks.checkToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Security)));
