import 'moment/locale/pt-br';
import moment from 'moment';

import { currentDateMasked } from '../utils/date';
import * as Api from './Api';
import constants from '../shared/constants';

const DASH_TOTALIZADOR_FETCH_SUCCESS = 'DASH_TOTALIZADOR/FETCH_SUCCESS';
const DASH_TOTALIZADOR_FETCH_LOADING = 'DASH_TOTALIZADOR/FETCH_LOADING';
const DASH_TOTALIZADOR_RESET = 'DASH_TOTALIZADOR/RESET';

const DASH_SITUACAO_FETCH_SUCCESS = 'DASH_SITUACAO/FETCH_SUCCESS';
const DASH_SITUACAO_FETCH_LOADING = 'DASH_SITUACAO/FETCH_LOADING';
const DASH_SITUACAO_RESET = 'DASH_SITUACAO/RESET';

const DASH_SITUACAO_ENERGIA_FETCH_SUCCESS = 'DASH_SITUACAO_ENERGIA/FETCH_SUCCESS';
const DASH_SITUACAO_ENERGIA_FETCH_LOADING = 'DASH_SITUACAO_ENERGIA/FETCH_LOADING';
const DASH_SITUACAO_ENERGIA_RESET = 'DASH_SITUACAO_ENERGIA/RESET';

const endDate = currentDateMasked();
const beginDate = moment()
  .subtract(constants.SETUP.diasMesCompleto, 'days')
  .format(constants.SETUP.dateFormat);

const TOTALIZADOR = {
  data: null,
  loading: true,
};

const SITUACAO = {
  data: null,
  loading: true,
};

const SITUACAO_ENERGIA = {
  data: null,
  loading: true,
};

const INITIAL_STATE = {
  totalizadores: TOTALIZADOR,
  situacoes: SITUACAO,
  situacoesEnergia: SITUACAO_ENERGIA,
  dataFinal: endDate,
  dataInicial: beginDate,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DASH_TOTALIZADOR_FETCH_LOADING: {
      return {
        ...state,
        totalizadores: {
          ...state.totalizadores,
          loading: payload,
        },
      };
    }
    case DASH_TOTALIZADOR_FETCH_SUCCESS: {
      return {
        ...state,
        totalizadores: {
          ...state.totalizadores,
          data: payload,
        },
      };
    }
    case DASH_TOTALIZADOR_RESET: {
      return {
        ...state,
        totalizadores: {
          ...TOTALIZADOR,
        },
      };
    }
    case DASH_SITUACAO_FETCH_LOADING: {
      return {
        ...state,
        situacoes: {
          ...state.situacoes,
          loading: payload,
        },
      };
    }
    case DASH_SITUACAO_FETCH_SUCCESS: {
      return {
        ...state,
        situacoes: {
          ...state.situacoes,
          data: payload,
        },
      };
    }
    case DASH_SITUACAO_RESET: {
      return {
        ...state,
        situacoes: {
          ...SITUACAO,
        },
      };
    }
    case DASH_SITUACAO_ENERGIA_FETCH_LOADING: {
      return {
        ...state,
        situacoesEnergia: {
          ...state.situacoesEnergia,
          loading: payload,
        },
      };
    }
    case DASH_SITUACAO_ENERGIA_FETCH_SUCCESS: {
      return {
        ...state,
        situacoesEnergia: {
          ...state.situacoesEnergia,
          data: payload,
        },
      };
    }
    case DASH_SITUACAO_ENERGIA_RESET: {
      return {
        ...state,
        situacoesEnergia: {
          ...SITUACAO_ENERGIA,
        },
      };
    }
    default:
      return state;
  }
};

export const fetchTotalizadores = (params, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: DASH_TOTALIZADOR_FETCH_LOADING, payload: true });

    const { data } = await Api.fetchTotalizadores(
      { ...params },
      delay,
    );

    dispatch({
      type: DASH_TOTALIZADOR_FETCH_SUCCESS,
      payload: data,
    });

    return data;
  } finally {
    dispatch({ type: DASH_TOTALIZADOR_FETCH_LOADING, payload: false });
  }
};

export const fetchSituacoes = (params, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: DASH_SITUACAO_FETCH_LOADING, payload: true });

    const { data } = await Api.fetchSituacoes(
      { ...params },
      delay,
    );

    dispatch({
      type: DASH_SITUACAO_FETCH_SUCCESS,
      payload: data,
    });

    return data;
  } finally {
    dispatch({ type: DASH_SITUACAO_FETCH_LOADING, payload: false });
  }
};

export const fetchSituacoesEnergia = (params, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: DASH_SITUACAO_ENERGIA_FETCH_LOADING, payload: true });

    const { data } = await Api.fetchSituacoesEnergia(
      { ...params },
      delay,
    );

    dispatch({
      type: DASH_SITUACAO_ENERGIA_FETCH_SUCCESS,
      payload: data,
    });

    return data;
  } finally {
    dispatch({ type: DASH_SITUACAO_ENERGIA_FETCH_LOADING, payload: false });
  }
};

export const resetTotalizadores = () => dispatch =>
  dispatch({ type: DASH_TOTALIZADOR_RESET });

export const resetSituacoes = () => dispatch =>
  dispatch({ type: DASH_SITUACAO_RESET });

export const resetSituacoesEnergia = () => dispatch =>
  dispatch({ type: DASH_SITUACAO_ENERGIA_RESET });
