import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Typography } from '../../../components';
import * as AuthDucks from '../Ducks';
import paths from '../paths';

class LogoutScreen extends PureComponent {
  componentDidMount() {
    this.handleLogout();
  }

  handleLogout = async () => {
    const { logout, history } = this.props;
    await logout();
    history.push(paths.LOGIN.fullPath);
  };

  render() {
    return <Typography style={{ padding: 8 }}>Aguarde...</Typography>;
  }
}

LogoutScreen.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  logout: AuthDucks.logout,
};

export default connect(null, mapDispatchToProps)(LogoutScreen);
