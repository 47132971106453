import axios from 'axios';

import { getAuthToken } from '../modules/auth/Helper';
import { store } from '../store';
import * as AuthDucks from '../modules/auth/Ducks';
import * as SnackbarDucks from '../modules/shared/snackbar/SnackbarDucks';
import constants from '../modules/shared/constants';

const axiosPublic = axios.create({ baseURL: constants.URL.LOCATION.api });
const axiosPrivate = axios.create({ baseURL: constants.URL.LOCATION.api });

const throwSnackbar = (message) =>
  store.dispatch({
    type: SnackbarDucks.SNACKBAR_THROW,
    payload: message,
  });

window.addEventListener(
  'offline',
  () => throwSnackbar(constants.MESSAGES.errorOffiline),
  false
);

const handleError = ({ response }) => {
  if (response) {
    const { status } = response;
    if (
      status === constants.HTTP_STATUS.INTERNAL_SERVER_ERROR ||
      status === constants.HTTP_STATUS.BAD_GATEWAY
    ) {
      throwSnackbar(constants.MESSAGES.internalError);
    }
  }
};

axiosPublic.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);
    throw error;
  }
);

axiosPrivate.interceptors.request.use((config) => {
  try {
    const token = getAuthToken();
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${token}`;

    const state = store.getState();
    if (state) {
      const {
        login: { isEmulando, usuarioEmulador },
      } = state;
      if (isEmulando) {
        const { usuarioId } = usuarioEmulador;
        newConfig.headers['X-Usuario-Emulador'] = usuarioId;
      }
    }

    return newConfig;
  } catch (error) {
    return config;
  }
});

axiosPrivate.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === constants.HTTP_STATUS.UNAUTHORIZED) {
      setTimeout(() => throwSnackbar(constants.MESSAGES.sessaoExpirou), 500);
      return store.dispatch(AuthDucks.handleLogout());
    }
    throw error;
  }
);

export { axiosPublic, axiosPrivate };
