import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, TextField, MaskTextField } from '../../../components';

const Form = ({ handleSubmit, onSubmit, onClean, loading, submitting }) => {
  const renderBotaoBuscar = () => (
    <Button
      className="btn-fill mr-3"
      type="submit"
      loading={submitting || loading}
    >
      Buscar
    </Button>
  );

  const renderBotaoLimpar = () => (
    <Button
      className="pull-right"
      variant="outline-secondary"
      loading={submitting || loading}
      onClick={onClean}
    >
      Limpar
    </Button>
  );

  const renderBotoes = () => (
    <Row>
      <Col xs="12">
        {renderBotaoBuscar()}
        {renderBotaoLimpar()}
      </Col>
    </Row>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Row>
          <Col lg="3" md="4" sm="6" xs="12">
            <Field component={TextField} name="nome" label="Nome" type="text" maxLength="50" />
          </Col>
          <Col lg="3" md="4" sm="6" xs="12">
            <Field
              component={MaskTextField}
              mask="999.999.999-99"
              name="cpf"
              label="CPF"
              type="text"
            />
          </Col>
          <Col lg="3" md="4" sm="6" xs="12">
            <Field
              component={TextField}
              name="email"
              label="Email"
              type="text"
              maxLength="50"
            />
          </Col>
        </Row>

        {renderBotoes()}

        <div className="clearfix" />
      </form>
    </>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClean: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

Form.defaultProps = {};

export default Form;
