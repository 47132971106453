import { Card, Container, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  TextField,
  MaskTextField,
  VirtualizedSelect,
} from '../../../components';
import constants from '../../shared/constants';

import BackgroundPhoto from '../../../assets/img/background-photo.jpg';
import Photo from '../../../assets/img/avatar.jpg';

const { SEXO_OPTIONS } = constants.SETUP;

const Form = ({
  usuario,
  handleSubmit,
  onSubmit,
  submitting,
  estados,
  cidades,
  onChangeEstado,
  onBlurCep,
}) => {
  const renderBotaoSalvar = () => (
    <Button
      className="btn-fill pull-right"
      type="submit"
      variant="info"
      disabled={submitting}
    >
      Atualizar
    </Button>
  );

  const renderBotoes = () => (
    <Row>
      <Col xs="12">{renderBotaoSalvar()}</Col>
    </Row>
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Minha Conta</Card.Title>
              </Card.Header>
              <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row>
                    <Col className="pr-1" md="9">
                      <Field
                        component={TextField}
                        name="nome"
                        label="Nome"
                        type="text"
                        maxLength="50"
                        disabled
                      />
                    </Col>
                    <Col className="pl-1" md="3">
                      <Field
                        component={TextField}
                        name="dataNascimento"
                        label="Data de Nascimento"
                        type="text"
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        type="text"
                        maxLength="50"
                        disabled
                      />
                    </Col>
                    <Col className="px-1" md="3">
                      <Field
                        component={MaskTextField}
                        mask="999.999.999-99"
                        name="cpf"
                        label="CPF"
                        type="text"
                        maxLength="14"
                        disabled={usuario.cpf}
                      />
                    </Col>
                    <Col className="pl-1" md="3">
                      <Field
                        component={TextField}
                        name="rg"
                        label="RG"
                        type="text"
                        maxLength="15"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Field
                        name="sexo"
                        component={VirtualizedSelect}
                        labelKey="label"
                        valueKey="value"
                        label="Sexo"
                        options={SEXO_OPTIONS}
                        placeholder="Selecione"
                      />
                    </Col>
                    <Col className="pl-1" md="6">
                      <Field
                        component={MaskTextField}
                        mask="(99) 9 9999-9999"
                        name="telefoneCelular"
                        label="Tel. Celular"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Field
                        component={MaskTextField}
                        mask="(99) 9999-9999"
                        name="telefoneResidencial"
                        label="Tel. Residencial / Celular"
                        type="text"
                      />
                    </Col>
                    <Col className="pl-1" md="6">
                      <Field
                        component={MaskTextField}
                        mask="(99) 9999-9999"
                        name="telefoneComercial"
                        label="Tel. Comercial / Celular"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mt-3">
                      <Card.Title as="h4">Endereço</Card.Title>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="3" md="4" sm="6" xs="12">
                      <Field
                        component={MaskTextField}
                        mask="99999-999"
                        name="cep"
                        label="CEP"
                        type="text"
                        onBlur={onBlurCep}
                      />
                    </Col>
                    <Col md="9">
                      <Field
                        component={TextField}
                        name="logradouro"
                        label="Logradouro"
                        type="text"
                        maxLength="50"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <Field
                        name="estadoId"
                        component={VirtualizedSelect}
                        labelKey="nome"
                        valueKey="id"
                        label="Estado"
                        options={estados}
                        placeholder={
                          estados.length === 0 ? 'Carregando...' : 'Selecione'
                        }
                        onChange={onChangeEstado}
                      />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <Field
                        name="cidadeId"
                        component={VirtualizedSelect}
                        labelKey="nome"
                        valueKey="id"
                        label="Cidade"
                        options={cidades}
                        disabled={cidades.length === 0}
                      />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <Field
                        component={TextField}
                        name="numero"
                        label="Número"
                        type="numeric"
                        maxLength="5"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8">
                      <Field
                        component={TextField}
                        name="complemento"
                        label="Complemento"
                        type="text"
                        maxLength="100"
                      />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <Field
                        component={TextField}
                        name="bairro"
                        label="Bairro"
                        type="text"
                        maxLength="100"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="8" md="4" sm="6" xs="12">
                      <Field
                        component={TextField}
                        name="referencia"
                        label="Referência"
                        type="text"
                        maxLength="100"
                      />
                    </Col>
                  </Row>

                  {renderBotoes()}

                  <div className="clearfix" />
                </form>
              </Card.Body>
            </Card>
          </Col>

          <Col md="4">
            <Card className="card-user">
              <div className="card-image">
                <img alt="..." src={BackgroundPhoto} />
              </div>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." className="avatar border-gray" src={Photo} />
                    <h5 className="title">{usuario.nome}</h5>
                  </a>
                  <p className="description">{usuario.email}</p>
                </div>
                <p className="description text-center">
                  {'"O seu melhor amigo sempre seguro" '}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Form.propTypes = {
  usuario: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  estados: PropTypes.array,
  cidades: PropTypes.array,
  onChangeEstado: PropTypes.func.isRequired,
  onBlurCep: PropTypes.func.isRequired,
};

Form.defaultProps = {
  estados: [],
  cidades: [],
};

export default Form;
