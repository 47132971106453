import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';

import constants from './modules/shared/constants';
import rootReducers from './reducers';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const config = {
  key: 'root',
  storage,
  whitelist: ['login', 'template'],
  debug: constants.SETUP.__dev__,
  stateReconciler: autoMergeLevel2,
};

const saveSubsetBlacklistFilter = [
  createBlacklistFilter('template', ['dashboardDrawerSmOpen', 'isFilterSave']),
  createBlacklistFilter('login', ['tokenChecked']),
];

const enhancers = [];

/*
  to improve your development, install this extension
  https://github.com/zalmoxisus/redux-devtools-extension
*/
if (constants.SETUP.__dev__) {
  const devTools =
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();

  if (devTools) {
    enhancers.push(devTools);
  }
}

export const configureStore = (initialState = {}, onComplete) => {
  const middleware = [thunk, routerMiddleware(history)];

  const reducers = persistReducer(
    { ...config, transforms: [...saveSubsetBlacklistFilter] },
    rootReducers(history)
  );

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );
  const persistConfig = { enhancers };
  const store = createStore(reducers, initialState, composedEnhancers);
  const persistor = persistStore(store, persistConfig, onComplete);

  return { store, persistor };
};

export const { store, persistor } = configureStore();
