import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, MaskTextField, VirtualizedSelect } from '../../../components';
import constants from '../../shared/constants';

const { TIPO_VISUALIZACAO } = constants.SETUP;

const Form = ({
  handleSubmit,
  onSubmit,
  onClean,
  loading,
  loadingSincronizar,
  submitting,
  tipoVisualizacao,
  usuariosRastreadores,
}) => {
  const renderBotaoBuscar = () => (
    <Button
      className="btn-fill mr-3"
      type="submit"
      loading={submitting || loading || loadingSincronizar}
      disabled={submitting || loading || loadingSincronizar}
    >
      Buscar
    </Button>
  );

  const renderBotaoLimpar = () => (
    <Button
      className="pull-right"
      variant="outline-secondary"
      loading={submitting || loading || loadingSincronizar}
      disabled={submitting || loading || loadingSincronizar}
      onClick={onClean}
    >
      Limpar
    </Button>
  );

  const renderBotoes = () => (
    <Row>
      <Col xs="12">
        {renderBotaoBuscar()}
        {renderBotaoLimpar()}
      </Col>
    </Row>
  );

  const historicoLocalizacaoRequired = () =>
    tipoVisualizacao === 'HISTORICO_LOCALIZACAO';

  const renderFiltroDatas = () =>
    tipoVisualizacao === 'HISTORICO_LOCALIZACAO' && (
      <>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="99/99/9999"
            name="dataInicial"
            label="Data Inicial"
            type="text"
            required={historicoLocalizacaoRequired()}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="99:99"
            name="horaInicial"
            label="Hora Inicial"
            type="text"
            required={historicoLocalizacaoRequired()}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="99/99/9999"
            name="dataFinal"
            label="Data Final"
            type="text"
            required={historicoLocalizacaoRequired()}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="99:99"
            name="horaFinal"
            label="Hora Final"
            type="text"
            required={historicoLocalizacaoRequired()}
          />
        </Col>
      </>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row>
        <Col lg="6" md="6" sm="6" xs="12">
          <Field
            name="tipoVisualizacao"
            component={VirtualizedSelect}
            labelKey="label"
            valueKey="value"
            label="Tipo de Visualização"
            options={TIPO_VISUALIZACAO}
            placeholder="Selecione"
            clearable={false}
            required
          />
        </Col>
        <Col lg="6" md="6" sm="6" xs="12">
          <Field
            name="usuarioRastreadorId"
            component={VirtualizedSelect}
            labelKey="value"
            valueKey="id"
            label="Rastreador"
            options={usuariosRastreadores}
            placeholder="Selecione"
            clearable={false}
            required={historicoLocalizacaoRequired()}
          />
        </Col>
      </Row>

      <Row>{renderFiltroDatas()}</Row>

      {renderBotoes()}

      <div className="clearfix" />
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClean: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingSincronizar: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  tipoVisualizacao: PropTypes.string.isRequired,
  usuariosRastreadores: PropTypes.array.isRequired,
};

Form.defaultProps = {};

export default Form;
