import onlyNumbers from '../helpers/onlyNumbers';
import sum from '../helpers/sum';
import toString from '../helpers/toString';

const getVerificationDigit = (value) => {
  const rest = value % 11;
  return rest < 2 ? 0 : 11 - rest;
};

const multiplyDigits = factor => (digit, index) => Number(digit) * (factor - index);

const isValidCpf = (cpf) => {
  const isSameNumber = cpf.match(/^(\d)\1{10}$/);

  if (isSameNumber) return false;

  const brokenCpf = cpf.match(/^(\d{9})(\d{2})$/);
  const withoutDigits = brokenCpf[1].split('');
  const digits = brokenCpf[2];

  const tempFirstDigit = withoutDigits.map(multiplyDigits(10)).reduce(sum);

  const firstDigit = getVerificationDigit(tempFirstDigit);

  const tempSecondDigit = withoutDigits
    .concat(firstDigit)
    .map(multiplyDigits(11))
    .reduce(sum);

  const secondDigit = getVerificationDigit(tempSecondDigit);

  return toString(firstDigit) + secondDigit === digits;
};

const isCpf = (cpf) => {
  const cleanCpf = onlyNumbers(cpf);
  return cleanCpf.length === 11 && isValidCpf(cleanCpf);
};

export default isCpf;
