export const stringToDate = (value) => {
  const dateArray = value.split('/');
  return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
};

export const sumYears = (formatedDate, years) => {
  const parts = formatedDate.split('/').map(item => Number.parseInt(item, 10));

  const year = parts[2] + Number.parseInt(years, 10);
  const month = parts[1] - 1;
  const day = parts[0];
  const valueDate = new Date(year, month, day);

  return valueDate;
};
