import { Card, Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Button } from '../../../components';
import { montarParamsFiltroPaginacao } from '../../utils/pagination';
import { throwFirstError } from '../../utils/error';
import * as Ducks from '../Ducks';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import constants from '../../shared/constants';
import Form from './Form';
import List from './List';
import * as Apis from '../Api';
import paths from '../../auth/paths';
import SituacaoDialog from './dialog/SituacaoDialog';

const formName = 'ChipListarScreen';

const PAGINATION = {
  last: false,
  totalElements: 0,
  totalPages: 0,
  first: true,
  numberOfElements: 10,
  size: 10,
  number: 0,
};

const ORDER = {
  orderBy: 'id',
  orderDirection: constants.DEFAULT.DIRECAO.asc,
};

class Screen extends Component {
  state = {
    loading: false,
    dados: null,
    pagination: PAGINATION,
    order: ORDER,
    filtros: {},
    item: null,
    openSituacao: false,
  };

  handleSubmit = async (values) => {
    await this.setState({ pagination: PAGINATION, order: ORDER });
    this.fetchChipsByFiltros(values);
  };

  fetchChipsByFiltros = async (filtros) => {
    this.setState({ loading: true });
    let { pagination, dados } = this.state;
    const { throwSnackbar, fetchChipsByFiltros } = this.props;
    const { order } = this.state;

    try {
      const params = montarParamsFiltroPaginacao({
        filtros,
        pagination,
        order,
      });
      const newParams = { ...params };
      const response = await fetchChipsByFiltros(
        newParams,
        constants.SETUP.defaultDelay.normal
      );
      const { content, ...paginationResponse } = response.data;
      pagination = paginationResponse;
      dados = content;
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.unavailableService);
      }
    }

    this.setState({
      loading: false,
      dados,
      pagination,
      filtros,
    });
  };

  handlePaginationChangePage = async (event, page) => {
    const { pagination, filtros } = this.state;
    await this.setState({ pagination: { ...pagination, number: page } });
    this.fetchChipsByFiltros(filtros);
  };

  handlePaginationChangeRowsPerPage = async (event) => {
    const { pagination, filtros } = this.state;
    await this.setState({
      pagination: { ...pagination, size: event.target.value },
    });
    this.fetchChipsByFiltros(filtros);
  };

  handleClean = () => {
    const { reset } = this.props;
    reset();
  };

  handleNovo = () => {
    const { history } = this.props;
    history.push(paths.CHIP_CADASTRAR.fullPath);
  };

  handleOpenSituacao = (item) => {
    this.setState({ openSituacao: true, item });
  };

  handleCloseSituacao = () => {
    this.setState({ openSituacao: false, item: null });
  };

  handleSituacao = async () => {
    const { throwSnackbar } = this.props;
    const { item, filtros } = this.state;
    try {
      await Apis.toggleAtivoInativo(item);
      const situacao = item.situacao === 'A' ? 'Inativado' : 'Ativado';
      throwSnackbar(`Chip ${situacao} com sucesso.`);
      this.fetchChipsByFiltros(filtros);
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(
          `Erro ao tentar ${item.situacao === 'A' ? 'inativar' : 'ativar'} o chip, tente novamente.`
        );
      }
    } finally {
      this.handleCloseSituacao();
    }
  };

  renderDialogSituacao = () => {
    const { openSituacao, item } = this.state;
    return (
      openSituacao && (
        <SituacaoDialog
          text={item.numero}
          show={openSituacao}
          situacao={item.situacao === 'A' ? 'Inativar' : 'Ativar'}
          onClose={this.handleCloseSituacao}
          onConfirm={this.handleSituacao}
        />
      )
    );
  };

  renderBotaoNovo = () => (
    <Row>
      <Col xs="12">
        <Button
          className="m-3"
          variant="success"
          onClick={this.handleNovo}
          disabled={this.state.loading}
        >
          <AddIcon />
        </Button>
      </Col>
    </Row>
  );

  render() {
    const { pagination, dados, order, loading } = this.state;
    const { handleSubmit } = this.props;
    const Novo = this.renderBotaoNovo;
    const SituacaoDialogConfirm = this.renderDialogSituacao;
    return (
      <>
        <Container fluid>
          <SituacaoDialogConfirm />

          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Pesquisa</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form
                    {...this.props}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    onSubmit={this.handleSubmit}
                    onClean={this.handleClean}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-0">
                  <List
                    {...this.props}
                    loading={loading}
                    data={dados}
                    onOpenSituacao={this.handleOpenSituacao}
                    pagination={pagination}
                    order={order}
                    onChangePagination={this.handlePaginationChangePage}
                    onChangeNumberItems={this.handlePaginationChangeRowsPerPage}
                  />
                  <Novo />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Screen.propTypes = {
  reset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  fetchChipsByFiltros: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
  initialValues: {},
});

const mapDispatchToProps = {
  throwSnackbar: SnackbarDucks.throwSnackbar,
  fetchChipsByFiltros: Ducks.fetchChipsByFiltros,
};

const form = reduxForm({
  form: formName,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
