import { Card, CardGroup, Col, Container, Row } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { SubmissionError } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getAuthToken } from '../Helper';
import { throwFirstError } from '../../utils/error';
import * as AuthApi from '../Api';
import * as AuthDucks from '../Ducks';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import constants from '../../shared/constants';
import Footer from '../../../components/Footer/FooterLogin';
import FormAlterarSenhaSenha from './formAlterarSenha';
import FormCriaConta from './formCriaConta';
import FormEsqueceuSuaSenha from './formEsqueceuSenha';
import FormLogin from './formLogin';
import paths from '../paths';

// import IMAGE from '../../../assets/img/sign-up.jpg';
import LOGO from '../../../assets/img/logo.png';

const styles = (theme) => ({
  wrapper: {
    // background: `url(${bg}) no-repeat center 100%`,
    backgroundColor: '#f3f6f7',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
  },
  holder: {
    display: 'flex',
    flex: 1,
  },
  desc: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(32),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(36),
      marginTop: 0,
      textAlign: 'left',
    },
  },
  hero: {
    marginBottom: theme.spacing.unit * 3,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  rowHolder: {
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      height: '100%',
    },
  },
  footer: {
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    color: theme.palette.common.white,
    marginTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
  },
  loginForm: {
    background: theme.palette.common.white,
    borderRadius: 3,
    padding: theme.spacing.unit * 3,
  },
  logoPetMap: {
    height: 115,
    alignItems: 'center',
    marginTop: 15,
  },
});

class LoginScreen extends Component {
  state = {
    success: false,
    token: null,
  };

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.resetStorage();
    this.checkToken();
  }

  getTokenParam = () => {
    const { match } = this.props;
    const { token } = match.params;
    return token;
  };

  checkToken = async () => {
    const token = this.getTokenParam();
    if (token) {
      try {
        await AuthApi.validarToken(token);
        this.setState({ token });
      } catch (error) {
        const { response } = error;
        const { throwSnackbar } = this.props;
        window.history.replaceState({}, document.title, paths.LOGIN.path);
        if (response) {
          throwSnackbar(response.data.message);
          throw error;
        }
      }
    }
  };

  onSubmitLogin = async (values, reCaptcha) => {
    try {
      const { login } = this.props;
      await login(values);
    } catch ({ response }) {
      reCaptcha.reset();

      if (response) {
        const { MESSAGES } = constants;
        const { data } = response;
        const { error, error_description: errorDescription } = data;

        let message = MESSAGES.emailSenhaInvalidos;

        if (error !== 'invalid_grant') {
          message = errorDescription;
        }

        throw new SubmissionError({
          password: message,
        });
      }
    }
  };

  onSubmitAlterarSuaSenha = async (values) => {
    try {
      const { token } = this.state;
      const newValues = {
        ...values,
        token,
      };
      await AuthApi.resetarSenha(newValues, constants.SETUP.defaultDelay.slow);
      this.setState({ success: true });
    } catch (error) {
      const { response } = error;
      const { throwSnackbar } = this.props;
      this.setState({ success: false });
      if (!throwFirstError(error)) {
        throw new SubmissionError({
          emailAtual: constants.MESSAGES.emailNaoEncontrado,
        });
      } else {
        if (response) {
          const { status } = response;
          if (status === constants.HTTP_STATUS.BAD_REQUEST) {
            throw new SubmissionError({
              emailAtual: constants.MESSAGES.emailNaoEncontrado,
            });
          } else if (
            status === constants.HTTP_STATUS.LOCKED ||
            status === constants.HTTP_STATUS.FORBIDDEN
          ) {
            throwSnackbar(response.data.message);
            throw error;
          }
        }
        throwSnackbar(constants.MESSAGES.internalError);
        throw error;
      }
    }
  };

  getRedirectPath() {
    const { usuario, location } = this.props;
    const { state: locationState } = location;
    const { isClient } = usuario;

    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname;
    }

    if (isClient) {
      return paths.LOCALIZACAO_MAPA.fullPath;
    } else {
      return paths.DASHBOARD.fullPath;
    }
  }

  isLoggedIn = () => {
    const token = getAuthToken();
    const { authenticated } = this.props;
    return token && authenticated;
  };

  resetStorage = () => {
    const { logout, usuario } = this.props;
    if (!this.isLoggedIn() && usuario) {
      logout();
    }
  };

  renderLogin = () => <FormLogin onSubmit={this.onSubmitLogin} />;

  renderCriaConta = () => <FormCriaConta onSubmit={this.onSubmitLogin} />;

  renderEsqueciMinhaSenha = () => {
    const { match } = this.props;
    return <FormEsqueceuSuaSenha match={match} />;
  };

  renderAlterarSenha = () => (
    <FormAlterarSenhaSenha
      onSubmit={this.onSubmitAlterarSuaSenha}
      success={this.state.success}
    />
  );

  renderForm = () => {
    const { token } = this.state;
    const {
      location,
      location: { pathname },
    } = this.props;

    const isEsqueciSenha = location && pathname.match(/password-reset/gi);
    const isCriaContaPath = location && pathname.match(/sign-up/gi);
    const isAlterarSenhaPath =
      location && pathname.match(/alterar-sua-senha/gi);

    if (isAlterarSenhaPath && token) {
      return this.renderAlterarSenha();
    } else if (isEsqueciSenha) {
      return this.renderEsqueciMinhaSenha();
    } else if (isCriaContaPath) {
      return this.renderCriaConta();
    }
    return this.renderLogin();
  };

  render() {
    const {
      location,
      classes,
      theme: { transitions },
    } = this.props;

    if (this.isLoggedIn()) {
      return (
        <Redirect
          to={{
            pathname: this.getRedirectPath(),
            state: {
              from: location,
            },
          }}
        />
      );
    }

    return (
      <Fade appear in timeout={transitions.duration.enteringScreen}>
        <div className={classes.wrapper}>
          <div className={classes.holder}>
            <div className="container">
              <div className="app flex-row align-items-center">
                <Container>
                  <Row className="justify-content-center mt-3">
                    <Col md="5">
                      <CardGroup>
                        <Card className="p-6">
                          <Row className="justify-content-center">
                            <img
                              className={classes.logoPetMap}
                              src={LOGO}
                              alt="PetMap"
                            />
                          </Row>
                          {this.renderForm()}
                        </Card>
                      </CardGroup>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Fade>
    );
  }
}

LoginScreen.defaultProps = {
  usuario: null,
};

LoginScreen.propTypes = {
  match: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  usuario: PropTypes.object,
  throwSnackbar: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.login.authenticated,
  usuario: state.login.usuario,
});

const mapDispatchToProps = {
  login: AuthDucks.login,
  logout: AuthDucks.handleLogout,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(LoginScreen);
