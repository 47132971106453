import { Nav, NavDropdown } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { hasSelfPermission } from '../../modules/auth/permission';

const styles = () => ({
  divider: {
    borderTop: 'none',
    borderBottom: '1px solid #484848',
  },
});

function Sidebar({ color, image, routes, classes }) {
  const location = useLocation();

  const activeRoute = (routeName) =>
    location.pathname.indexOf(routeName) > -1 ? 'active' : '';

  const renderDivider = (prop) => {
    const { name } = prop;
    if (name === 'Pagamentos' || name === 'Meus Rastreadores' || name === 'Notificações') {
      return <NavDropdown.Divider className={classes.divider} />;
    }
    return null;
  };

  const renderNavLink = (prop) => (
    <NavLink to={prop.fullPath} className="nav-link" activeClassName="active">
      <i className={prop.icon} />
      <p>{prop.name}</p>
    </NavLink>
  );

  const renderLi = (prop, key) => (
    <li
      className={
        prop.upgrade
          ? 'active active-pro'
          : activeRoute(prop.layout + prop.path)
      }
      key={key}
    >
      {renderNavLink(prop)}
      {renderDivider(prop)}
    </li>
  );

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <div className="logo-img">
            <img
              src={require('assets/img/reactlogo.png').default} // eslint-disable-line
              alt="..."
            />
          </div>
        </div>
        <Nav>
          {routes
            .filter((prop) => prop.visible && hasSelfPermission(prop.roles))
            .map((prop, key) => renderLi(prop, key))}
        </Nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.func.isRequired,
  image: PropTypes.object,
  routes: PropTypes.array.isRequired,
};

Sidebar.defaultProps = {
  image: null,
};

export default withStyles(styles)(Sidebar);
