import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { extractError } from '../../../utils/error';
import { history } from '../../../../store';
import * as AuthApi from '../../Api';
import * as AuthDucks from '../../Ducks';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';
import constants from '../../../shared/constants';
import Form from './Form';
import paths from '../../paths';
import Success from './Success';
import validate from './validate';

const formName = 'esqueceuSuaSenha';

const {
  MESSAGES: { internalError },
  SETUP: { defaultDelay },
} = constants;

class RedefinirSenha extends PureComponent {
  state = {
    success: false,
  };

  handleSubmit = async (values) => {
    try {
      const data = await AuthApi.userSignup(values, defaultDelay.slow);
      this.handleTratarSuccess(data);
    } catch (error) {
      const { throwSnackbar } = this.props;
      const message = extractError(error);
      if (message) {
        throwSnackbar(message);
      } else {
        throwSnackbar(internalError);
        throw error;
      }
    }
  };

  handleTratarSuccess = (data) => {
    if (data) {
      this.setState({ success: true });
    }
  };

  handleVoltar = () => {
    history.push(paths.LOGIN.fullPath);
  };

  renderBody = () => (
    <>
      {this.renderBodyEnviar()}
      {this.renderBodySuccess()}
    </>
  );

  renderBodyEnviar = () => {
    const { success } = this.state;
    const { handleSubmit } = this.props;
    return (
      !success && (
        <Form
          {...this.props}
          handleSubmit={handleSubmit}
          onSubmit={this.handleSubmit}
        />
      )
    );
  };

  renderBodySuccess = () => {
    const { success } = this.state;
    return success && <Success {...this.props} onBack={this.handleVoltar} />;
  };

  render() {
    const Body = this.renderBody;
    return <Body />;
  }
}

RedefinirSenha.propTypes = {
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
};

RedefinirSenha.defaultProps = {};

const mapStateToProps = () => ({
  initialValues: {},
});

const mapDispatchToProps = {
  login: AuthDucks.login,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

const form = reduxForm({
  form: formName,
  validate,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(RedefinirSenha));
