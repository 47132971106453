import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Typography } from '../../../../components';

class Success extends PureComponent {
  render() {
    const { onBack } = this.props;
    return (
      <>
        <div className="title-text">
          <h3>Parabéns, seja bem vindo(a)</h3>
        </div>
        <Typography variant="ghost">
          Enviamos a você um email com seu dados de acesso.
        </Typography>
        <div>
          <Button className="my-4" color="primary" onClick={onBack}>
            Entrar
          </Button>
        </div>

        <div className="text-rodape">
          Se não encontrar o e-mail na sua caixa de entrada, verifique a pasta
          de spam.
        </div>
      </>
    );
  }
}

Success.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default Success;
