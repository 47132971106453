import { axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';

const urlRest = '/api/rastreadores';
const urlUsuariosRastreadores = '/api/usuarios/rastreadores';

export const fetchUsuariosRastreadores = () =>
  axiosPrivate.get(`${urlUsuariosRastreadores}/select`);

export const desligarRastreador = (id) =>
  axiosPrivate.put(`${urlRest}/desligar/${id}`);

export const sincronizarRastreadores = () =>
  axiosPrivate.put(`${urlUsuariosRastreadores}/sincronizar`);

export const fetchLocalizacoesRastreadoresByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(`${urlUsuariosRastreadores}/ultimas-localizacoes`, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};
