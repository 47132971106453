import { roles } from '../permission';

export default {
  LOGIN: {
    path: '/auth/login',
    fullPath: '/auth/login',
    layout: '/auth',
    visible: false,
  },

  LOGOUT: {
    path: '/admin/logout',
    fullPath: '/admin/logout',
    name: 'Sair',
    icon: 'nc-icon nc-stre-right',
    layout: '/admin',
    visible: true,
  },

  ESQUECEU_SUA_SENHA: {
    path: '/auth/password-reset',
    fullPath: '/auth/password-reset',
    layout: '/auth',
    visible: false,
  },
  REDEFINE_SUA_SENHA: {
    path: '/auth/alterar-sua-senha/:token',
    layout: '/auth',
    visible: false,
  },
  ALTERAR_SENHA: {
    path: '/auth/alterar-senha',
    layout: '/auth',
    visible: false,
  },
  CRIAR_CONTA: {
    path: '/auth/sign-up',
    fullPath: '/auth/sign-up',
    layout: '/auth',
    visible: false,
  },

  DASHBOARD: {
    path: '/dashboard',
    fullPath: '/admin/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    layout: '/admin',
    visible: true,
    roles: [
      roles.DASH_TOTALIZADOR,
      roles.DASH_SITUACAO,
      roles.DASH_SITUACAO_ENERGIA,
    ],
  },

  MINHA_CONTA: {
    path: '/minha-conta',
    fullPath: '/user/minha-conta',
    name: 'Minha Conta',
    icon: 'nc-icon nc-circle-09',
    layout: '/user',
    visible: true,
  },

  USUARIO_LISTAR: {
    path: '/usuarios',
    fullPath: '/admin/usuarios',
    name: 'Usuários',
    icon: 'nc-icon nc-circle-09',
    layout: '/admin',
    visible: true,
    roles: [roles.VISUALIZAR_USUARIOS],
  },
  USUARIO_CADASTRAR: {
    path: '/usuarios/cadastrar',
    fullPath: '/admin/usuarios/cadastrar',
    name: 'Usuários',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_USUARIOS],
  },
  USUARIO_EDITAR_ID: {
    path: '/usuarios/editar/:id',
    fullPath: '/admin/usuarios/editar',
    name: 'Usuários',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_USUARIOS],
  },

  RASTREADOR_LISTAR: {
    path: '/rastreadores',
    fullPath: '/admin/rastreadores',
    name: 'Rastreadores',
    icon: 'nc-icon nc-square-pin',
    layout: '/admin',
    visible: true,
    roles: [roles.VISUALIZAR_TODOS_RASTREADORES],
  },
  RASTREADOR_CADASTRAR: {
    path: '/rastreadores/cadastrar',
    fullPath: '/admin/rastreadores/cadastrar',
    name: 'Rastreadores',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_TODOS_RASTREADORES],
  },
  RASTREADOR_EDITAR_ID: {
    path: '/rastreadores/editar/:id',
    fullPath: '/admin/rastreadores/editar',
    name: 'Rastreadores',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_TODOS_RASTREADORES],
  },
  RASTREADOR_DETALHE_ID: {
    path: '/rastreadores/detalhe/:id',
    fullPath: '/user/rastreadores/detalhe',
    name: 'Detalhe',
    layout: '/user',
    visible: false,
  },

  MEUS_RASTREADORES_LISTAR: {
    path: '/meus-rastreadores',
    fullPath: '/user/meus-rastreadores',
    name: 'Meus Rastreadores',
    icon: 'nc-icon nc-square-pin',
    layout: '/user',
    visible: true,
  },
  MEUS_RASTREADORES_CADASTRAR: {
    path: '/meus-rastreadores/cadastrar',
    fullPath: '/user/meus-rastreadores/cadastrar',
    name: 'Meus Rastreadores',
    layout: '/user',
    visible: false,
  },
  MEUS_RASTREADORES_EDITAR_ID: {
    path: '/meus-rastreadores/editar/:id',
    fullPath: '/user/meus-rastreadores/editar',
    name: 'Meus Rastreadores',
    layout: '/user',
    visible: false,
  },

  LOCALIZACAO_MAPA: {
    path: '/mapa',
    fullPath: '/user/mapa',
    name: 'Mapa GPS',
    icon: 'nc-icon nc-map-big',
    layout: '/user',
    visible: true,
  },

  CHIP_LISTAR: {
    path: '/chips',
    fullPath: '/admin/chips',
    name: 'Chips',
    icon: 'nc-icon nc-tag-content',
    layout: '/admin',
    visible: true,
    roles: [roles.VISUALIZAR_CHIPS],
  },
  CHIP_CADASTRAR: {
    path: '/chips/cadastrar',
    fullPath: '/admin/chips/cadastrar',
    name: 'Chips',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_CHIPS],
  },
  CHIP_EDITAR_ID: {
    path: '/chips/editar/:id',
    fullPath: '/admin/chips/editar',
    name: 'Chips',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_CHIPS],
  },

  NOTIFICACAO: {
    path: '/notificacoes',
    fullPath: '/user/notificacoes',
    name: 'Notificações',
    icon: 'nc-icon nc-bell-55',
    layout: '/user',
    visible: true,
  },

  PAGAMENTO: {
    path: '/pagamentos',
    fullPath: '/admin/pagamentos',
    name: 'Pagamentos',
    icon: 'nc-icon nc-credit-card',
    layout: '/admin',
    visible: false,
    roles: [roles.VISUALIZAR_TODOS_PAGAMENTOS],
  },

  MEUS_PAGAMENTOS: {
    path: '/meus-pagamentos',
    fullPath: '/user/meus-pagamentos',
    name: 'Meus Pagamentos',
    icon: 'nc-icon nc-credit-card',
    layout: '/user',
    visible: false,
    roles: [
      roles.VISUALIZAR_TODOS_PAGAMENTOS,
      // roles.VISUALIZAR_PAGAMENTOS_CLIENTE,
    ],
  },
};
