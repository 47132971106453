import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { yellow, blue } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Typography } from '../../../components';
import * as AuthDucks from '../../auth/Ducks';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import paths from '../../auth/paths';

const styles = (theme) => ({
  emulacaoAlert: {
    alignItems: 'center',
    background: yellow[100],
    display: 'flex',
    height: theme.spacing.unit * 5,
    justifyContent: 'center',
  },
  link: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&, &:hover': {
      color: blue[500],
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class Emulacao extends Component {
  handleLogoutEmulacao = () => {
    const { logoutEmulacao, history, throwSnackbar } = this.props;
    logoutEmulacao();
    history.push(paths.DASHBOARD.path);
    throwSnackbar('Saindo da emulação, aguarde...');

    // recarregar todas as configuracoes do usuario
    window.setTimeout(() => window.location.reload(), 1000);
  };

  render() {
    const { isEmulando, classes } = this.props;
    return (
      isEmulando && (
        <div className={classes.emulacaoAlert}>
          <Typography align="center" variant="caption">
            Você está utilizando um ambiente emulado.
            <button
              className={classes.link}
              onClick={this.handleLogoutEmulacao}
            >
              SAIR DA EMULAÇÃO
            </button>
          </Typography>
        </div>
      )
    );
  }
}

Emulacao.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isEmulando: PropTypes.bool.isRequired,
  logoutEmulacao: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logoutEmulacao: AuthDucks.logoutEmulacao,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

const mapStateToProps = (state) => ({
  isEmulando: state.login.isEmulando,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(Emulacao);
