import { persistor } from '../../store';
import * as api from './Api';
import * as AuthHelper from './Helper';

const LOGIN_SUCCESS = 'LOGIN/SUCCESS';
export const LOGIN_LOGOUT = 'LOGIN/LOGOUT';
const LOGIN_TOKEN_CHECKED = 'LOGIN/TOKEN_CHECKED';

const LOGIN_EMULAR_LOADING = 'LOGIN_EMULAR/LOADING';
const LOGIN_EMULAR_SUCCESS = 'LOGIN_EMULAR/SUCCESS';
const LOGIN_EMULAR_LOGOUT = 'LOGIN_EMULAR/LOGOUT';

const EMULACAO_STATE = {
  isEmulando: false,
  usuarioEmulador: null,
  usuarioTokenEmulador: null,
};

const INITIAL_STATE = {
  authenticated: false,
  tokenChecked: false,
  usuario: null,
  ...EMULACAO_STATE,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        usuario: {
          ...payload,
        },
      };
    case LOGIN_LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case LOGIN_TOKEN_CHECKED:
      return {
        ...state,
        tokenChecked: payload,
      };
    case LOGIN_EMULAR_SUCCESS:
    case LOGIN_EMULAR_LOGOUT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const login = (credentials) => async (dispatch) =>
  api.login(credentials).then(({ data: usuario }) => {
    const { access_token: token } = usuario;
    AuthHelper.setAuthCookie(token);
    dispatch({ type: LOGIN_SUCCESS, payload: usuario });
    return usuario;
  });

export const handleLogout = () => (dispatch) => {
  AuthHelper.removeAuthCookie();
  AuthHelper.removeEmulacaoCookies();
  dispatch({ type: LOGIN_LOGOUT });
  persistor.purge();
};

export const logout = () => async (dispatch) => {
  try {
    const logoutRequest = await api.logout();
    dispatch(handleLogout());
    return logoutRequest;
  } catch (error) {
    return error;
  }
};

export const checkToken = (token) => async (dispatch) => {
  try {
    if (!token) throw Error();
    const response = await api.checkToken(token);
    dispatch({ type: LOGIN_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch(handleLogout());
    return error;
  } finally {
    dispatch({ type: LOGIN_TOKEN_CHECKED, payload: true });
  }
};

export const emularUsuario = (idUsuario) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_EMULAR_LOADING, payload: true });

    const usuarioEmulador = getState().login.usuario;
    const emulacaoRequest = await api.emularUsuario(idUsuario);
    const usuarioEmulado = emulacaoRequest.data;
    const { access_token } = usuarioEmulado; // eslint-disable-line

    const emulacao = {
      usuario: usuarioEmulado,
      isEmulando: true,
      usuarioEmulador,
      usuarioTokenEmulador: AuthHelper.getAuthToken(),
    };

    AuthHelper.setAuthCookie(access_token);
    AuthHelper.setUsuarioEmuladoCookie(usuarioEmulado.usuarioId);
    AuthHelper.setUsuarioEmuladorCookie(usuarioEmulador.usuarioId);

    dispatch({ type: LOGIN_EMULAR_SUCCESS, payload: emulacao });

    return emulacaoRequest;

    // eslint-disable-next-line
  } catch (error) {
    throw error;
  } finally {
    dispatch({ type: LOGIN_EMULAR_LOADING, payload: false });
  }
};

export const logoutEmulacao = () => (dispatch, getState) => {
  const {
    login: { usuarioTokenEmulador },
  } = getState();

  AuthHelper.setAuthCookie(usuarioTokenEmulador);
  AuthHelper.removeEmulacaoCookies();

  dispatch({
    type: LOGIN_EMULAR_LOGOUT,
    payload: EMULACAO_STATE,
  });
};
