import { validateRule } from './index';
import * as validators from '../validators';

export const getOperatorSetup = text => ({
  operator: text.split(' ')[0],
  context: text.split(' ')[1],
});

export const getRuleByOperator = (ruleName, ruleValue, value, values) => {
  if (typeof ruleValue === 'string') {
    const { operator, context } = getOperatorSetup(ruleValue);
    const isToday = context === 'today';

    switch (operator) {
      case '>':
        return isToday ? 'isDateGreaterThanToday' : 'isDateGreaterThanField';

      case '>=':
        return isToday
          ? 'isDateGreaterThanTodayOrEqualToday'
          : 'isDateGreaterThanFieldOrEqualField';

      case '<':
        return isToday ? 'isDateLessThanToday' : 'isDateLessThanField';

      case '<=':
        return isToday ? 'isDateLessThanTodayOrEqualToday' : 'isDateLessThanFieldOrEqualField';

      case 'notWeekend':
        return 'notWeekend';

      case 'notSunday':
        return 'notSunday';

      case 'underAge':
        return 'underAge';

      case 'overAge':
        return 'overAge';

      default:
        return 'isDate';
    }
  } else if (Array.isArray(ruleValue)) {
    const validations = [];

    ruleValue.forEach((rule) => {
      validations.push(validateRule('date', rule, value, values).hasError);
    });

    if (validations.length && validations.includes(true)) {
      let rule;
      validations.forEach((validation, index) => {
        if (validation) {
          const ruleNameByOperator = getRuleByOperator(ruleName, ruleValue[index], value, values);
          rule = {
            ruleName: ruleNameByOperator,
            ruleValue: ruleValue[index],
          };
        }
      });
      return rule;
    }
  }
  return 'isDate';
};

export const getRuleName = (ruleName, ruleValue, value, values) => {
  const isValidDate = validators.isDate(value);
  const isOperator = typeof ruleValue !== 'boolean';

  if (isValidDate && isOperator) {
    return getRuleByOperator(ruleName, ruleValue, value, values);
  }

  return 'isDate';
};
