import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { throwFirstError } from '../../../utils/error';
import * as DashboardDucks from '../../Ducks';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';
import constants from '../../../shared/constants';
import Situacoes from './components/dashboard';
import SituacoesLoading from './components/loading';

class Screen extends Component {
  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.fetchSituacoes();
  };

  fetchSituacoes = async () => {
    const {
      dataInicial,
      dataFinal,
      fetchSituacoes,
      throwSnackbar,
    } = this.props;

    try {
      return await fetchSituacoes(
        {
          dataInicial,
          dataFinal,
        },
        constants.SETUP.defaultDelay.slow,
      );
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.errorOnLoadRecords('situaçōes'));
      }
    }
    return null;
  };

  render() {
    const {
      situacoes: { data, loading },
  } = this.props;

  const hasData = Boolean(data);
  const isLoading = loading && !hasData;

  return isLoading || !hasData ? <SituacoesLoading /> : <Situacoes data={data} />;
  }
}

Screen.propTypes = {
  fetchSituacoes: PropTypes.func.isRequired,
  situacoes: PropTypes.object.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  dataInicial: PropTypes.string.isRequired,
  dataFinal: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  situacoes: state.dashboard.situacoes,
  dataInicial: state.dashboard.dataInicial,
  dataFinal: state.dashboard.dataFinal,
});

const mapDispatchToProps = {
  fetchSituacoes: DashboardDucks.fetchSituacoes,
  resetSituacoes: DashboardDucks.resetSituacoes,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Screen);
