import { validateSchema } from '../../../utils/validate';

const schema = {
  rules: {
    senhaAtual: {
        required: true,
      },
      senhaNova: {
        required: true,
        maxLength: 80,
      },
      confirmarNovaSenha: {
        required: true,
        equalTo: 'senhaNova',
      },
  },
  messages: {
    senhaNova: {
      minLength: 'Escolha uma senha mais longa',
    },
    confirmarNovaSenha: {
      equalTo: 'As senhas não correspondem',
    },
  },
};

export default (values) => validateSchema(values, schema);
