import ChipListarScreen from '../../modules/chip/listar/Screen';
import ChipManterScreen from '../../modules/chip/manter/Screen';
import DashboardScreen from '../../modules/dashboard/initial';
import LocalizacaoMapaScreen from '../../modules/mapa/localizacao/Screen';
import MeuRastreadorListarScreen from '../../modules/rastreador/meus-rastreadores/listar/Screen';
import MeuRastreadorManterScreen from '../../modules/rastreador/meus-rastreadores/manter/Screen';
import MinhaContaScren from '../../modules/usuario/minha-conta/Screen';
import NotificacaoListarScreen from '../../modules/notificacao/listar/Screen';
import paths from '../../modules/auth/paths';
import RastreadorDetalheScreen from '../../modules/rastreador/detalhar/Screen';
import RastreadorListarScreen from '../../modules/rastreador/listar/Screen';
import RastreadorManterScreen from '../../modules/rastreador/manter/Screen';
import UsuarioListarScreen from '../../modules/usuario/listar/Screen';
import UsuarioManterScreen from '../../modules/usuario/manter/Screen';
import LogoutScreen from '../../modules/auth/logout/Screen';

import PagamentoListarScreen from '../../modules/pagamento/pagamentos/listar/Screen';
import MeuPagamentoListarScreen from '../../modules/pagamento/meus-pagamentos/listar/Screen';

export default [
  {
    exact: true,
    component: DashboardScreen,
    ...paths.DASHBOARD,
  },

  // USUARIOS
  {
    exact: true,
    component: UsuarioManterScreen,
    ...paths.USUARIO_EDITAR_ID,
  },
  {
    exact: true,
    component: UsuarioManterScreen,
    ...paths.USUARIO_CADASTRAR,
  },
  {
    exact: true,
    component: UsuarioListarScreen,
    ...paths.USUARIO_LISTAR,
  },

  // CHIPS
  {
    exact: true,
    component: ChipManterScreen,
    ...paths.CHIP_EDITAR_ID,
  },
  {
    exact: true,
    component: ChipManterScreen,
    ...paths.CHIP_CADASTRAR,
  },
  {
    exact: true,
    component: ChipListarScreen,
    ...paths.CHIP_LISTAR,
  },

  // PAGAMENTO
  {
    exact: true,
    component: PagamentoListarScreen,
    ...paths.PAGAMENTO,
  },

  // MAPA
  {
    exact: true,
    component: LocalizacaoMapaScreen,
    ...paths.LOCALIZACAO_MAPA,
  },

  // RASTREADOR
  {
    exact: true,
    component: RastreadorDetalheScreen,
    ...paths.RASTREADOR_DETALHE_ID,
  },
  {
    exact: true,
    component: RastreadorManterScreen,
    ...paths.RASTREADOR_EDITAR_ID,
  },
  {
    exact: true,
    component: RastreadorManterScreen,
    ...paths.RASTREADOR_CADASTRAR,
  },
  {
    exact: true,
    component: RastreadorListarScreen,
    ...paths.RASTREADOR_LISTAR,
  },

  // MEUS RASTREADORES
  {
    exact: true,
    component: MeuRastreadorManterScreen,
    ...paths.MEUS_RASTREADORES_EDITAR_ID,
  },
  {
    exact: true,
    component: MeuRastreadorManterScreen,
    ...paths.MEUS_RASTREADORES_CADASTRAR,
  },
  {
    exact: true,
    component: MeuRastreadorListarScreen,
    ...paths.MEUS_RASTREADORES_LISTAR,
  },

  // MINHA CONTA
  {
    exact: true,
    component: MinhaContaScren,
    ...paths.MINHA_CONTA,
  },

  // MEUS PAGAMENTOS
  {
    exact: true,
    component: MeuPagamentoListarScreen,
    ...paths.MEUS_PAGAMENTOS,
  },

  // NOTIFICACAO
  {
    exact: true,
    component: NotificacaoListarScreen,
    ...paths.NOTIFICACAO,
  },

  // SAIR
  {
    exact: true,
    component: LogoutScreen,
    ...paths.LOGOUT,
  },
];
