import * as api from './Api';

const RASTREADOR_RESET_OBJECT_SUCCESS = 'RASTREADOR/RESET_OBJECT_SUCCESS';

const RASTREADOR_FETCH_SUCCESS = 'RASTREADOR/FETCH_SUCCESS';
const RASTREADOR_FETCH_LOADING = 'RASTREADOR/FETCH_LOADING';
const RASTREADOR_FETCH_FAILURE = 'RASTREADOR/FETCH_FAILURE';

export const RASTREADOR_GET_SUCCESS = 'RASTREADOR/GET_SUCCESS';
export const RASTREADOR_GET_FAILURE = 'RASTREADOR/GET_FAILURE';
export const RASTREADOR_GET_LOADING = 'RASTREADOR/GET_LOADING';

const INITIAL_STATE = {
  items: null,
  itemsError: false,
  itemsIsLoading: false,
  item: null,
  itemError: false,
  itemIsLoading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case RASTREADOR_RESET_OBJECT_SUCCESS:
      return {
        ...state,
        item: null,
      };
    case RASTREADOR_GET_SUCCESS:
      return {
        ...state,
        item: {
          ...payload,
        },
      };
    case RASTREADOR_GET_FAILURE:
      return {
        ...state,
        itemError: payload,
      };
    case RASTREADOR_GET_LOADING:
      return {
        ...state,
        itemIsLoading: payload,
      };
    case RASTREADOR_FETCH_SUCCESS:
      return {
        ...state,
        itemsError: false,
        items: payload,
      };
    case RASTREADOR_FETCH_LOADING:
      return {
        ...state,
        itemsIsLoading: payload,
      };
    case RASTREADOR_FETCH_FAILURE:
      return {
        ...state,
        itemsError: payload,
      };
    default:
      return state;
  }
};

export const fetchRastreadoresByFiltros = filtros => async (dispatch) => {
  try {
    dispatch({ type: RASTREADOR_FETCH_LOADING, payload: true });
    const response = await api.fetchRastreadoresByFiltros(filtros);
    dispatch({ type: RASTREADOR_FETCH_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch({ type: RASTREADOR_FETCH_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: RASTREADOR_FETCH_LOADING, payload: false });
  }
};

export const getRastreador = (id, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: RASTREADOR_GET_LOADING, payload: true });
    const res = await api.getRastreador(id, delay);
    dispatch({ type: RASTREADOR_GET_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({ type: RASTREADOR_GET_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: RASTREADOR_GET_LOADING, payload: false });
  }
};

export const getUsuarioRastreador = (id, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: RASTREADOR_GET_LOADING, payload: true });
    const res = await api.getUsuarioRastreador(id, delay);
    dispatch({ type: RASTREADOR_GET_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({ type: RASTREADOR_GET_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: RASTREADOR_GET_LOADING, payload: false });
  }
};

export const resetObject = () => async (dispatch) => {
  dispatch({ type: RASTREADOR_RESET_OBJECT_SUCCESS });
};
