import * as api from './Api';

const USUARIO_RESET_OBJECT_SUCCESS = 'USUARIO/RESET_OBJECT_SUCCESS';

const USUARIO_FETCH_SUCCESS = 'USUARIO/FETCH_SUCCESS';
const USUARIO_FETCH_LOADING = 'USUARIO/FETCH_LOADING';
const USUARIO_FETCH_FAILURE = 'USUARIO/FETCH_FAILURE';

export const USUARIO_GET_SUCCESS = 'USUARIO/GET_SUCCESS';
export const USUARIO_GET_FAILURE = 'USUARIO/GET_FAILURE';
export const USUARIO_GET_LOADING = 'USUARIO/GET_LOADING';

const INITIAL_STATE = {
  items: null,
  itemsError: false,
  itemsIsLoading: false,
  item: null,
  itemError: false,
  itemIsLoading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USUARIO_RESET_OBJECT_SUCCESS:
      return {
        ...state,
        item: null,
      };
    case USUARIO_GET_SUCCESS:
      return {
        ...state,
        item: {
          ...payload,
        },
      };
    case USUARIO_GET_FAILURE:
      return {
        ...state,
        itemError: payload,
      };
    case USUARIO_GET_LOADING:
      return {
        ...state,
        itemIsLoading: payload,
      };
    case USUARIO_FETCH_SUCCESS:
      return {
        ...state,
        itemsError: false,
        items: payload,
      };
    case USUARIO_FETCH_LOADING:
      return {
        ...state,
        itemsIsLoading: payload,
      };
    case USUARIO_FETCH_FAILURE:
      return {
        ...state,
        itemsError: payload,
      };
    default:
      return state;
  }
};

export const fetchUsuariosByFiltros = filtros => async (dispatch) => {
  try {
    dispatch({ type: USUARIO_FETCH_LOADING, payload: true });
    const response = await api.fetchUsuariosByFiltros(filtros);
    dispatch({ type: USUARIO_FETCH_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch({ type: USUARIO_FETCH_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: USUARIO_FETCH_LOADING, payload: false });
  }
};

export const getUsuario = (id, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: USUARIO_GET_LOADING, payload: true });
    const res = await api.getUsuario(id, delay);
    dispatch({ type: USUARIO_GET_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({ type: USUARIO_GET_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: USUARIO_GET_LOADING, payload: false });
  }
};

export const getUsuarioMinhaConta = (id, delay = 0) => async (dispatch) => {
  try {
    dispatch({ type: USUARIO_GET_LOADING, payload: true });
    const res = await api.getUsuarioMinhaConta(id, delay);
    dispatch({ type: USUARIO_GET_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({ type: USUARIO_GET_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: USUARIO_GET_LOADING, payload: false });
  }
};

export const resetObject = () => async (dispatch) => {
  dispatch({ type: USUARIO_RESET_OBJECT_SUCCESS });
};
