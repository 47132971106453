import { Row, Col } from 'react-bootstrap';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { dateISOToDateMasked } from '../../../utils/date';
import { TableEmpty, ActionButton } from '../../../../components';
import constants from '../../../shared/constants';

const columns = [
  { name: 'IMEI' },
  { name: 'Descrição' },
  { name: 'Intervalo Localização', align: 'right' },
  { name: 'Bateria', align: 'right' },
  { name: 'Data', align: 'right' },
  { name: 'Situação', align: 'right' },
  { name: 'Ações', align: 'right' },
];

const List = ({
  history,
  data,
  loading,
  pagination,
  onChangePagination,
  onChangeNumberItems,
  onOpenEconomiaBateria,
  onOpenUltraEconomiaBateria,
}) => {
  const renderColumns = () => (
    <TableRow>
      {columns.map((item) => (
        <TableCell align={item.align || ''}>{item.name}</TableCell>
      ))}
    </TableRow>
  );

  const handleEditar = (id) => {
    history.push(`/user/meus-rastreadores/editar/${id}`);
  };

  const renderOpcaoEditar = (item) => {
    const options = [];
    options.push({
      content: 'Editar',
      data: item.id,
      onClick: handleEditar,
    });
    return options;
  };

  const handleDetalhar = (id) => {
    history.push(`/user/rastreadores/detalhe/${id}`);
  };

  const renderOpcaoDetalhar = (item) => {
    const options = [];
    options.push({
      content: 'Detalhar',
      data: item.rastreadorId,
      onClick: handleDetalhar,
    });
    return options;
  };

  const renderOpcaoEconomiaBateria = (item) => {
    const options = [];
    const { economiaBateria, ultraEconomiaBateria } = item;
    if (ultraEconomiaBateria === 'F') {
      options.push({
        content:
          economiaBateria === 'V'
            ? 'Inativar economia de bateria'
            : 'Ativar economia de bateria',
        data: item,
        onClick: onOpenEconomiaBateria,
      });
    }
    return options;
  };

  const renderOpcaoUltraEconomiaBateria = (item) => {
    const options = [];
    const { economiaBateria, ultraEconomiaBateria } = item;
    if (economiaBateria === 'F') {
      options.push({
        content:
          ultraEconomiaBateria === 'V'
            ? 'Inativar ultra economia de bateria'
            : 'Ativar ultra economia de bateria',
        data: item,
        onClick: onOpenUltraEconomiaBateria,
      });
    }
    return options;
  };

  const renderMenuOpcoes = (item) => [
    ...renderOpcaoEditar(item),
    ...renderOpcaoDetalhar(item),
    ...renderOpcaoEconomiaBateria(item),
    ...renderOpcaoUltraEconomiaBateria(item),
  ];

  const renderActionButton = (item) => {
    const options = renderMenuOpcoes(item);
    return (
      <ActionButton
        disabled={options.length === 0}
        gutter={false}
        icon={MoreVertIcon}
        options={options}
      />
    );
  };

  const renderRows = () => {
    if (Array.isArray(data) && data.length && !loading) {
      return data.map((item) => (
        <TableRow key={item.id}>
          <TableCell padding="dense">{item.imei}</TableCell>
          <TableCell padding="dense">{item.descricao}</TableCell>
          <TableCell padding="dense" align="right">
            {item.intervaloLocalizacao}
          </TableCell>
          <TableCell padding="dense" align="right">
            {item.bateria && item.bateria >= 0 ? `${item.bateria} %` : '-'}
          </TableCell>
          <TableCell padding="dense" align="right">
            {dateISOToDateMasked(item.dataCadastro)}
          </TableCell>
          <TableCell padding="dense" align="right">
            {item.situacaoEnergiaDescricao || '-'}
          </TableCell>
          <TableCell padding="none" align="right" className="pr-2">
            {renderActionButton(item)}
          </TableCell>
        </TableRow>
      ));
    }
    return <TableEmpty loading={loading} data={data} />;
  };

  // eslint-disable-next-line
  const renderFooter = () => {
    if (Array.isArray(data)) {
      return (
        <TableRow>
          <TablePagination
            page={pagination.number}
            rowsPerPage={pagination.size}
            count={pagination.totalElements}
            onChangePage={onChangePagination}
            onChangeRowsPerPage={onChangeNumberItems}
            labelRowsPerPage={constants.MESSAGES.mostrarLinhas}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} a ${to} de ${count}`
            }
          />
        </TableRow>
      );
    }
  };

  return (
    <Row>
      <Col md="12">
        <Table className="table-hover ">
          {renderColumns()}
          <TableBody>{renderRows()}</TableBody>
          <TableFooter>{renderFooter()}</TableFooter>
        </Table>
      </Col>
    </Row>
  );
};

List.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  onChangeNumberItems: PropTypes.func.isRequired,
  onOpenEconomiaBateria: PropTypes.func.isRequired,
  onOpenUltraEconomiaBateria: PropTypes.func.isRequired,
};

List.defaultProps = {
  data: [],
  loading: false,
};

export default List;
