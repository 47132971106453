import { validateSchema } from '../../utils/validate';

const schema = {
  rules: {
    descricao: {
      required: true,
    },
    numero: {
      required: true,
    },
  },
};

export default (values) => validateSchema(values, schema);
