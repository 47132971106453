import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { dateTimeISOToDateMasked } from '../../utils/date';
import { TableEmpty } from '../../../components';
import constants from '../../shared/constants';

const columns = [
  { name: 'Cód.' },
  { name: 'Latitude' },
  { name: 'Longitude' },
  { name: 'Velocidade' },
  { name: 'Link Mapa' },
  { name: 'Data e Hora', align: 'right' },
];

const List = ({
  data,
  loading,
  pagination,
  onChangePagination,
  onChangeNumberItems,
}) => {
  const renderColumns = () => (
    <TableRow>
      {columns.map((item) => (
        <TableCell align={item.align || ''}>{item.name}</TableCell>
      ))}
    </TableRow>
  );

  const renderRows = () => {
    if (Array.isArray(data) && data.length && !loading) {
      return data.map((item) => (
        <TableRow key={item.id}>
          <TableCell padding="dense">{item.id}</TableCell>
          <TableCell padding="dense">{item.latitude}</TableCell>
          <TableCell padding="dense">{item.longitude}</TableCell>
          <TableCell padding="dense">
            {item.velocidade ? `${item.velocidade} Km/h` : '-'}
          </TableCell>
          <TableCell padding="dense">
            <a
              href={`https://www.google.com/maps/@${item.latitude},${item.longitude},20.96z`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Mapa
            </a>
          </TableCell>
          <TableCell padding="dense" align="right">
            {dateTimeISOToDateMasked(item.dataHora)}
          </TableCell>
        </TableRow>
      ));
    }
    return <TableEmpty loading={loading} data={data} />;
  };

  // eslint-disable-next-line
  const renderFooter = () => {
    if (Array.isArray(data)) {
      return (
        <TableRow>
          <TablePagination
            page={pagination.number}
            rowsPerPage={pagination.size}
            count={pagination.totalElements}
            onChangePage={onChangePagination}
            onChangeRowsPerPage={onChangeNumberItems}
            labelRowsPerPage={constants.MESSAGES.mostrarLinhas}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} a ${to} de ${count}`
            }
          />
        </TableRow>
      );
    }
  };

  return (
    <>
      <Row>
        <Col md="12">
          <Table className="table-hover ">
            {renderColumns()}
            <TableBody>{renderRows()}</TableBody>
            <TableFooter>{renderFooter()}</TableFooter>
          </Table>
        </Col>
      </Row>
    </>
  );
};

List.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  onChangeNumberItems: PropTypes.func.isRequired,
};

List.defaultProps = {
  data: [],
  loading: false,
};

export default List;
