import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import React from 'react';

import TextField from './TextField';

const TextFieldWithReduxForm = ({
  input,
  meta: { touched, error },
  ...props
}) => {
  const hasError = Boolean(touched && error);
  return (
    <>
      <TextField error={hasError} {...input} {...props} />
      {hasError && (
        <FormHelperText
          dangerouslySetInnerHTML={{ __html: error }}
          className="x-error"
          error
        />
      )}
    </>
  );
};

TextFieldWithReduxForm.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default TextFieldWithReduxForm;
