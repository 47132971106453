import { Card, Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { montarParamsFiltroPaginacao } from '../../utils/pagination';
import { throwFirstError } from '../../utils/error';
import { Button } from '../../../components';
import { history } from '../../../store';
import * as Apis from '../Api';
import constants from '../../shared/constants';
import Detalhe from './Detalhe';
import LocalizacoesGps from './LocalizacoesGps';
import LocalizacoesWifi from './LocalizacoesWifi';
import LocalizacoesLbs from './LocalizacoesLbs';
import Baterias from './Baterias';

const PAGINATION = {
  last: false,
  totalElements: 0,
  totalPages: 0,
  first: true,
  numberOfElements: 10,
  size: 10,
  number: 0,
};

const ORDER_LOCALIZACAO = {
  orderBy: 'dataHora',
  orderDirection: constants.DEFAULT.DIRECAO.desc,
};

const ORDER_BATERIA = {
  orderBy: 'dataCadastro',
  orderDirection: constants.DEFAULT.DIRECAO.desc,
};

const LOCALIZACAO_GPS = {
  loadingLocalizacaoGps: null,
  localizacoesGps: null,
  paginationLocalizacaoGps: PAGINATION,
  orderLocalizacao: ORDER_LOCALIZACAO,
};

const LOCALIZACAO_WIFI = {
  loadingLocalizacaoWifi: null,
  localizacoesWifi: null,
  paginationLocalizacaoWifi: PAGINATION,
  orderLocalizacao: ORDER_LOCALIZACAO,
};

const LOCALIZACAO_LBS = {
  loadingLocalizacaoLbs: null,
  localizacoesLbs: null,
  paginationLocalizacaoLbs: PAGINATION,
  orderLocalizacao: ORDER_LOCALIZACAO,
};

const BATERIA = {
  baterias: null,
  loadingBateria: false,
  paginationBateria: PAGINATION,
  orderBateria: ORDER_BATERIA,
};

const RASTREADOR = {
  rastreador: null,
  loadingRastreador: false,
};

class Screen extends Component {
  state = {
    rastreadorId: null,
    ...RASTREADOR,
    ...LOCALIZACAO_GPS,
    ...LOCALIZACAO_WIFI,
    ...LOCALIZACAO_LBS,
    ...BATERIA,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    await this.getParametros();
    this.fetchRastreador();
    this.fetchLocalizacoesGpsByFiltros();
    this.fetchLocalizacoesWifiByFiltros();
    this.fetchLocalizacoesLbsByFiltros();
    this.fetchBateriasByFiltros();
  };

  getParametros = () => {
    const { match } = this.props;
    const { id: rastreadorId } = match.params;
    this.setState({ rastreadorId });
  };

  fetchRastreador = async () => {
    const { throwSnackbar } = this.props;
    const { rastreadorId } = this.state;

    if (rastreadorId) {
      this.setState({ loadingRastreador: true });
      try {
        const { data: rastreador } = await Apis.getDetalheCompletoRastreador(
          rastreadorId
        );
        this.setState({ rastreador });
      } catch (error) {
        throwSnackbar(constants.MESSAGES.loadFormError);
      } finally {
        this.setState({ loadingRastreador: false });
      }
    }
  };

  fetchLocalizacoesGpsByFiltros = async () => {
    this.setState({ loadingLocalizacaoGps: true });
    let { paginationLocalizacaoGps, localizacoesGps } = this.state;
    const { throwSnackbar } = this.props;
    const { orderLocalizacao, rastreadorId } = this.state;
    const tipoLocalizacao = 'GPS';

    if (rastreadorId) {
      try {
        const params = montarParamsFiltroPaginacao({
          pagination: paginationLocalizacaoGps,
          order: orderLocalizacao,
        });
        const newParams = { ...params, rastreadorId, tipoLocalizacao };
        const response = await Apis.fetchLocalizacoesByFiltros(
          newParams,
          constants.SETUP.defaultDelay.normal
        );
        const { content, ...paginationResponse } = response.data;
        paginationLocalizacaoGps = paginationResponse;
        localizacoesGps = content;
      } catch (error) {
        if (!throwFirstError(error)) {
          throwSnackbar(constants.MESSAGES.unavailableService);
        }
      }

      this.setState({
        loadingLocalizacaoGps: false,
        paginationLocalizacaoGps,
        localizacoesGps,
      });
    } else {
      throwSnackbar(constants.MESSAGES.loadFormError);
    }
  };

  fetchLocalizacoesWifiByFiltros = async () => {
    this.setState({ loadingLocalizacaoWifi: true });
    let { paginationLocalizacaoWifi, localizacoesWifi } = this.state;
    const { throwSnackbar } = this.props;
    const { orderLocalizacao, rastreadorId } = this.state;
    const tipoLocalizacao = 'WIFI';

    if (rastreadorId) {
      try {
        const params = montarParamsFiltroPaginacao({
          pagination: paginationLocalizacaoWifi,
          order: orderLocalizacao,
        });
        const newParams = { ...params, rastreadorId, tipoLocalizacao };
        const response = await Apis.fetchLocalizacoesByFiltros(
          newParams,
          constants.SETUP.defaultDelay.normal
        );
        const { content, ...paginationResponse } = response.data;
        paginationLocalizacaoWifi = paginationResponse;
        localizacoesWifi = content;
      } catch (error) {
        if (!throwFirstError(error)) {
          throwSnackbar(constants.MESSAGES.unavailableService);
        }
      }

      this.setState({
        loadingLocalizacaoWifi: false,
        paginationLocalizacaoWifi,
        localizacoesWifi,
      });
    } else {
      throwSnackbar(constants.MESSAGES.loadFormError);
    }
  };

  fetchLocalizacoesLbsByFiltros = async () => {
    this.setState({ loadingLocalizacaoLbs: true });
    let { paginationLocalizacaoLbs, localizacoesLbs } = this.state;
    const { throwSnackbar } = this.props;
    const { orderLocalizacao, rastreadorId } = this.state;
    const tipoLocalizacao = 'LBS';

    if (rastreadorId) {
      try {
        const params = montarParamsFiltroPaginacao({
          pagination: paginationLocalizacaoLbs,
          order: orderLocalizacao,
        });
        const newParams = { ...params, rastreadorId, tipoLocalizacao };
        const response = await Apis.fetchLocalizacoesByFiltros(
          newParams,
          constants.SETUP.defaultDelay.normal
        );
        const { content, ...paginationResponse } = response.data;
        paginationLocalizacaoLbs = paginationResponse;
        localizacoesLbs = content;
      } catch (error) {
        if (!throwFirstError(error)) {
          throwSnackbar(constants.MESSAGES.unavailableService);
        }
      }

      this.setState({
        loadingLocalizacaoLbs: false,
        paginationLocalizacaoLbs,
        localizacoesLbs,
      });
    } else {
      throwSnackbar(constants.MESSAGES.loadFormError);
    }
  };

  fetchBateriasByFiltros = async () => {
    this.setState({ loadingBateria: true });
    let { paginationBateria, baterias } = this.state;
    const { throwSnackbar } = this.props;
    const { orderBateria, rastreadorId } = this.state;

    if (rastreadorId) {
      try {
        const params = montarParamsFiltroPaginacao({
          pagination: paginationBateria,
          order: orderBateria,
        });
        const newParams = { ...params, rastreadorId };
        const response = await Apis.fetchBateriasByFiltros(
          newParams,
          constants.SETUP.defaultDelay.normal
        );
        const { content, ...paginationResponse } = response.data;
        paginationBateria = paginationResponse;
        baterias = content;
      } catch (error) {
        if (!throwFirstError(error)) {
          throwSnackbar(constants.MESSAGES.unavailableService);
        }
      }

      this.setState({
        loadingBateria: false,
        paginationBateria,
        baterias,
      });
    } else {
      throwSnackbar(constants.MESSAGES.loadFormError);
    }
  };

  handlePaginationLocalizacaoGpsChangePage = async (event, page) => {
    const { paginationLocalizacaoGps } = this.state;
    await this.setState({
      paginationLocalizacaoGps: { ...paginationLocalizacaoGps, number: page },
    });
    this.fetchLocalizacoesGpsByFiltros();
  };

  handlePaginationLocalizacaoWifiChangePage = async (event, page) => {
    const { paginationLocalizacaoWifi } = this.state;
    await this.setState({
      paginationLocalizacaoWifi: { ...paginationLocalizacaoWifi, number: page },
    });
    this.fetchLocalizacoesWifiByFiltros();
  };

  handlePaginationLocalizacaoLbsChangePage = async (event, page) => {
    const { paginationLocalizacaoLbs } = this.state;
    await this.setState({
      paginationLocalizacaoLbs: { ...paginationLocalizacaoLbs, number: page },
    });
    this.fetchLocalizacoesLbsByFiltros();
  };

  handlePaginationBateriaChangePage = async (event, page) => {
    const { paginationBateria } = this.state;
    await this.setState({
      paginationBateria: { ...paginationBateria, number: page },
    });
    this.fetchBateriasByFiltros();
  };

  handlePaginationLocalizacaoGpsChangeRowsPerPage = async (event) => {
    const { paginationLocalizacaoGps } = this.state;
    await this.setState({
      paginationLocalizacaoGps: {
        ...paginationLocalizacaoGps,
        size: event.target.value,
      },
    });
    this.fetchLocalizacoesGpsByFiltros();
  };

  handlePaginationLocalizacaoWifiChangeRowsPerPage = async (event) => {
    const { paginationLocalizacaoWifi } = this.state;
    await this.setState({
      paginationLocalizacaoWifi: {
        ...paginationLocalizacaoWifi,
        size: event.target.value,
      },
    });
    this.fetchLocalizacoesWifiByFiltros();
  };

  handlePaginationLocalizacaoLbsChangeRowsPerPage = async (event) => {
    const { paginationLocalizacaoLbs } = this.state;
    await this.setState({
      paginationLocalizacaoLbs: {
        ...paginationLocalizacaoLbs,
        size: event.target.value,
      },
    });
    this.fetchLocalizacoesLbsByFiltros();
  };

  handlePaginationBateriaChangeRowsPerPage = async (event) => {
    const { paginationBateria } = this.state;
    await this.setState({
      paginationBateria: { ...paginationBateria, size: event.target.value },
    });
    this.fetchBateriasByFiltros();
  };

  handleVoltar = () => {
    history.goBack();
  };

  renderBotaoVoltar = () => {
    const {
      loadingLocalizacaoGps,
      loadingLocalizacaoWifi,
      loadingLocalizacaoLbs,
      loadingBateria,
    } = this.state;
    return (
      <Row>
        <Col xs="12">
          <Button
            className="ml-1 mb-1 pull-right"
            variant="outline-secondary"
            onClick={this.handleVoltar}
            disabled={
              loadingLocalizacaoGps ||
              loadingLocalizacaoWifi ||
              loadingLocalizacaoLbs ||
              loadingBateria
            }
          >
            Voltar
          </Button>
        </Col>
      </Row>
    );
  };

  renderDetalhe = () => {
    const { loadingRastreador, rastreador } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Detalhe</Card.Title>
              </Card.Header>
              <Card.Body>
                <Detalhe
                  {...this.props}
                  loading={loadingRastreador}
                  data={rastreador}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  renderListaLocalizacoesGps = () => {
    const {
      loadingLocalizacaoGps,
      paginationLocalizacaoGps,
      orderLocalizacao,
      localizacoesGps,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Localizações GPS</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <LocalizacoesGps
                  {...this.props}
                  loading={loadingLocalizacaoGps}
                  data={localizacoesGps}
                  pagination={paginationLocalizacaoGps}
                  order={orderLocalizacao}
                  onChangePagination={
                    this.handlePaginationLocalizacaoGpsChangePage
                  }
                  onChangeNumberItems={
                    this.handlePaginationLocalizacaoGpsChangeRowsPerPage
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  renderListaLocalizacoesWifi = () => {
    const {
      loadingLocalizacaoWifi,
      paginationLocalizacaoWifi,
      orderLocalizacao,
      localizacoesWifi,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Localizações WI-FI</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <LocalizacoesWifi
                  {...this.props}
                  loading={loadingLocalizacaoWifi}
                  data={localizacoesWifi}
                  pagination={paginationLocalizacaoWifi}
                  order={orderLocalizacao}
                  onChangePagination={
                    this.handlePaginationLocalizacaoWifiChangePage
                  }
                  onChangeNumberItems={
                    this.handlePaginationLocalizacaoWifiChangeRowsPerPage
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  renderListaLocalizacoesLbs = () => {
    const {
      loadingLocalizacaoLbs,
      paginationLocalizacaoLbs,
      orderLocalizacao,
      localizacoesLbs,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Localizações LBS</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <LocalizacoesLbs
                  {...this.props}
                  loading={loadingLocalizacaoLbs}
                  data={localizacoesLbs}
                  pagination={paginationLocalizacaoLbs}
                  order={orderLocalizacao}
                  onChangePagination={
                    this.handlePaginationLocalizacaoLbsChangePage
                  }
                  onChangeNumberItems={
                    this.handlePaginationLocalizacaoLbsChangeRowsPerPage
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  renderListaBaterias = () => {
    const { loadingBateria, paginationBateria, orderBateria, baterias } =
      this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Histórico de Baterias</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Baterias
                  {...this.props}
                  loading={loadingBateria}
                  data={baterias}
                  pagination={paginationBateria}
                  order={orderBateria}
                  onChangePagination={this.handlePaginationBateriaChangePage}
                  onChangeNumberItems={
                    this.handlePaginationBateriaChangeRowsPerPage
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  renderScreen = () => {
    const Voltar = this.renderBotaoVoltar;
    const DisplayDetalhe = this.renderDetalhe;
    const ListaLocalizacoesGps = this.renderListaLocalizacoesGps;
    const ListaLocalizacoesWifi = this.renderListaLocalizacoesWifi;
    const ListaLocalizacoesLbs = this.renderListaLocalizacoesLbs;
    const ListaBaterias = this.renderListaBaterias;

    return (
      <>
        <DisplayDetalhe />
        <ListaLocalizacoesGps />
        <ListaLocalizacoesWifi />
        <ListaLocalizacoesLbs />
        <ListaBaterias />

        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Body>
                  <Voltar />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    const ScreenContent = this.renderScreen;
    return <ScreenContent />;
  }
}

Screen.propTypes = {
  match: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
};

export default Screen;
