import { fade } from '@material-ui/core/styles/colorManipulator';
import { Form } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import onlyNumbers from '../../modules/utils/numeric';

const styles = (theme) => ({
  group: {
    marginBottom: 0,
  },
  root: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
    '& > div': {
      height: 'auto !important',
    },
    '& input + div': {
      alignSelf: 'center',
      marginLeft: 0,
      position: 'absolute',
      right: 1,
    },
    '& input + div > button': {
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  error: {
    '& > input': {
      borderColor: 'blue',
      color: theme.palette.error.main,
    },
    '& > textarea': {
      borderColor: theme.palette.error.main,
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    minHeight: 34,
    padding: '6px 12px',
    borderColor: theme.palette.primary.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${fade(theme.palette.primary.main, 0.15)}`,
    },
    '&:disabled': {
      background: theme.palette.grey[50],
      color: theme.palette.text.disabled,
    },
  },
  endAdornment: {
    paddingRight: theme.spacing.unit * 6,
  },
  label: {
    // fontSize: theme.typography.pxToRem(13),
    transform: 'none',
    fontWeight: 'normal',
  },
});

const TEXT_TRANSFORM_ANY = 'any';
const TEXT_TRANSFORM_LOWERCASE = 'lowercase';
const TEXT_TRANSFORM_UPPERCASE = 'uppercase';

const TextField = ({
  classes,
  error,
  InputLabelProps,
  InputProps,
  multiline,
  name,
  onBlur,
  onChange,
  textTransform,
  trimOnBlur,
  type,
  label,
  required,
  placeholder,
  ...props
}) => {
  const rows = multiline ? { rows: 2 } : null;

  const normalizeValue = (value) => {
    if (typeof value !== 'string' || type === 'password') return value;

    switch (textTransform) {
      case TEXT_TRANSFORM_UPPERCASE:
        return value.toUpperCase();
      case TEXT_TRANSFORM_LOWERCASE:
        return value.toLowerCase();
      default:
        return value;
    }
  };

  const handleBlur = (value) => {
    if (typeof value !== 'string' || type === 'password') return value;

    if (trimOnBlur) {
      return value.trim();
    }
    return value;
  };

  const handleKeyUp = (value) => {
    if (type === 'numeric') {
      return onlyNumbers(value.trim());
    }
    return value;
  };

  return (
    <Form.Group className={classes.group}>
      <Form.Label>
        {label ? `${normalizeValue(label)} ${required ? '*' : ''}` : null}
      </Form.Label>
      <Form.Control
        error={error}
        id={name}
        type={type}
        {...rows}
        multiline={multiline}
        placeholder={
          placeholder ? `${placeholder} ${required && !label ? '*' : ''}` : null
        }
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.root,
            error: classes.error,
            input: classNames(
              classes.input,
              InputProps && InputProps.endAdornment ? classes.endAdornment : ''
            ),
          },
          ...InputProps,
        }}
        {...props}
        onChange={(event) => {
          const newEvent = event;

          const { target } = event;
          const start = target.selectionStart;
          const end = target.selectionEnd;

          newEvent.target.value = normalizeValue(newEvent.target.value);

          if (onChange) {
            onChange(newEvent);
            target.setSelectionRange(start, end);
          }
        }}
        onBlur={(event) => {
          const newEvent = event;

          newEvent.target.value = handleBlur(newEvent.target.value);

          if (onBlur) {
            onBlur(newEvent);
          }
        }}
        onKeyUp={(event) => {
          const newEvent = event;
          newEvent.target.value = handleKeyUp(newEvent.target.value);
        }}
      />
    </Form.Group>
  );
};

TextField.defaultProps = {
  error: false,
  InputLabelProps: null,
  InputProps: null,
  multiline: false,
  name: null,
  onBlur: null,
  onChange: null,
  textTransform: TEXT_TRANSFORM_UPPERCASE,
  trimOnBlur: true,
  type: 'text',
  changeMode: 'onChange',
  required: false,
  placeholder: null,
};

TextField.propTypes = {
  required: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  changeMode: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  trimOnBlur: PropTypes.bool,
  type: PropTypes.string,
  textTransform: PropTypes.oneOf([
    TEXT_TRANSFORM_ANY,
    TEXT_TRANSFORM_LOWERCASE,
    TEXT_TRANSFORM_UPPERCASE,
  ]),
};

export default withStyles(styles, { name: 'XTextField' })(TextField);
