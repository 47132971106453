import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { initialFilter } from '../../utils/pagination';

import {
  currentDateMasked,
  currentHoraMasked,
  beginDate,
  dateTimeMaskedToISO,
} from '../../utils/date';
import * as Apis from '../Api';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import constants from '../../shared/constants';
import Form from './Form';
import Mapa from './Mapa';
import validate from './validate';
import DesligarDialog from './dialog/DesligarDialog';
import { Typography } from '../../../components';

const formName = 'MapaScreen';

const {
  SETUP: { defaultDelay },
} = constants;

const FILTROS = {
  tipoVisualizacao: 'LOCALIZACAO_ATUAL',
};

class Screen extends PureComponent {
  state = {
    loading: false,
    loadingSincronizar: false,
    usuariosRastreadores: [],
    rastreadores: [],
    rota: [],
    defaultCenter: [],
    filtros: FILTROS,
    item: null,
    openDesligar: false,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const { filtros } = this.state;
    this.fetchUsuariosRastreadores();
    this.fetchLocalizacoesRastreadoresByFiltros(filtros);
  };

  getDefaultCenter = (rastreadores) =>
    rastreadores.map((x) => ({
      lat: parseFloat(x.position.lat),
      lng: parseFloat(x.position.lng),
    }));

  getRota = (rastreadores) => {
    const { tipoVisualizacao } = this.props;
    return tipoVisualizacao === 'HISTORICO_LOCALIZACAO'
      ? rastreadores.map((x) => ({
          lat: parseFloat(x.position.lat),
          lng: parseFloat(x.position.lng),
        }))
      : [];
  };

  convertLatLng = (rastreadores) =>
    rastreadores.map((x) => ({
      ...x,
      position: {
        lat: parseFloat(x.position.lat),
        lng: parseFloat(x.position.lng),
      },
    }));

  handleSubmit = async (values) => {
    this.fetchLocalizacoesRastreadoresByFiltros(values);
  };

  fetchUsuariosRastreadores = async () => {
    const { throwSnackbar } = this.props;
    try {
      const { data } = await Apis.fetchUsuariosRastreadores();
      this.setState({ usuariosRastreadores: data });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.errorOnLoadField('Rastreadores'));
    }
  };

  fetchLocalizacoesRastreadoresByFiltros = async (filtros) => {
    const { throwSnackbar } = this.props;
    this.setState({ loading: true });
    try {
      const params = initialFilter({ filtros });
      const newValues = {
        ...params,
        dataInicial:
          params.dataInicial &&
          dateTimeMaskedToISO(`${params.dataInicial} ${params.horaInicial}`),
        dataFinal:
          params.dataFinal &&
          dateTimeMaskedToISO(`${params.dataFinal} ${params.horaFinal}`),
      };
      const { data: rastreadores } =
        await Apis.fetchLocalizacoesRastreadoresByFiltros(
          newValues,
          defaultDelay.normal
        );
      this.setState({
        rastreadores: this.convertLatLng(rastreadores),
        rota: this.getRota(rastreadores),
        defaultCenter: this.getDefaultCenter(rastreadores),
      });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.loadFormError);
    } finally {
      this.setState({ loading: false, loadingSincronizar: false });
    }
  };

  handleClean = () => {
    const { reset } = this.props;
    reset();
  };

  handleOpenDesligar = (item) => {
    this.setState({ openDesligar: true, item });
  };

  handleCloseDesligar = () => {
    this.setState({ openDesligar: false, item: null });
  };

  handleDesligar = async () => {
    const { throwSnackbar } = this.props;
    const { item, filtros } = this.state;
    try {
      await Apis.desligarRastreador(item.id);
      throwSnackbar('Desligando rastreador com sucesso.');
      this.fetchLocalizacoesRastreadoresByFiltros(filtros);
    } catch (error) {
      throwSnackbar('Erro ao tentar desligar o rastreador, tente novamente.');
    } finally {
      this.handleCloseDesligar();
    }
  };

  handleSincronizar = async () => {
    const { throwSnackbar } = this.props;
    const { filtros } = this.state;
    try {
      this.setState({ loadingSincronizar: true });

      await Apis.sincronizarRastreadores();
      this.fetchLocalizacoesRastreadoresByFiltros(filtros);
    } catch (error) {
      throwSnackbar(
        'Erro ao tentar sincronizar os rastreadores, tente novamente.'
      );
    }
  };

  renderDialogDesligar = () => {
    const { openDesligar, item } = this.state;
    return (
      openDesligar && (
        <DesligarDialog
          text={item.name}
          show={openDesligar}
          onClose={this.handleCloseDesligar}
          onConfirm={this.handleDesligar}
        />
      )
    );
  };

  renderLoading = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>
              <Row align="center">
                <Col md="12">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                  </Spinner>
                </Col>
                <Col md="12">
                  <Typography variant="caption">Carregando...</Typography>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  renderForm = () => {
    const { handleSubmit } = this.props;
    const { loading, loadingSincronizar, usuariosRastreadores } = this.state;

    const DesligarDialogConfirm = this.renderDialogDesligar;

    return (
      <Container fluid>
        <DesligarDialogConfirm />

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Pesquisa</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form
                  {...this.props}
                  loading={loading}
                  loadingSincronizar={loadingSincronizar}
                  usuariosRastreadores={usuariosRastreadores}
                  handleSubmit={handleSubmit}
                  onSubmit={this.handleSubmit}
                  onClean={this.handleClean}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  renderMap = () => {
    const { handleSubmit } = this.props;
    const { loadingSincronizar, rastreadores, rota, defaultCenter } =
      this.state;

    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body className="table-full-width table-responsive px-0">
                <Mapa
                  {...this.props}
                  loading={loadingSincronizar}
                  onSubmit={this.handleSubmit}
                  handleSubmit={handleSubmit}
                  onOpenDesligar={this.handleOpenDesligar}
                  onSincronizar={this.handleSincronizar}
                  rastreadores={rastreadores}
                  defaultCenter={defaultCenter}
                  rota={rota}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  render() {
    const { loading, loadingSincronizar } = this.state;

    const Loading = this.renderLoading;
    const FormScreen = this.renderForm;
    const MapScreen = this.renderMap;

    return (
      <>
        <FormScreen />

        {loading && !loadingSincronizar && <Loading />}

        {(!loading || (loading && loadingSincronizar)) && <MapScreen />}
      </>
    );
  }
}

Screen.propTypes = {
  reset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  tipoVisualizacao: PropTypes.string.isRequired,
};

const normalizeInfos = () => ({
  tipoVisualizacao: 'LOCALIZACAO_ATUAL',
  dataInicial: beginDate(1),
  horaInicial: currentHoraMasked(),
  dataFinal: currentDateMasked(),
  horaFinal: currentHoraMasked(),
});

const selector = formValueSelector(formName);

const mapStateToProps = (state) => ({
  initialValues: normalizeInfos(),
  tipoVisualizacao: selector(state, 'tipoVisualizacao'),
});

const mapDispatchToProps = {
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

const form = reduxForm({
  validate,
  form: formName,
  destroyOnUnmount: true,
  enableReinitialize: false,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
