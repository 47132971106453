import Cookies from 'js-cookie';

import constants from '../shared/constants';

export const authCookieName = 'bike-rastreador-token';
export const usuarioEmuladoCookieName = 'usuarioEmuladoId';
export const usuarioEmuladorCookieName = 'usuarioEmuladorId';

export const setAuthCookie = (token) =>
  Cookies.set(authCookieName, token, {
    expires: 365,
    domain: constants.URL.LOCATION.hostname,
    secure: true,
    sameSite: 'strict',
  });

export const getAuthToken = () =>
  Cookies.get(authCookieName, {
    domain: constants.URL.LOCATION.hostname,
  });

export const removeAuthCookie = () =>
  Cookies.remove(authCookieName, {
    domain: constants.URL.LOCATION.hostname,
  });

export const getUsuarioEmuladoCookie = () =>
  Cookies.get(usuarioEmuladoCookieName, {
    domain: constants.URL.LOCATION.hostname,
  });

export const setUsuarioEmuladoCookie = (value) =>
  Cookies.set(usuarioEmuladoCookieName, value, {
    expires: 365,
    domain: constants.URL.LOCATION.hostname,
    secure: true,
    sameSite: 'strict',
  });

export const getUsuarioEmuladorCookie = () =>
  Cookies.get(usuarioEmuladorCookieName, {
    domain: constants.URL.LOCATION.hostname,
  });

export const setUsuarioEmuladorCookie = (value) =>
  Cookies.set(usuarioEmuladorCookieName, value, {
    expires: 365,
    domain: constants.URL.LOCATION.hostname,
    secure: true,
    sameSite: 'strict',
  });

export const removeEmulacaoCookies = () => {
  Cookies.remove(usuarioEmuladoCookieName, {
    domain: constants.URL.LOCATION.hostname,
  });
  Cookies.remove(usuarioEmuladorCookieName, {
    domain: constants.URL.LOCATION.hostname,
  });
};
