import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, TextField, VirtualizedSelect } from '../../../components';
import constants from '../../shared/constants';

const { PROTOCOLOS_RASTREADORES } = constants.SETUP;

const Form = ({
  handleSubmit,
  onSubmit,
  onVoltar,
  submitting,
  rastreador,
  chips,
}) => {
  const renderBotaoSalvar = () => (
    <Button
      className="btn-fill mr-3"
      type="submit"
      variant="success"
      loading={submitting}
    >
      Salvar
    </Button>
  );

  const renderBotaoVoltar = () => (
    <Button
      type="button"
      variant="outline-primary"
      onClick={onVoltar}
      loading={submitting}
    >
      Voltar
    </Button>
  );

  const renderBotoes = () => (
    <Row>
      <Col xs="12">
        {renderBotaoSalvar()}
        {renderBotaoVoltar()}
      </Col>
    </Row>
  );

  const isDisabledFields = () => rastreador && rastreador.situacao === 'ATIVO';

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Row>
          <Col lg="3" md="4" sm="6" xs="12">
            <Field
              component={TextField}
              name="imei"
              label="IMEI"
              type="numeric"
              maxLength="20"
              disabled={isDisabledFields()}
              required
            />
          </Col>
          <Col lg="3" md="4" sm="6" xs="12">
            <Field
              name="protocolo"
              component={VirtualizedSelect}
              labelKey="label"
              valueKey="value"
              label="Protocolo"
              options={PROTOCOLOS_RASTREADORES}
              placeholder="Selecione"
              disabled={isDisabledFields()}
              required
            />
          </Col>
          <Col lg="3" md="4" sm="6" xs="12">
            <Field
              name="chipId"
              component={VirtualizedSelect}
              labelKey="text"
              valueKey="id"
              label="Chip"
              options={chips || []}
              placeholder={!chips ? 'Carregando...' : 'Selecione'}
              disabled={isDisabledFields()}
              required
            />
          </Col>
        </Row>

        {renderBotoes()}

        <div className="clearfix" />
      </form>
    </>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onVoltar: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  rastreador: PropTypes.object,
  chips: PropTypes.array,
};

Form.defaultProps = {
  rastreador: null,
  chips: null,
};

export default Form;
