import { arrayContainsSomeFromArray } from '../../utils/array';

export const hasPermission = (userPermissions, routePermissions) => {
  const routePerm =
    typeof routePermissions === 'string'
      ? [routePermissions]
      : routePermissions;

  if (Array.isArray(routePerm) && routePerm.length) {
    return arrayContainsSomeFromArray(userPermissions, routePerm);
  }

  return true;
};
