import constants from '../shared/constants';

export const initialFilter = ({ filtros = {} }) => ({
  ...filtros,
});

export const montarParamsFiltroPaginacao = ({
  filtros = {},
  pagination = {},
  order = {},
}) => ({
  page: pagination.number,
  size: pagination.size,
  ...order,
  ...filtros,
});

export const montarParamsFiltroPaginacaoOld = ({
  filtros = {},
  pagination = {},
  order = {},
}) => ({
  offset: pagination.number,
  limit: pagination.size,
  ...order,
  ...filtros,
});

export const invertOrderDirection = (newDirection) =>
  newDirection === constants.DEFAULT.DIRECAO.asc
    ? constants.DEFAULT.DIRECAO.desc
    : constants.DEFAULT.DIRECAO.asc;

export const changeOrderBy = (
  order = { orderBy: 'id', orderDirection: 'asc' },
  newOrderBy
) => {
  let newOrder = { ...order };
  if (order.orderBy === newOrderBy) {
    newOrder = {
      orderDirection: invertOrderDirection(order.orderDirection),
      orderBy: newOrderBy,
    };
  } else {
    newOrder = {
      orderDirection: constants.DEFAULT.DIRECAO.asc,
      orderBy: newOrderBy,
    };
  }
  return newOrder;
};

export const compareValues =
  (key, order = 'asc') =>
  (a, b) => {
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };

export const sortData = (
  data = [],
  { orderBy = 'id', orderDirection = 'asc' }
) => data.slice().sort(compareValues(orderBy, orderDirection));
