import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
  },
});

class Loading extends PureComponent {
  render() {
    const { classes, className, ...restProps } = this.props;

    return <div className={classNames(classes.root, className)} {...restProps} />;
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyles(styles)(Loading);
