import { axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';

const urlRest = '/api/chips';

export const fetchChipsByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlRest, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};

export const getChip = (id, delay = 0) => {
  const request = axiosPrivate.get(`${urlRest}/${id}`);
  return pMinDelay(request, delay);
};

export const saveChip = (values, delay = 0) => {
  const request = axiosPrivate.post(urlRest, values);
  return pMinDelay(request, delay);
};

export const toggleAtivoInativo = (data, delay = 0) => {
  if (data.situacao === 'A') {
    return pMinDelay(
      axiosPrivate.delete(`${urlRest}/inativar/${data.id}`),
      delay
    );
  }
  return pMinDelay(axiosPrivate.put(`${urlRest}/ativar/${data.id}`), delay);
};

export const fetchChipsDisponiveis = () => axiosPrivate.get('/api/chips/select/disponiveis');

export const fetchChipsAtivos = () => axiosPrivate.get('/api/chips/select');
