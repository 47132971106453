const isDateGreaterThan = (value, param) => {
  const newValue = value;
  const newParam = param;

  newValue.setHours(0, 0, 0, 0);
  newParam.setHours(0, 0, 0, 0);

  return newValue.getTime() < newParam.getTime();
};

export default isDateGreaterThan;
