import { store } from '../../store';
import { throwSnackbar } from '../shared/snackbar/SnackbarDucks';

export const throwFirstError = (error) => {
  if (error && error.response) {
    const errors = error.response.data;
    if (Array.isArray(errors) && errors.length >= 1) {
      const { message } = errors[0];
      if (message) {
        store.dispatch(throwSnackbar(message));
        return true;
      }
    }
  }
  return false;
};

export const extractError = (error) => {
  if (error) {
    const { response } = error;
    if (response) {
      const { data } = response;
      if (Array.isArray(data) && data.length) {
        const msgObject = data[0];
        if (typeof msgObject === 'object') {
          const { message } = msgObject;
          if (message) {
            return message;
          }
        }
      }
    }
  }

  return null;
};
