import { Row, Col } from 'react-bootstrap';
import React from 'react';

const Form = () => (
  <>
    <Row>
      <Col lg="3" md="4" sm="6" xs="12">
        Em desenvolvimento
      </Col>
    </Row>
    <div className="clearfix" />
  </>
);

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
