import { CardBody, Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TextField, Typography, Button } from '../../../../components';

const styles = (theme) => ({
  title: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  text: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
  },
  invalid: {
    color: theme.palette.red[500],
  },
});

class Form extends PureComponent {
  rendeMessageTokenInvalid = () => {
    const { tokenInvalid, classes } = this.props;
    return (
      tokenInvalid && (
        <div className="mt-2 mb-2">
          <Typography variant="ghost" className={classes.invalid}>
            Seu link de redefinição expirou após 72 horas ou já foi usado.
          </Typography>
        </div>
      )
    );
  };

  render() {
    const TokenInvalid = this.rendeMessageTokenInvalid;
    const { handleSubmit, onSubmit, submitting, onBack, classes } = this.props;
    return (
      <CardBody>
        <Typography align="center" gutterBottom className={classes.title}>
          Esqueceu sua senha
        </Typography>

        <TokenInvalid />

        <div className={`${classes.text} mt-2`}>
          Problemas ao fazer login?
          <ul>
            <li>Nomes de usuários estão no formato de um endereço de email.</li>
            <li>As senhas diferenciam maiúsculas de minúsculas.</li>
          </ul>
          Para redefinir a sua senha, insira o seu email de usuário.
        </div>

        <form onSubmit={handleSubmit(onSubmit)} noValidate className="mt-2">
          <FormControl error fullWidth>
            <Field
              name="emailAtual"
              type="text"
              label="Email"
              placeholder="Digite seu email"
              component={TextField}
              textTransform="any"
              autoFocus
              required
            />
          </FormControl>
          <Row>
            <Col xs="6">
              <FormControl fullWidth margin="normal">
                <Button
                  onClick={onBack}
                  loading={submitting}
                  color="secondary"
                  gutter="right"
                  variant="ghost"
                  block
                  outline
                >
                  Cancelar
                </Button>
              </FormControl>
            </Col>
            <Col xs="6">
              <FormControl fullWidth margin="normal">
                <Button
                  loading={submitting}
                  className="btn-fill"
                  color="secondary"
                  type="submit"
                  gutter="right"
                  block
                >
                  Continuar
                </Button>
              </FormControl>
            </Col>
          </Row>
        </form>
      </CardBody>
    );
  }
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  tokenInvalid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Form);
