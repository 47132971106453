import { Typography as MTypography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const styles = (theme) => {
  const GUTTER_SIZE = theme.spacing.unit;

  return {
    inline: {
      display: 'inline',
    },

    divider: {
      display: 'block',
      marginTop: GUTTER_SIZE,
    },

    gutterTop1x: { marginTop: `${GUTTER_SIZE * 1}px !important` },
    gutterTop2x: { marginTop: `${GUTTER_SIZE * 2}px !important` },
    gutterTop3x: { marginTop: `${GUTTER_SIZE * 3}px !important` },

    gutterBottom1x: { marginBottom: `${GUTTER_SIZE * 1}px !important` },
    gutterBottom2x: { marginBottom: `${GUTTER_SIZE * 2}px !important` },
    gutterBottom3x: { marginBottom: `${GUTTER_SIZE * 3}px !important` },

    onClick: { cursor: 'pointer' },

    button: {
      background: 'transparent',
      border: 'none',
    },
  };
};

class Typography extends PureComponent {
  getGutterStyle = () => {
    const { gutterBottom, gutterTop, classes } = this.props;
    const gutterTopSize = typeof gutterTop === 'boolean' ? '1x' : gutterTop;
    const gutterBottomSize =
      typeof gutterBottom === 'boolean' ? '1x' : gutterBottom;
    const classNamesList = [];

    if (gutterTop) {
      classNamesList.push(classes[`gutterTop${gutterTopSize}`]);
    }

    if (gutterBottom) {
      classNamesList.push(classes[`gutterBottom${gutterBottomSize}`]);
    }

    return classNames(classNamesList);
  };

  render() {
    const {
      children,
      classes,
      className,
      component,
      gutterBottom,
      gutterTop,
      inline,
      onClick,
      ...rest
    } = this.props;

    const buttonStyle = component === 'button' ? classes.button : '';
    const onClickStyle = onClick ? classes.onClick : '';
    const rootStyle = inline ? classes.inline : null;

    return (
      <MTypography
        classes={{ root: rootStyle }}
        className={classNames(
          this.getGutterStyle(),
          onClickStyle,
          buttonStyle,
          className
        )}
        onClick={onClick}
        component={component}
        {...rest}
      >
        {children}
      </MTypography>
    );
  }
}

Typography.defaultProps = {
  className: '',
  component: null,
  gutterBottom: false,
  gutterTop: false,
  inline: false,
  onClick: null,
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  gutterBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['1x', '2x', '3x']),
  ]),
  gutterTop: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['1x', '2x', '3x']),
  ]),
  inline: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withStyles(styles, { name: 'Typography' })(Typography);
