import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { Card, Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, TextField, VirtualizedSelect } from '../../../../components';
import constants from '../../../shared/constants';

const { PERIODO_ULTRA_ECONOMIA_BATERIA } = constants.SETUP;

const styles = () => ({
  subtitle: {
    marginTop: '10px',
    fontSize: '0.875rem',
    color: '#a0a0a0',
  },
});

const Form = ({
  handleSubmit,
  onSubmit,
  onVoltar,
  submitting,
  rastreador,
  classes,
}) => {
  const isDisabledFields = () => rastreador && rastreador.id > 0;

  const renderBotaoSalvar = () => (
    <Button
      className="btn-fill mr-3"
      type="submit"
      variant="success"
      loading={submitting}
    >
      Salvar
    </Button>
  );

  const renderBotaoVoltar = () => (
    <Button
      type="button"
      variant="outline-primary"
      onClick={onVoltar}
      loading={submitting}
    >
      Voltar
    </Button>
  );

  const renderBotoes = () => (
    <Row>
      <Col xs="12">
        {renderBotaoSalvar()}
        {renderBotaoVoltar()}
      </Col>
    </Row>
  );

  const renderCardForm = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Cadastrar</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg="3" md="4" sm="6" xs="12">
                  <Field
                    component={TextField}
                    name="imei"
                    label="IMEI"
                    type="numeric"
                    disabled={isDisabledFields()}
                    maxLength="20"
                    required
                  />
                </Col>
                <Col lg="3" md="4" sm="6" xs="12">
                  <Field
                    component={TextField}
                    name="descricao"
                    label="Descrição"
                    type="text"
                    maxLength="150"
                    required
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  const renderCardIntervaloLocalizacaoNormal = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                Intevalo de Posicionamento no Modo Normal
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg="3" md="4" sm="6" xs="12">
                  <Field
                    component={TextField}
                    name="intervaloLocalizacao"
                    label="Intervalo de posicionamento (MIN)"
                    maxLength="3"
                    type="numeric"
                    required
                  />
                </Col>
              </Row>

              <p className={classes.subtitle}>
                No modo Normal, quando houver vibrações/movimento, o rastreador
                irá enviar posicionamentos seguindo o “intervalo de
                posicionamento” configurado pelo usuário na tela “Editar” (Meus
                Rastreadores -&gt; “Nome do rastreador” -&gt; Editar). Por
                exemplo, se o intervalo configurado pelo usuário for 1 minuto, o
                rastreador enviará um posicionamento (coordenadas geográficas)
                quando for submetido a 1 minutos de vibração ou movimento. Neste
                modo, o rastreador irá enviar dados de LBS (torres de celular) e
                dados de WiFi (mac address) sempre que os mesmos estiverem
                disponíveis. Ou seja, caso o rastreador esteja próximo de torres
                de celular ou roteadores WiFi.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  const renderCardIntervaloUltraEconomiaBateria = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                Intevalo de Posicionamento no Modo de Ultra-Economia de Bateria
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg="3" md="4" sm="6" xs="12">
                  <Field
                    name="periUltraEconBateria"
                    component={VirtualizedSelect}
                    labelKey="label"
                    valueKey="value"
                    label="Intervalo de posicionamento"
                    options={PERIODO_ULTRA_ECONOMIA_BATERIA}
                    placeholder="Selecione"
                    required
                  />
                </Col>
              </Row>

              <p className={classes.subtitle}>
                No modo de Ultra-Economia de Bateria, o rastreador irá enviar
                posicionamentos seguindo os “intervalos de posicionamento” pré
                definidos pelo usuário. Por exemplo, caso o intervalo definido
                seja 30 minutos, o rastreador irá: a cada 30 minutos, ligar
                automaticamente; enviar um posicionamento (coordenadas
                geográficas) - dados de LBS (torres de celular) - dados de WiFi
                (mac address), se disponíveis; e desligar automaticamente. Caso
                o usuário altere o intervalo, a nova configuração passará a
                valer após o religamento do rastreador, seja ele automático
                (descrito acima) ou de forma manual (com o pressionamento do
                compartimento azul do rastreador).
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  const renderCardBotoes = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>{renderBotoes()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderCardForm()}
      {renderCardIntervaloLocalizacaoNormal()}
      {renderCardIntervaloUltraEconomiaBateria()}
      {renderCardBotoes()}
      <div className="clearfix" />
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onVoltar: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  rastreador: PropTypes.object,
};

Form.defaultProps = {
  rastreador: null,
};

export default withStyles(styles)(Form);
