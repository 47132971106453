import { validateSchema } from '../../../utils/validate';

const schema = {
  rules: {
    imei: {
      required: true,
    },
    descricao: {
      required: true,
    },
    intervaloLocalizacao: {
      required: true,
    },
  },
};

export default (values) => validateSchema(values, schema);
