import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/animate.min.css';

import './assets/css/styles.css?v=2.0.0'; // eslint-disable-line
import './assets/scss/styles.scss?v=2.0.0'; // eslint-disable-line
import '@fortawesome/fontawesome-free/css/all.min.css';

import { store, persistor, history } from './store';
import Routes from './routes';

import theme from './theme';

const root = document.querySelector('#root');

const Root = () => (
  <MuiThemeProvider theme={theme}>
    <Routes />
  </MuiThemeProvider>
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  root
);
