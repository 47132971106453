import { Container } from 'react-bootstrap';
import React, { Component } from 'react';
import constants from '../../modules/shared/constants';

const year = new Date().getFullYear();

class Footer extends Component {
  render() {
    return (
      <footer className="footer px-0 px-lg-3">
        <Container fluid>
          <nav>
            <p className="copyright text-center">
              Copyright © {year}{' '}
              <a href="https://petmap.com.br" target="blank">PetMap</a>.{' '}
              v{constants.SETUP.__version__}, Todos os Direitos Reservados
            </p>
          </nav>
        </Container>
      </footer>
    );
  }
}

export default Footer;
