import { validateSchema } from '../../utils/validate';

const schema = {
  rules: {
    nome: {
      required: true,
    },
    dataNascimento: {
      required: false,
      date: ['overAge 18', '<= today'],
    },
    email: {
      required: true,
      email: true,
    },
    telefoneCelular: {
      required: true,
    },
    cpf: {
      required: true,
      cpf: true,
    },
    nivelId: {
      required: true,
    },
  },
};

export default (values) => validateSchema(values, schema);
