export const SNACKBAR_THROW = 'SNACKBAR/THROW';
export const SNACKBAR_RESET = 'SNACKBAR/RESET';

const INITIAL_STATE = {
  open: false,
  message: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SNACKBAR_THROW:
      return {
        ...state,
        open: true,
        message: payload,
      };
    case SNACKBAR_RESET:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export const throwSnackbar = (message) => (dispatch) => {
  dispatch({ type: SNACKBAR_THROW, payload: message });
};

export const reset = () => (dispatch) => {
  dispatch({ type: SNACKBAR_RESET });
};
