import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';

import Item from './item';

const ITEM_HEIGHT = 48;

const styles = () => ({
  rootIconButtonGutterLess: {
    padding: '0px !important',
    width: 'auto !important',
  },
  disableButtonHover: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  disableButton: {
    cursor: 'not-allowed',
  },
});

class ActionButton extends PureComponent {
  state = {
    anchorEl: null,
  };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  render() {
    const { options, icon: Icon, classes, gutter, disabled } = this.props;

    const { anchorEl } = this.state;

    const gutterStyle = !gutter ? classes.rootIconButtonGutterLess : null;
    const disabledStyle = disabled ? classes.disableButton : null;

    return (
      <div className={disabledStyle}>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? 'action-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.disableButtonHover}
          classes={{ root: gutterStyle }}
          disabled={disabled}
          disableRipple
        >
          <Icon />
        </IconButton>
        {anchorEl ? (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                maxWidth: 300,
                minWidth: 200,
              },
            }}
          >
            {options.map(option => (
              <Item
                key={option.content}
                data={option.data}
                onClick={option.onClick}
                onClose={this.handleClose}
              >
                {option.content}
              </Item>
            ))}
          </Menu>
        ) : null}
      </div>
    );
  }
}

ActionButton.defaultProps = {
  gutter: true,
  disabled: false,
};

ActionButton.propTypes = {
  icon: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      data: PropTypes.any,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  classes: PropTypes.object.isRequired,
  gutter: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(styles, { name: 'XActionButton' })(ActionButton);
