import { validateSchema } from '../../../utils/validate';

const schema = {
  rules: {
    nome: {
      required: true,
    },
    email: {
      required: true,
      email: true,
    },
    telefoneCelular: {
      required: true,
    },
    senha: {
      required: true,
    },
  },
};

export default (values) => validateSchema(values, schema);
