import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { dateMaskedToISO, dateISOToDateMasked } from '../../utils/date';
import { throwFirstError } from '../../utils/error';
import { Typography } from '../../../components';
import * as CidadeApi from '../../shared/cidade/Api';
import * as Ducks from '../Ducks';
import * as EnderecoApi from '../../shared/endereco/Api';
import * as EstadoApi from '../../shared/estado/Api';
import * as NivelApi from '../../shared/nivel/Api';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import * as UsuarioApi from '../Api';
import constants from '../../shared/constants';
import Form from './Form';
import paths from '../../auth/paths';
import validate from './validate';

const formName = 'UsuarioManterScreen';

const {
  SETUP: { defaultDelay },
  MESSAGES,
} = constants;

class Screen extends Component {
  state = {
    loading: false,
    estados: [],
    cidades: [],
    niveis: [],
    usuario: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const { resetObject } = this.props;
    resetObject();
    this.fetchEstados();
    this.fetchNiveis();
    this.fetchUsuario();
  };

  fetchUsuario = async () => {
    const { match, getUsuario, throwSnackbar, change } = this.props;
    const { id } = match.params;

    if (id) {
      this.setState({ loading: true });
      try {
        const { data: usuario } = await getUsuario(id, defaultDelay.normal);
        this.setState({ usuario });
        change('dataNascimento', dateISOToDateMasked(usuario.dataNascimento));
        this.renderFieldCidadeByEstado(usuario.estadoId);
      } catch (error) {
        throwSnackbar(constants.MESSAGES.loadFormError);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  fetchEstados = async () => {
    const { throwSnackbar } = this.props;
    try {
      const estados = await EstadoApi.fetchEstados();
      this.setState({ estados: estados.data });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.errorOnLoadField('Estado'));
    }
  };

  fetchCidades = async (estadoId) => {
    const { throwSnackbar } = this.props;
    try {
      const cidades = await CidadeApi.fetchCidadesByEstado(estadoId);
      this.setState({ cidades: cidades.data });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.errorOnLoadField('Cidade'));
    }
  };

  fetchNiveis = async () => {
    const { throwSnackbar } = this.props;
    try {
      const niveis = await NivelApi.fetchNiveis();
      this.setState({ niveis: niveis.data });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.errorOnLoadField('Estado'));
    }
  };

  handleSubmit = async (values) => {
    const { throwSnackbar } = this.props;
    try {
      const newValues = {
        ...values,
        dataNascimento: dateMaskedToISO(values.dataNascimento),
      };
      const { data } = await UsuarioApi.saveUsuario(newValues, 500);
      this.handleTratarSubmitSuccess(data);
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(MESSAGES.unavailableService);
      }
    }
  };

  handleTratarSubmitSuccess = (id) => {
    if (id) {
      const { throwSnackbar, history, resetObject } = this.props;
      resetObject();
      history.push(paths.USUARIO_LISTAR.fullPath);
      throwSnackbar(MESSAGES.saveFormUsuarioSuccess);
    }
  };

  handleVoltar = () => {
    const { resetObject, history } = this.props;
    resetObject();
    history.push(paths.USUARIO_LISTAR.fullPath);
  };

  handleChangeEstado = (event, value) => {
    this.resetEstadoDependencies();
    if (value && value > 0) {
      this.fetchCidades(value);
    }
  };

  handleBlurCep = async (event, value) => {
    const { change } = this.props;
    const { data } = await EnderecoApi.getEnderecoByCep(value);
    change('cep', data.cep);
    change('logradouro', data.logradouro);
    change('complemento', data.complemento);
    change('bairro', data.bairro);
    change('uf', data.estado);
    change('estadoId', data.estadoId);
    change('cidadeId', data.cidadeId);
    await this.fetchCidades(data.estadoId);
  };

  resetEstadoDependencies = () => {
    this.resetFieldCidade();
  };

  resetFieldCidade = () => {
    const { untouch, change } = this.props;
    untouch('cidadeId');
    change('cidadeId', '');
    this.setState({ cidades: [] });
  };

  renderFieldCidadeByEstado = (estadoId) => {
    if (estadoId && estadoId > 0) {
      this.fetchCidades(estadoId);
    }
  };

  renderLoading = () => (
    <Card.Body>
      <Row align="center">
        <Col md="12">
          <Spinner animation="border" role="status">
            <span className="sr-only">Carregando...</span>
          </Spinner>
        </Col>
        <Col md="12">
          <Typography variant="caption">Carregando...</Typography>
        </Col>
      </Row>
    </Card.Body>
  );

  renderScreen = () => {
    const { handleSubmit } = this.props;
    const { estados, cidades, niveis } = this.state;
    return (
      <>
        <Card.Header>
          <Card.Title as="h4">Cadastrar</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form
            {...this.props}
            onVoltar={this.handleVoltar}
            onSubmit={this.handleSubmit}
            handleSubmit={handleSubmit}
            estados={estados}
            cidades={cidades}
            niveis={niveis}
            onChangeEstado={this.handleChangeEstado}
            onBlurCep={this.handleBlurCep}
          />
        </Card.Body>
      </>
    );
  };

  render() {
    const { loading, usuario } = this.state;
    const Loading = this.renderLoading;
    const ScreenContent = this.renderScreen;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              {loading && <Loading />}
              {(usuario || !loading) && <ScreenContent />}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Screen.propTypes = {
  match: PropTypes.object.isRequired,
  untouch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getUsuario: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  resetObject: PropTypes.func.isRequired,
  itemIsLoading: PropTypes.bool.isRequired,
  usuario: PropTypes.object.isRequired,
};

const normalizeInfos = (values) =>
  values
    ? {
        ...values,
        dataNascimento:
          values.dataNascimento && dateISOToDateMasked(values.dataNascimento),
      }
    : {};

const mapStateToProps = (state) => ({
  initialValues: normalizeInfos(state.usuario.item),
  itemIsLoading: state.usuario.itemIsLoading,
  usuario: state.login.usuario,
});

const mapDispatchToProps = {
  getUsuario: Ducks.getUsuario,
  throwSnackbar: SnackbarDucks.throwSnackbar,
  resetObject: Ducks.resetObject,
};

const form = reduxForm({
  validate,
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
