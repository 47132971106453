import { Field } from 'redux-form';
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, TextField, MaskTextField, VirtualizedSelect } from '../../../components';
import constants from '../../shared/constants';

const { SEXO_OPTIONS } = constants.SETUP;

const Form = ({
  handleSubmit,
  onSubmit,
  onVoltar,
  submitting,
  estados,
  cidades,
  niveis,
  onChangeEstado,
  onBlurCep,
}) => {
  const renderBotaoSalvar = () => (
    <Button
      className="btn-fill mr-3"
      type="submit"
      variant="success"
      loading={submitting}
    >
      Salvar
    </Button>
  );

  const renderBotaoVoltar = () => (
    <Button
      type="button"
      variant="outline-primary"
      onClick={onVoltar}
      loading={submitting}
    >
      Voltar
    </Button>
  );

  const renderBotoes = () => (
    <Row>
      <Col xs="12">
        {renderBotaoSalvar()}
        {renderBotaoVoltar()}
      </Col>
    </Row>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row>
        <Col lg="6" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="nome"
            label="Nome"
            type="text"
            maxLength="50"
            required
          />
        </Col>
        <Col lg="2" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="99/99/9999"
            name="dataNascimento"
            label="Data de Nascimento"
            type="text"
          />
        </Col>
        <Col lg="4" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="email"
            label="Email"
            type="text"
            maxLength="50"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="999.999.999-99"
            name="cpf"
            label="CPF"
            required
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field component={TextField} name="rg" label="RG" type="text" maxLength="15" />
        </Col>
        <Col lg="6" md="4" sm="6" xs="12">
          <Field
            name="sexo"
            component={VirtualizedSelect}
            labelKey="label"
            valueKey="value"
            label="Sexo"
            options={SEXO_OPTIONS}
            placeholder="Selecione"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="(99) 9 9999-9999"
            name="telefoneCelular"
            label="Tel. Celular"
            type="text"
            required
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="(99) 9999-9999"
            name="telefoneResidencial"
            label="Tel. Residencial / Celular"
            type="text"
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="(99) 9999-9999"
            name="telefoneComercial"
            label="Tel. Comercial / Celular"
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col className="mt-3">
          <Card.Title as="h4">Endereço</Card.Title>
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={MaskTextField}
            mask="99999-999"
            name="cep"
            label="CEP"
            type="text"
            onBlur={onBlurCep}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="logradouro"
            label="Logradouro"
            type="text"
            maxLength="50"
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="numero"
            label="Número"
            type="numeric"
            maxLength="5"
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="complemento"
            label="Complemento"
            type="text"
            maxLength="100"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="bairro"
            label="Bairro"
            type="text"
            maxLength="100"
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            name="estadoId"
            component={VirtualizedSelect}
            labelKey="nome"
            valueKey="id"
            label="Estado"
            options={estados}
            placeholder={estados.length === 0 ? 'Carregando...' : 'Selecione'}
            onChange={onChangeEstado}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            name="cidadeId"
            component={VirtualizedSelect}
            labelKey="nome"
            valueKey="id"
            label="Cidade"
            options={cidades}
            disabled={cidades.length === 0}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <Field
            component={TextField}
            name="referencia"
            label="Referência"
            type="text"
            maxLength="100"
          />
        </Col>
      </Row>

      <Row>
        <Col className="mt-3">
          <Card.Title as="h4">Perfil</Card.Title>
        </Col>
      </Row>

      <Row>
        <Col lg="6" md="4" sm="6" xs="12">
          <Field
            name="nivelId"
            component={VirtualizedSelect}
            labelKey="nome"
            valueKey="id"
            label="Nível"
            options={niveis}
            placeholder="Selecione"
            required
          />
        </Col>
      </Row>

      {renderBotoes()}

      <div className="clearfix" />
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onVoltar: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  estados: PropTypes.array,
  cidades: PropTypes.array,
  niveis: PropTypes.array.isRequired,
  onChangeEstado: PropTypes.func.isRequired,
  onBlurCep: PropTypes.func.isRequired,
};

Form.defaultProps = {
  estados: [],
  cidades: [],
};

export default Form;
