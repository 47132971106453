import { Card, Container, Row, Col } from 'react-bootstrap';
import React, { Component } from 'react';

import Form from './Form';

class Screen extends Component {
  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Meus Pagamentos</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form {...this.props} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Screen;
