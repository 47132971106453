export default {
  EMULAR_USUARIO: 'ROLE_PER1',
  VISUALIZAR_USUARIOS: 'ROLE_PER2',
  VISUALIZAR_CHIPS: 'ROLE_PER5',
  VISUALIZAR_TODOS_RASTREADORES: 'ROLE_PER100',
  VISUALIZAR_RASTREADORES_CLIENTE: 'ROLE_PER150',
  DASH_TOTALIZADOR: 'ROLE_PER200',
  DASH_SITUACAO: 'ROLE_PER201',
  DASH_SITUACAO_ENERGIA: 'ROLE_PER202',
  VISUALIZAR_TODOS_PAGAMENTOS: 'ROLE_PER300',
  VISUALIZAR_PAGAMENTOS_CLIENTE: 'ROLE_PER301',
};
