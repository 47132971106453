import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getAuthToken } from '../Helper';
import { hasSelfPermission } from '../permission';
import paths from '../paths';

// import NaoPermitido from '../../../components/naoPermitido';

const PrivateRoute = ({
  component: Component,
  template: Template,
  ...rest
}) => {
  class Authentication extends PureComponent {
    checkPermission = () => {
      const {
        login: { usuario },
      } = this.props;
      return usuario && hasSelfPermission(rest.roles);
    };

    handleRender = (props) => {
      const isAuth = getAuthToken();
      if (isAuth) {
        const canAccess = this.checkPermission();

        if (canAccess) {
          if (Template) {
            return (
              <Template {...props}>
                <Component {...props} />
              </Template>
            );
          }
          return <Component {...props} />;
        }
        return null; // <NaoPermitido />;
      }

      return (
        <Redirect
          to={{
            pathname: paths.LOGIN.fullPath,
            state: { from: props.location },
          }}
        />
      );
    };

    render() {
      return <Route {...rest} render={(props) => this.handleRender(props)} />;
    }
  }

  const mapStateToProps = (state) => ({
    login: state.login,
    router: state.router,
  });

  Authentication.propTypes = {
    login: PropTypes.object.isRequired,
    location: PropTypes.object,
    state: PropTypes.object,
    router: PropTypes.object,
  };

  Authentication.defaultProps = {
    location: null,
    state: null,
    router: null,
  };

  const AuthenticationContainer = connect(mapStateToProps)(Authentication);
  return <AuthenticationContainer />;
};

PrivateRoute.defaultProps = {
  roles: [],
  template: null,
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  roles: PropTypes.array,
  template: PropTypes.object,
};

export default PrivateRoute;
