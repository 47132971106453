import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { throwFirstError } from '../../utils/error';
import { Typography } from '../../../components';
import * as Apis from '../Api';
import * as Ducks from '../Ducks';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import constants from '../../shared/constants';
import Form from './Form';
import paths from '../../auth/paths';
import validate from './validate';

const formName = 'ChipManterScreen';

const {
  SETUP: { defaultDelay },
  MESSAGES,
} = constants;

class Screen extends Component {
  state = {
    loading: false,
    chip: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const { resetObject } = this.props;
    resetObject();
    this.fetchChip();
  };

  fetchChip = async () => {
    const { match, getChip, throwSnackbar } = this.props;
    const { id } = match.params;

    if (id) {
      this.setState({ loading: true });
      try {
        const { data: chip } = await getChip(id, defaultDelay.normal);
        this.setState({ chip });
      } catch (error) {
        throwSnackbar(constants.MESSAGES.loadFormError);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  handleSubmit = async (values) => {
    const { throwSnackbar } = this.props;
    try {
      const newValues = {
        ...values,
      };
      const { data } = await Apis.saveChip(newValues, 500);
      this.handleTratarSubmitSuccess(data);
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(MESSAGES.unavailableService);
      }
    }
  };

  handleTratarSubmitSuccess = (id) => {
    if (id) {
      const { throwSnackbar, history, resetObject } = this.props;
      resetObject();
      history.push(paths.CHIP_LISTAR.fullPath);
      throwSnackbar(MESSAGES.saveFormChipSuccess);
    }
  };

  handleVoltar = () => {
    const { resetObject, history } = this.props;
    resetObject();
    history.push(paths.CHIP_LISTAR.fullPath);
  };

  renderLoading = () => (
    <Card.Body>
      <Row align="center">
        <Col md="12">
          <Spinner animation="border" role="status">
            <span className="sr-only">Carregando...</span>
          </Spinner>
        </Col>
        <Col md="12">
          <Typography variant="caption">Carregando...</Typography>
        </Col>
      </Row>
    </Card.Body>
  );

  renderScreen = () => {
    const { handleSubmit } = this.props;
    const { chip } = this.state;
    return (
      <>
        <Card.Header>
          <Card.Title as="h4">Cadastrar</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form
            {...this.props}
            chip={chip}
            onVoltar={this.handleVoltar}
            onSubmit={this.handleSubmit}
            handleSubmit={handleSubmit}
          />
        </Card.Body>
      </>
    );
  };

  render() {
    const { loading, chip } = this.state;
    const Loading = this.renderLoading;
    const ScreenContent = this.renderScreen;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              {loading && <Loading />}
              {(chip || !loading) && <ScreenContent />}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Screen.propTypes = {
  match: PropTypes.object.isRequired,
  untouch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getChip: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  resetObject: PropTypes.func.isRequired,
  itemIsLoading: PropTypes.bool.isRequired,
};

const normalizeInfos = (values) =>
  values
    ? {
        ...values,
      }
    : {};

const mapStateToProps = (state) => ({
  initialValues: normalizeInfos(state.chip.item),
  itemIsLoading: state.chip.itemIsLoading,
});

const mapDispatchToProps = {
  getChip: Ducks.getChip,
  throwSnackbar: SnackbarDucks.throwSnackbar,
  resetObject: Ducks.resetObject,
};

const form = reduxForm({
  validate,
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
