import { Card, Col } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ItemPlaceholder } from '../../../../../../components/Placeholder';

const styles = () => ({
  title: {
    height: 25,
    width: 100,
  },
  subTitle: {
    height: 25,
    marginTop: 10,
    width: 70,
  },
  grafico: {
    height: 270,
  },
});

class Loading extends PureComponent {
  renderItem = () => {
    const { classes } = this.props;
    return (
      <Col md="6">
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <ItemPlaceholder className={classes.title} />
            </Card.Title>
            <p className="card-category">
              <ItemPlaceholder className={classes.subTitle} />
            </p>
          </Card.Header>
          <Card.Body>
            <ItemPlaceholder className={classes.grafico} />
          </Card.Body>
        </Card>
      </Col>
    );
  };

  render() {
    const Item = this.renderItem;
    return <Item />;
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
