import { Navbar, Container, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import React from 'react';

import components from '../../templates/components';
import Emulacao from '../../modules/usuario/emulacao';

const Header = () => {
  const location = useLocation();

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    const node = document.createElement('div');
    node.id = 'bodyClick';
    // eslint-disable-next-line
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    const { pathname } = location;
    // eslint-disable-next-line
    for (let i = 0; i < components.length; i++) {
      const { name, fullPath } = components[i];
      const eqSubstring =
        pathname.substring(0, pathname.lastIndexOf('/')) === fullPath;
      const eq = pathname === fullPath;

      if (eqSubstring || eq) {
        return name;
      }
    }
    return 'Brand';
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v" />
            </Button>
            <Navbar.Brand
              href="#home"
              onClick={(e) => e.preventDefault()}
              className="mr-2"
            >
              {getBrandText()}
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>
      <Emulacao />
    </>
  );
};

export default Header;
