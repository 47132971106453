import * as DEFAULT from './global';
import * as HTTP_STATUS from './httpStatus';
import * as MESSAGES from './message';
import * as SETUP from './setup';
import * as URL from './url';

export default {
  SETUP,
  URL,
  MESSAGES,
  HTTP_STATUS,
  DEFAULT,
};
