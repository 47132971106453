import { connect } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import PropTypes from 'prop-types';
import React from 'react';

import * as SnackbarDucks from '../../modules/shared/snackbar/SnackbarDucks';

const SharedLayout = ({ open, message, reset }) => {
  const notificationAlertRef = React.useRef(null);

  const handleClose = () => reset();

  const notify = (place) => {
    const options = {
      place,
      message: <div>{message}</div>,
      type: 'primary',
      // icon: 'nc-icon nc-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    handleClose();
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {open && notify('bc')}
    </>
  );
};

SharedLayout.propTypes = {
  reset: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
});

const mapDispatchToProps = {
  reset: SnackbarDucks.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedLayout);
