import 'moment/locale/pt-br';
import moment from 'moment';

import constants from '../../modules/shared/constants';
import validate from '../../lib/validate';

export const validateSchema = (values, schema) => {
  const errors = {};
  if (schema && schema.rules) {
    const fields = Object.keys(schema.rules);
    const validation = validate.schema(schema, values);

    fields.forEach((field) => {
      errors[field] = validation[field].errorText;
    });
  }

  return errors;
};

export const validateSchemaContato = (values) => {
  const errors = {};
  if (!values.contatos || !values.contatos.length) {
    errors.contatos = { _error: 'Deve ser inserido pelo menos um contato.' };
  } else {
    const contatosArrayErrors = [];
    values.contatos.forEach((contato, contatoIndex) => {
      const contatoErrors = {};
      if (!contato || !contato.nome) {
        contatoErrors.nome = 'Campo obrigatório.';
        contatosArrayErrors[contatoIndex] = contatoErrors;
      }
      if (!contato || !contato.telefone) {
        contatoErrors.telefone = 'Campo obrigatório.';
        contatosArrayErrors[contatoIndex] = contatoErrors;
      }
    });
    if (contatosArrayErrors.length) {
      errors.contatos = contatosArrayErrors;
    }
  }
  return errors;
};

export const validateDateRange = (
  validation,
  values,
  range,
  { dataInicialKey = 'dataInicial', dataFinalKey = 'dataFinal' } = {}
) => {
  const newValidation = { ...validation };
  const { dateFormat } = constants.SETUP;
  const { periodoPermitido } = constants.MESSAGES;

  const validationDataInicial = validation[dataInicialKey];
  const validationDataFinal = validation[dataFinalKey];

  if (!validationDataInicial.errorStatus && !validationDataFinal.errorStatus) {
    const { dataInicial, dataFinal } = values;
    const inicio = moment(dataInicial, dateFormat);
    const fim = moment(dataFinal, dateFormat);
    const diff = fim.diff(inicio, 'days');

    if (diff > range) {
      newValidation.dataFinal.errorText = periodoPermitido(range);
    }
  }

  return newValidation;
};
