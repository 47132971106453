import { Button as MButton } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const styles = (theme) => ({
  holder: {
    position: 'relative',
    display: 'inline-block',
    '@media (max-width: 576px)': {
      marginTop: 10,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 10,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 10,
    },
  },
  holderFullWidth: {
    position: 'relative',
    display: 'block',
    '@media (max-width: 576px)': {
      marginTop: 10,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 10,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 10,
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -Math.abs(theme.spacing.unit * 3) / 2,
    marginLeft: -Math.abs(theme.spacing.unit * 3) / 2,
  },
  gutterTop: {
    marginTop: theme.spacing.unit,
  },
  gutterRight: {
    marginRight: theme.spacing.unit,
  },
  gutterBottom: {
    marginBottom: theme.spacing.unit,
  },
  gutterLeft: {
    marginLeft: theme.spacing.unit,
  },
  fullWidth: {
    width: '100%',
  },
  ghost: {
    borderWidth: 1,
    borderStyle: 'solid',
  },
  floating: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing.unit * 3,
  },
  sizeExtraLarge: {
    minHeight: 56,
    padding: theme.spacing.unit * 2,
  },
});

class Button extends PureComponent {
  getGutter = () => {
    const { gutter, classes } = this.props;

    if (!gutter) return '';

    return classNames(
      gutter.split(' ').map((direction) => {
        switch (direction) {
          case 'all':
            return classNames(
              classes.gutterTop,
              classes.gutterRight,
              classes.gutterBottom,
              classes.gutterLeft
            );

          case 'top':
            return classes.gutterTop;

          case 'right':
            return classes.gutterRight;

          case 'bottom':
            return classes.gutterBottom;

          case 'left':
            return classes.gutterLeft;

          default:
            return '';
        }
      })
    );
  };

  renderLabel = () => {
    const { loading, loadingText, displayText, children } = this.props;
    return loading && displayText ? loadingText : children;
  };

  render() {
    const {
      classes,
      loading,
      loadingText,
      gutter,
      theme,
      fullWidth,
      variant,
      ...props
    } = this.props;

    const gutters = this.getGutter();

    return (
      <div
        className={`${
          fullWidth ? classes.holderFullWidth : classes.holder
        } ${gutters}`}
      >
        <MButton disabled={loading} fullWidth={fullWidth} {...props} variant={variant}>
          {this.renderLabel()}
        </MButton>
        {loading && (
          <CircularProgress
            size={theme.spacing.unit * 3}
            className={classes.buttonProgress}
          />
        )}
      </div>
    );
  }
}

Button.defaultProps = {
  fullWidth: false,
  loading: false,
  displayText: true,
  loadingText: 'AGUARDE',
  gutter: null,
  variant: 'primary'
};

Button.propTypes = {
  gutter: PropTypes.object,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  displayText: PropTypes.bool,
  loadingText: PropTypes.string,
  theme: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

export default withStyles(styles, { withTheme: true, name: 'Button' })(Button);
