import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const styles = () => ({
  root: {
    overflow: 'hidden',
    position: 'relative',

    '&:before': {
      animation: 'XPhAnimation 1s linear infinite',
      background:
        'linear-gradient(to right, transparent 46%, rgba(255, 255, 255, 0.35) 50%, transparent 54%) 50% 50%',
      bottom: 0,
      content: '" "',
      left: '50%',
      marginLeft: '-250%',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '500%',
      zIndex: 1,
    },
  },
  '@keyframes XPhAnimation': {
    '0%': {
      transform: 'translate3d(-30%, 0, 0)',
    },
    '100%': {
      transform: 'translate3d(30%, 0, 0)',
    },
  },
});

class Loading extends PureComponent {
  render() {
    const { classes, ...restProps } = this.props;

    return <div className={classes.root} {...restProps} />;
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
