import { useLocation, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import AdminNavbar from '../components/Navbars/AdminNavbar';
import components from '../templates/components';
import Footer from '../components/Footer/Footer';
import Sidebar from '../components/Sidebar/Sidebar';

function Admin({ history, match, ...rest }) {
  const location = useLocation();
  const mainPanel = React.useRef(null);

  const getRoutes = () =>
    components.map((prop, key) => {
      if (prop.layout === '/admin' || prop.layout === '/user') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} {...rest} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      const element = document.getElementById('bodyClick');
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar color="black" routes={components} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar history={history} {...rest} />
          <div className="content">
            <Switch>{getRoutes()}</Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

Admin.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(Admin);
