import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { dateMaskedToISO, dateISOToDateMasked } from '../../utils/date';
import { throwFirstError } from '../../utils/error';
import { Typography } from '../../../components';
import * as CidadeApi from '../../shared/cidade/Api';
import * as Ducks from '../Ducks';
import * as EnderecoApi from '../../shared/endereco/Api';
import * as EstadoApi from '../../shared/estado/Api';
import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import * as UsuarioApi from '../Api';
import constants from '../../shared/constants';
import Form from './Form';
import paths from '../../auth/paths';
import validate from './validate';

const formName = 'UsuarioManterScreen';

const {
  SETUP: { defaultDelay },
  MESSAGES,
} = constants;

class Screen extends Component {
  state = {
    loading: false,
    estados: [],
    cidades: [],
    usuario: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.fetchEstados();
    this.fetchUsuario();
  };

  fetchUsuario = async () => {
    const { getUsuarioMinhaConta, throwSnackbar, change, usuario } = this.props;
    const { usuarioId } = usuario;

    if (usuarioId) {
      this.setState({ loading: true });
      try {
        const { data } = await getUsuarioMinhaConta(usuarioId, defaultDelay.normal);
        this.setState({ usuario: data });
        change('dataNascimento', dateISOToDateMasked(data.dataNascimento));
        this.renderFieldCidadeByEstado(data.estadoId);
      } catch (error) {
        throwSnackbar(constants.MESSAGES.loadFormError);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  fetchEstados = async () => {
    const { throwSnackbar } = this.props;
    try {
      const estados = await EstadoApi.fetchEstados();
      this.setState({ estados: estados.data });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.errorOnLoadField('Estado'));
    }
  };

  fetchCidades = async (estadoId) => {
    const { throwSnackbar } = this.props;
    try {
      const cidades = await CidadeApi.fetchCidadesByEstado(estadoId);
      this.setState({ cidades: cidades.data });
    } catch (error) {
      throwSnackbar(constants.MESSAGES.errorOnLoadField('Cidade'));
    }
  };

  handleSubmit = async (values) => {
    const { throwSnackbar } = this.props;
    try {
      const newValues = {
        ...values,
        dataNascimento: dateMaskedToISO(values.dataNascimento),
      };
      const { data } = await UsuarioApi.saveMinhaConta(newValues, 500);
      this.handleTratarSubmitSuccess(data);
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(MESSAGES.unavailableService);
      }
    }
  };

  handleTratarSubmitSuccess = (id) => {
    if (id) {
      const { throwSnackbar } = this.props;
      throwSnackbar(MESSAGES.updateFormMinhaContaSuccess);
    }
  };

  handleVoltar = () => {
    const { history } = this.props;
    history.push(paths.USUARIO_LISTAR.fullPath);
  };

  handleChangeEstado = (event, value) => {
    this.resetEstadoDependencies();
    if (value && value > 0) {
      this.fetchCidades(value);
    }
  };

  handleBlurCep = async (event, value) => {
    const { change } = this.props;
    const { data } = await EnderecoApi.getEnderecoByCep(value);
    change('cep', data.cep);
    change('logradouro', data.logradouro);
    change('complemento', data.complemento);
    change('bairro', data.bairro);
    change('uf', data.estado);
    change('estadoId', data.estadoId);
    change('cidadeId', data.cidadeId);
    await this.fetchCidades(data.estadoId);
  };

  resetEstadoDependencies = () => {
    this.resetFieldCidade();
  };

  resetFieldCidade = () => {
    const { untouch, change } = this.props;
    untouch('cidadeId');
    change('cidadeId', '');
    this.setState({ cidades: [] });
  };

  renderFieldCidadeByEstado = (estadoId) => {
    if (estadoId && estadoId > 0) {
      this.fetchCidades(estadoId);
    }
  };

  renderLoading = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>
              <Row align="center">
                <Col md="12">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                  </Spinner>
                </Col>
                <Col md="12">
                  <Typography variant="caption">Carregando...</Typography>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  renderScreen = () => {
    const { handleSubmit } = this.props;
    const { estados, cidades, usuario } = this.state;
    return (
      <Form
        {...this.props}
        onVoltar={this.handleVoltar}
        onSubmit={this.handleSubmit}
        handleSubmit={handleSubmit}
        usuario={usuario}
        estados={estados}
        cidades={cidades}
        onChangeEstado={this.handleChangeEstado}
        onBlurCep={this.handleBlurCep}
      />
    );
  };

  render() {
    const { loading, usuario } = this.state;
    const Loading = this.renderLoading;
    const ScreenContent = this.renderScreen;
    return (
      <>
        {loading && <Loading />}
        {usuario && !loading && <ScreenContent />}
      </>
    );
  }
}

Screen.propTypes = {
  match: PropTypes.object.isRequired,
  untouch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getUsuarioMinhaConta: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  itemIsLoading: PropTypes.bool.isRequired,
  usuario: PropTypes.object.isRequired,
};

const normalizeInfos = (values) =>
  values
    ? {
        ...values,
        dataNascimento:
          values.dataNascimento && dateISOToDateMasked(values.dataNascimento),
      }
    : {};

const mapStateToProps = (state) => ({
  initialValues: normalizeInfos(state.usuario.item),
  itemIsLoading: state.usuario.itemIsLoading,
  usuario: state.login.usuario,
});

const mapDispatchToProps = {
  getUsuarioMinhaConta: Ducks.getUsuarioMinhaConta,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

const form = reduxForm({
  validate,
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
