import { validateSchema } from '../../../utils/validate';

const schema = {
  rules: {
    email: {
      required: true,
      email: true,
    },
    password: {
      required: true,
    },
  },
};

export default (values) => validateSchema(values, schema);
