export const FIELD_CNPJ_INVALID = 'CNPJ inválido.';

export const FIELD_CPF_INVALID = 'CPF inválido.';

export const FIELD_DATE_INVALID = 'Data inválida.';

export const FIELD_HOUR_INVALID = 'Hora inválida.';

export const FIELD_EMAIL_INVALID = 'Email inválido.';

export const FIELD_URL_INVALID = 'URL inválida.';

export const FIELD_IP_INVALID = 'IP inválido.';

export const FIELD_MAX_LENGTH = maxLength => `Campo deve conter no máximo ${maxLength} caracteres.`;

export const FIELD_MIN_LENGTH = minLength => `Campo deve conter no mínimo ${minLength} caracteres.`;

export const FIELD_NOT_EQUAL = field => `Valor não corresponde ao campo ${field}.`;

export const FIELD_EQUAL = field => `Valor não pode ser igual ao campo ${field}`;

export const FIELD_NOT_MATCH = 'Campo inválido.';

export const FIELD_NOT_MAX = max => `Campo deve menor ou igual a ${max}.`;

export const FIELD_NOT_MIN = min => `Campo deve maior ou igual a ${min}.`;

export const FIELD_REQUIRED = 'Campo obrigatório.';

export const FIELD_DATE_GREATER_THAN_TODAY = 'A data deve ser maior que hoje.';

export const FIELD_DATE_GREATER_THAN_OR_EQUAL_TODAY = 'A data deve ser maior ou igual a hoje.';

export const FIELD_DATE_LESS_THAN_TODAY = 'A data deve ser menor que hoje.';

export const FIELD_DATE_SHOULD_BE_WORKING_DAY = 'A data deve ser um dia útil.';

export const FIELD_DATE_SHOULD_NOT_BE_SUNDAY = 'A data não pode ser um domingo.';

export const FIELD_DATE_LESS_THAN_OR_EQUAL_TODAY = 'A data deve ser menor ou igual a hoje.';

export const FIELD_DATE_GREATER_THAN_FIELD_X = fieldName =>
  `A data deve ser maior que o campo ${fieldName}.`;

export const FIELD_DATE_GREATER_THAN_OR_EQUAL_FIELD_X = fieldName =>
  `A data deve ser maior ou igual ao campo ${fieldName}.`;

export const FIELD_DATE_LESS_THAN_FIELD_X = fieldName =>
  `A data deve ser menor que o campo ${fieldName}.`;

export const FIELD_DATE_LESS_THAN_OR_EQUAL_FIELD_X = fieldName =>
  `A data deve ser menor ou igual ao campo ${fieldName}.`;

export const FIELD_DATE_OVER_THAN_AGE = age => `A data deve ser maior que ${age} anos.`;

export const FIELD_DATE_UNDER_THAN_AGE = age => `A data deve ser menor que ${age} anos.`;

export const FIELD_DATE_LESS_THAN_NOW = 'A data deve ser menor que a Data Atual.';

export const FIELD_DATE_LESS_THAN_OR_EQUAL_NOW = 'A data deve ser menor ou igual à Data Atual.';

export const FIELD_DATE_GREATER_THAN_NOW = 'A data deve ser maior que a Data Atual.';

export const FIELD_DATE_GREATER_THAN_OR_EQUAL_NOW = 'A data deve ser maior ou igual à Data Atual.';

export const FIELD_PHONE_INVALID = 'Telefone inválido.';
