import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { COLOR_PALETTE } from './config/styles';

const textTitleColor = COLOR_PALETTE.grey[700];

const theme = createMuiTheme({
  palette: {
    primary: {
      all: COLOR_PALETTE.primary,
      main: COLOR_PALETTE.primary[400],
      contrastText: '#fff',
    },
    secondary: {
      all: COLOR_PALETTE.secondary[300],
      main: COLOR_PALETTE.secondary[300],
      contrastText: '#fff',
    },
    grey: COLOR_PALETTE.grey,
    red: COLOR_PALETTE.red,
    common: {
      faintWhite: 'rgba(255, 255, 255, 0.12)',
      minFaintWhite: 'rgba(255, 255, 255, 0.8)',
    },
    text: {
      primary: fade(textTitleColor, 0.87),
      secondary: fade(textTitleColor, 0.54),
      disabled: fade(textTitleColor, 0.38),
      hint: fade(textTitleColor, 0.38),
    },
    divider: fade(textTitleColor, 0.12),
    action: {
      active: fade(textTitleColor, 0.54),
      hover: fade(textTitleColor, 0.14),
      selected: fade(textTitleColor, 0.08),
      disabled: fade(textTitleColor, 0.26),
      disabledBackground: fade(textTitleColor, 0.12),
    },
  },
  typography: {
    useNextVariants: true,
  },
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.01),0px 2px 1px -1px rgba(0, 0, 0, 0.01)',
    '0px 1px 5px 0px rgba(0, 0, 0, 0.1),0px 2px 2px 0px rgba(0, 0, 0, 0.01),0px 3px 1px -2px rgba(0, 0, 0, 0.01)',
    '0px 1px 8px 0px rgba(0, 0, 0, 0.1),0px 3px 4px 0px rgba(0, 0, 0, 0.01),0px 3px 3px -2px rgba(0, 0, 0, 0.01)',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.1),0px 4px 5px 0px rgba(0, 0, 0, 0.01),0px 1px 10px 0px rgba(0, 0, 0, 0.01)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.1),0px 5px 8px 0px rgba(0, 0, 0, 0.01),0px 1px 14px 0px rgba(0, 0, 0, 0.01)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.1),0px 6px 10px 0px rgba(0, 0, 0, 0.01),0px 1px 18px 0px rgba(0, 0, 0, 0.01)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.1),0px 7px 10px 1px rgba(0, 0, 0, 0.01),0px 2px 16px 1px rgba(0, 0, 0, 0.01)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.1),0px 8px 10px 1px rgba(0, 0, 0, 0.01),0px 3px 14px 2px rgba(0, 0, 0, 0.01)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.1),0px 9px 12px 1px rgba(0, 0, 0, 0.01),0px 3px 16px 2px rgba(0, 0, 0, 0.01)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.1),0px 10px 14px 1px rgba(0, 0, 0, 0.01),0px 4px 18px 3px rgba(0, 0, 0, 0.01)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.1),0px 11px 15px 1px rgba(0, 0, 0, 0.01),0px 4px 20px 3px rgba(0, 0, 0, 0.01)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.1),0px 12px 17px 2px rgba(0, 0, 0, 0.01),0px 5px 22px 4px rgba(0, 0, 0, 0.01)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.1),0px 13px 19px 2px rgba(0, 0, 0, 0.01),0px 5px 24px 4px rgba(0, 0, 0, 0.01)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.1),0px 14px 21px 2px rgba(0, 0, 0, 0.01),0px 5px 26px 4px rgba(0, 0, 0, 0.01)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.1),0px 15px 22px 2px rgba(0, 0, 0, 0.01),0px 6px 28px 5px rgba(0, 0, 0, 0.01)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.1),0px 16px 24px 2px rgba(0, 0, 0, 0.01),0px 6px 30px 5px rgba(0, 0, 0, 0.01)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.1),0px 17px 26px 2px rgba(0, 0, 0, 0.01),0px 6px 32px 5px rgba(0, 0, 0, 0.01)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.1),0px 18px 28px 2px rgba(0, 0, 0, 0.01),0px 7px 34px 6px rgba(0, 0, 0, 0.01)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.1),0px 19px 29px 2px rgba(0, 0, 0, 0.01),0px 7px 36px 6px rgba(0, 0, 0, 0.01)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.1),0px 20px 31px 3px rgba(0, 0, 0, 0.01),0px 8px 38px 7px rgba(0, 0, 0, 0.01)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.1),0px 21px 33px 3px rgba(0, 0, 0, 0.01),0px 8px 40px 7px rgba(0, 0, 0, 0.01)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.1),0px 22px 35px 3px rgba(0, 0, 0, 0.01),0px 8px 42px 7px rgba(0, 0, 0, 0.01)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.1),0px 23px 36px 3px rgba(0, 0, 0, 0.01),0px 9px 44px 8px rgba(0, 0, 0, 0.01)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.1),0px 24px 38px 3px rgba(0, 0, 0, 0.01),0px 9px 46px 8px rgba(0, 0, 0, 0.01)',
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  spacing: {
    gutter: 16,
  },
  drawer: {
    width: 200,
  },
});

export default theme;
