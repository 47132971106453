import { CardBody } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { TextField, Button, Typography } from '../../../../components';
import * as AuthDucks from '../../Ducks';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';

import paths from '../../paths';
import validate from './validate';

const formName = 'login';

const styles = () => ({
  title: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  text: {
    color: 'rgba(69, 90, 100, 0.87)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
  },
  textLink: {
    color: '#1877f2',
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.01071em',
    '&:hover': {
      color: '#1877f2',
      textDecoration: 'underline',
    },
  },
});

class Login extends PureComponent {
  renderMensagemSuccess = () => {
    const { success } = this.props;
    return (
      success && (
        <div className="mt-2 mb-2">
          <span className="text-success">
            Senha alterada com sucesso. Volte para o login e acesse novamente.
          </span>
        </div>
      )
    );
  };

  render() {
    const { handleSubmit, onSubmit, error, submitting, classes, success } =
      this.props;
    return (
      <CardBody>
        <Typography align="center" gutterBottom className={classes.title}>
          Alterar sua senha
        </Typography>

        <div className={`${classes.text} mt-2`}>
          Crie uma nova senha e tenha mais segurança e cômodidade.
        </div>

        {this.renderMensagemSuccess()}

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {!success && (
            <>
              <FormControl error fullWidth margin="normal">
                <Field
                  name="senhaNova"
                  type="password"
                  label="Nova senha"
                  placeholder="Digite a nova senha"
                  component={TextField}
                  inputProps={{ autoComplete: 'new-password', maxLength: 80 }}
                  required
                />
              </FormControl>
              <FormControl error fullWidth margin="normal">
                <Field
                  name="confirmarNovaSenha"
                  type="password"
                  component={TextField}
                  label="Confirmar Nova Senha"
                  placeholder="Digite a nova senha novamente"
                  inputProps={{ autoComplete: 'new-password', maxLength: 80 }}
                  required
                />
              </FormControl>
              <div className="text-center">
                <Button
                  className="btn-fill my-4"
                  color="primary"
                  type="submit"
                  loading={submitting}
                  disabled={submitting}
                >
                  Alterar senha
                </Button>
              </div>
            </>
          )}

          {error && <strong>{error}</strong>}
        </form>

        <p className={classes.text}>
          Voltar para o{' '}
          <a href={paths.LOGIN.fullPath} className={classes.textLink}>
            Login
          </a>
        </p>
      </CardBody>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

Login.defaultProps = {
  error: null,
};

const form = reduxForm({
  form: formName,
  validate,
});

const mapDispatchToProps = {
  login: AuthDucks.login,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(form(Login));
