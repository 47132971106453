import { sumYears } from '../helpers/date';
import isDateEqualTo from './isDateEqualTo';
import isLessThan from './isDateLessThan';

const isOverAge = (value, minAge) => {
  const now = new Date();
  const valueDate = sumYears(value, minAge);

  return isLessThan(valueDate, now) || isDateEqualTo(valueDate, now);
};

export default isOverAge;
