import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import * as Ducks from '../Ducks';
import { hasSelfPermission, roles } from '../../auth/permission';

import * as SnackbarDucks from '../../shared/snackbar/SnackbarDucks';
import Empty from '../components/empty';
import constants from '../../shared/constants';
import { throwFirstError } from '../../utils/error';

import TotalizadorReport from '../components/totalizador-report';
import SituacaoReport from '../components/situacao-report';
import SituacaoEnergiaReport from '../components/situacao-energia-report';

class Screen extends PureComponent {
  constructor() {
    super();
    this.state = {
      dashboards: [
        {
          render: this.renderTotalizador,
          role: roles.DASH_TOTALIZADOR,
        },
        {
          render: this.renderSituacao,
          role: roles.DASH_SITUACAO,
        },
        {
          render: this.renderSituacaoEnergia,
          role: roles.DASH_SITUACAO_ENERGIA,
        },
      ],
    };
  }

  getDashboardsByPermissao = () => {
    const { dashboards } = this.state;
    let index = 0;

    return dashboards.map((item) => {
      const newItem = { ...item };
      if (!newItem.role || hasSelfPermission(newItem.role)) {
        newItem.index = index;
        newItem.canView = true;
        index += 1;
      } else {
        newItem.canView = false;
      }
      return newItem;
    });
  };

  handleSubmit = async (values) => {
    this.handleSubmitTotalizador(values);
    this.handleSubmitSituacao(values);
    this.handleSubmitSituacaoEnergia(values);
  };

  handleSubmitTotalizador = async (values) => {
    const { fetchTotalizadores, throwSnackbar } = this.props;

    try {
      await fetchTotalizadores(
        {
          ...values,
        },
        constants.SETUP.defaultDelay.slow,
      );
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.errorOnLoadRecords('totalizadores'));
      }
    }
  };

  handleSubmitSituacao = async (values) => {
    const { fetchSituacoes, throwSnackbar } = this.props;

    try {
      await fetchSituacoes(
        {
          ...values,
        },
        constants.SETUP.defaultDelay.slow,
      );
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.errorOnLoadRecords('situaçōes'));
      }
    }
  };

  handleSubmitSituacaoEnergia = async (values) => {
    const { fetchSituacoesEnergia, throwSnackbar } = this.props;

    try {
      await fetchSituacoesEnergia(
        {
          ...values,
        },
        constants.SETUP.defaultDelay.slow,
      );
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.errorOnLoadRecords('situaçōes energia'));
      }
    }
  };

  renderTotalizador = () => <TotalizadorReport />;

  renderSituacao = () => <SituacaoReport />;

  renderSituacaoEnergia = () => <SituacaoEnergiaReport />;

  renderDashboardsByPermissao = () => {
    const dashboards = this.getDashboardsByPermissao();

    const dashs = dashboards.reduce((result, item) => {
      if (item.canView) {
        result.push(<Fragment key={item.index}>{item.render(item.index)}</Fragment>);
      }
      return result;
    }, []);

    return dashs.length ? dashs : <Empty />;
  };

  render() {
    const Dashboards = this.renderDashboardsByPermissao;
    return (
      <>
        <Container fluid>
          <Row>
            <Dashboards />
          </Row>
        </Container>
      </>
    );
  }
}

Screen.propTypes = {
  fetchTotalizadores: PropTypes.func.isRequired,
  fetchSituacoes: PropTypes.func.isRequired,
  fetchSituacoesEnergia: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchTotalizadores: Ducks.fetchTotalizadores,
  fetchSituacoes: Ducks.fetchSituacoes,
  fetchSituacoesEnergia: Ducks.fetchSituacoesEnergia,
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

export default connect(
  null,
  mapDispatchToProps,
)(Screen);
