import {
  isCpf,
  isDate,
  isEmail,
  isHour,
  isMatch,
  isOverAge,
  isUnderAge,
} from './validators';

import schema from './validate';

const methods = {
  isCpf,
  isDate,
  isEmail,
  isHour,
  isMatch,
  isOverAge,
  isUnderAge,
};

const validate = {
  methods,
  schema,
};

export default validate;
