import * as api from './Api';

const CHIP_RESET_OBJECT_SUCCESS = 'CHIP/RESET_OBJECT_SUCCESS';

const CHIP_FETCH_SUCCESS = 'CHIP/FETCH_SUCCESS';
const CHIP_FETCH_LOADING = 'CHIP/FETCH_LOADING';
const CHIP_FETCH_FAILURE = 'CHIP/FETCH_FAILURE';

export const CHIP_GET_SUCCESS = 'CHIP/GET_SUCCESS';
export const CHIP_GET_FAILURE = 'CHIP/GET_FAILURE';
export const CHIP_GET_LOADING = 'CHIP/GET_LOADING';

const INITIAL_STATE = {
  items: null,
  itemsError: false,
  itemsIsLoading: false,
  item: null,
  itemError: false,
  itemIsLoading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHIP_RESET_OBJECT_SUCCESS:
      return {
        ...state,
        item: null,
      };
    case CHIP_GET_SUCCESS:
      return {
        ...state,
        item: {
          ...payload,
        },
      };
    case CHIP_GET_FAILURE:
      return {
        ...state,
        itemError: payload,
      };
    case CHIP_GET_LOADING:
      return {
        ...state,
        itemIsLoading: payload,
      };
    case CHIP_FETCH_SUCCESS:
      return {
        ...state,
        itemsError: false,
        items: payload,
      };
    case CHIP_FETCH_LOADING:
      return {
        ...state,
        itemsIsLoading: payload,
      };
    case CHIP_FETCH_FAILURE:
      return {
        ...state,
        itemsError: payload,
      };
    default:
      return state;
  }
};

export const fetchChipsByFiltros = (filtros) => async (dispatch) => {
  try {
    dispatch({ type: CHIP_FETCH_LOADING, payload: true });
    const response = await api.fetchChipsByFiltros(filtros);
    dispatch({ type: CHIP_FETCH_SUCCESS, payload: response.data });
    return response;
  } catch (error) {
    dispatch({ type: CHIP_FETCH_FAILURE, payload: true });
    throw error;
  } finally {
    dispatch({ type: CHIP_FETCH_LOADING, payload: false });
  }
};

export const getChip =
  (id, delay = 0) =>
  async (dispatch) => {
    try {
      dispatch({ type: CHIP_GET_LOADING, payload: true });
      const res = await api.getChip(id, delay);
      dispatch({ type: CHIP_GET_SUCCESS, payload: res.data });
      return res;
    } catch (error) {
      dispatch({ type: CHIP_GET_FAILURE, payload: true });
      throw error;
    } finally {
      dispatch({ type: CHIP_GET_LOADING, payload: false });
    }
  };

export const resetObject = () => async (dispatch) => {
  dispatch({ type: CHIP_RESET_OBJECT_SUCCESS });
};
