import { axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';

const urlRest = '/api/notificacoes';

export const fetchNotificacoesByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlRest, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};
