import { indigo, blue, red, blueGrey } from '@material-ui/core/colors';

const COLOR_NAMES = {
  primary: indigo,
  secondary: indigo,
  blue,
  red,
  grey: blueGrey,
};

const COLOR_PALETTE = { ...COLOR_NAMES };

export { COLOR_PALETTE };
