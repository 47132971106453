import { Card, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ChartistGraph from 'react-chartist';

class Form extends PureComponent {
  render() {
    const { data } = this.props;
    const { totalAguardandoVinculo, totalAtivo, totalInativo, totalAlerta } = data;

    return (
      <Col md="6">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Rastreadores</Card.Title>
            <p className="card-category">Situação</p>
          </Card.Header>
          <Card.Body>
            <div className="ct-chart" id="chartActivity">
              <ChartistGraph
                data={{
                  labels: ['Aguardando Vínculo', 'Ativo', 'Inativo', 'Alerta'],
                  series: [
                    [
                      totalAguardandoVinculo,
                      totalAtivo,
                      totalInativo,
                      totalAlerta,
                    ],
                  ],
                }}
                type="Bar"
                options={{
                  seriesBarDistance: 10,
                  axisX: {
                    showGrid: false,
                  },
                  height: '245px',
                }}
                responsiveOptions={[
                  [
                    'screen and (max-width: 640px)',
                    {
                      seriesBarDistance: 5,
                      axisX: {
                        // eslint-disable-next-line
                        labelInterpolationFnc: function (value) {
                          return value[0];
                        },
                      },
                    },
                  ],
                ]}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

Form.propTypes = {
  data: PropTypes.object,
};

Form.defaultProps = {
  data: null,
};

export default Form;
