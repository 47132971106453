import { Card, Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { montarParamsFiltroPaginacao } from '../../utils/pagination';
import { throwFirstError } from '../../utils/error';
import constants from '../../shared/constants';
import List from './List';
import * as Apis from '../Api';

const PAGINATION = {
  last: false,
  totalElements: 0,
  totalPages: 0,
  first: true,
  numberOfElements: 10,
  size: 10,
  number: 0,
};

const ORDER = {
  orderBy: 'dataCadastro',
  orderDirection: constants.DEFAULT.DIRECAO.desc,
};

class Screen extends Component {
  state = {
    loading: false,
    dados: null,
    pagination: PAGINATION,
    order: ORDER,
    filtros: {},
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.fetchNotificacoesByFiltros();
  };

  fetchNotificacoesByFiltros = async (filtros) => {
    this.setState({ loading: true });
    let { pagination, dados } = this.state;
    const { throwSnackbar } = this.props;
    const { order } = this.state;

    try {
      const params = montarParamsFiltroPaginacao({
        filtros,
        pagination,
        order,
      });
      const newParams = { ...params };
      const response = await Apis.fetchNotificacoesByFiltros(
        newParams,
        constants.SETUP.defaultDelay.normal
      );
      const { content, ...paginationResponse } = response.data;
      pagination = paginationResponse;
      dados = content;
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.unavailableService);
      }
    }

    this.setState({
      loading: false,
      dados,
      pagination,
      filtros,
    });
  };

  handlePaginationChangePage = async (event, page) => {
    const { pagination, filtros } = this.state;
    await this.setState({ pagination: { ...pagination, number: page } });
    this.fetchNotificacoesByFiltros(filtros);
  };

  handlePaginationChangeRowsPerPage = async (event) => {
    const { pagination, filtros } = this.state;
    await this.setState({
      pagination: { ...pagination, size: event.target.value },
    });
    this.fetchNotificacoesByFiltros(filtros);
  };

  render() {
    const { pagination, dados, order, loading } = this.state;
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Pesquisa</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <List
                    {...this.props}
                    loading={loading}
                    data={dados}
                    pagination={pagination}
                    order={order}
                    onChangePagination={this.handlePaginationChangePage}
                    onChangeNumberItems={this.handlePaginationChangeRowsPerPage}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Screen.propTypes = {
  reset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
};

export default Screen;
