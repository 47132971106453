import { Col } from 'react-bootstrap';
import React, { PureComponent } from 'react';

import { Typography } from '../../../../components';

class Empty extends PureComponent {
  render() {
    return (
      <Col xs="12">
        <Typography>Nenhum relatório a ser exibido.</Typography>
      </Col>
    );
  }
}

export default Empty;
