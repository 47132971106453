import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { throwFirstError } from '../../../utils/error';
import { Typography } from '../../../../components';
import * as Ducks from '../../Ducks';
import * as RastreadorApi from '../../Api';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';
import constants from '../../../shared/constants';
import Form from './Form';
import paths from '../../../auth/paths';
import validate from './validate';

const formName = 'RastreadorManterScreen';

const {
  SETUP: { defaultDelay },
  MESSAGES,
} = constants;

class Screen extends Component {
  state = {
    loading: false,
    rastreador: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const { resetObject } = this.props;
    resetObject();
    this.fetchUsuarioRastreador();
  };

  fetchUsuarioRastreador = async () => {
    const { match, getUsuarioRastreador, throwSnackbar } = this.props;
    const { id } = match.params;

    if (id) {
      this.setState({ loading: true });
      try {
        const { data: rastreador } = await getUsuarioRastreador(
          id,
          defaultDelay.normal
        );
        this.setState({ rastreador });
      } catch (error) {
        throwSnackbar(constants.MESSAGES.loadFormError);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  handleSubmit = async (values) => {
    const { throwSnackbar } = this.props;
    try {
      const newValues = {
        ...values,
      };
      const { data } = await RastreadorApi.salvaVinculoRastreador(
        newValues,
        500
      );
      this.handleTratarSubmitSuccess(data);
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(MESSAGES.unavailableService);
      }
    }
  };

  handleTratarSubmitSuccess = (id) => {
    if (id) {
      const { throwSnackbar, history, resetObject } = this.props;
      resetObject();
      history.push(paths.MEUS_RASTREADORES_LISTAR.fullPath);
      throwSnackbar(MESSAGES.saveFormRastreadorSuccess);
    }
  };

  handleVoltar = () => {
    const { resetObject, history } = this.props;
    resetObject();
    history.push(paths.MEUS_RASTREADORES_LISTAR.fullPath);
  };

  renderLoading = () => (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>
              <Row align="center">
                <Col md="12">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                  </Spinner>
                </Col>
                <Col md="12">
                  <Typography variant="caption">Carregando...</Typography>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  renderScreen = () => {
    const { handleSubmit } = this.props;
    const { rastreador } = this.state;
    return (
      <Form
        {...this.props}
        rastreador={rastreador}
        onVoltar={this.handleVoltar}
        onSubmit={this.handleSubmit}
        handleSubmit={handleSubmit}
      />
    );
  };

  render() {
    const { loading, rastreador } = this.state;
    const Loading = this.renderLoading;
    const ScreenContent = this.renderScreen;
    return (
      <>
        {loading && <Loading />}
        {(rastreador || !loading) && <ScreenContent />}
      </>
    );
  }
}

Screen.propTypes = {
  match: PropTypes.object.isRequired,
  untouch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getUsuarioRastreador: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  resetObject: PropTypes.func.isRequired,
  itemIsLoading: PropTypes.bool.isRequired,
};

const normalizeInfos = (values) =>
  values
    ? {
        ...values,
      }
    : {};

const mapStateToProps = (state) => ({
  initialValues: normalizeInfos(state.rastreador.item),
  itemIsLoading: state.rastreador.itemIsLoading,
});

const mapDispatchToProps = {
  getUsuarioRastreador: Ducks.getUsuarioRastreador,
  throwSnackbar: SnackbarDucks.throwSnackbar,
  resetObject: Ducks.resetObject,
};

const form = reduxForm({
  validate,
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
