import { Spinner } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '../Typography';

const styles = (theme) => ({
  noReults: {
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
  },
  tableCell: {
    textAlign: 'center',
  },
  illustration: {
    height: 160,
    margin: '0 auto',
    width: 148,
  },
});

class TableEmpty extends PureComponent {
  renderFilterInfo = () => {
    const { enterFilterText } = this.props;

    return (
      <Typography variant="body2" align="center">
        {enterFilterText}
      </Typography>
    );
  };

  renderNoResults = () => {
    const { classes, noResultsText } = this.props;

    return (
      <div className={classes.noReults}>
        <Typography variant="body2" align="center" gutterTop="2x">
          {noResultsText}
        </Typography>
      </div>
    );
  };

  renderLoading = () => (
    <Spinner animation="border" role="status">
      <span className="sr-only">Carregando...</span>
    </Spinner>
  );

  renderContent = () => {
    const { data, loading } = this.props;

    if (loading) {
      return this.renderLoading();
    }

    if (data) {
      return this.renderNoResults();
    }

    return this.renderFilterInfo();
  };

  render() {
    const { classes } = this.props;

    return (
      <TableRow>
        <TableCell colSpan={100} className={classes.tableCell}>
          {this.renderContent()}
        </TableCell>
      </TableRow>
    );
  }
}

TableEmpty.defaultProps = {
  data: null,
  enterFilterText: 'Informe os filtros.',
  loading: false,
  noResultsText: 'Nenhum resultado foi encontrado.',
};

TableEmpty.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.node,
  enterFilterText: PropTypes.string,
  loading: PropTypes.bool,
  noResultsText: PropTypes.string,
};

export default withStyles(styles)(TableEmpty);
