import { Row, Col, Spinner } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { DisplayInfo, Typography } from '../../../components';

const Detalhe = ({ data, loading }) => {
  const renderLoading = () => (
    <Row align="center">
      <Col md="12">
        <Spinner animation="border" role="status">
          <span className="sr-only">Carregando...</span>
        </Spinner>
      </Col>
      <Col md="12">
        <Typography variant="caption">Carregando...</Typography>
      </Col>
    </Row>
  );

  const renderDetalhe = () => {
    const {
      imei,
      descricao,
      bateria,
      situacaoDescricao,
      situacaoEnergiaDescricao,
      intervaloLocalizacao,
      economiaBateria,
      ultraEconomiaBateria,
    } = data;
    return (
      <Row>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo label="IMEI" value={imei} />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo label="Descrição" value={descricao || '-'} />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo
            label="Bateria"
            value={bateria != null && bateria >= 0 ? `${bateria} %` : '-'}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo
            label="Intervalo de Localização"
            value={
              intervaloLocalizacao <= 1
                ? `${intervaloLocalizacao} minuto`
                : `${intervaloLocalizacao} minutos`
            }
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo
            label="Economia de bateria"
            value={economiaBateria === 'V' ? 'Ativo' : 'Inativo'}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo
            label="Ultra Economia de bateria"
            value={ultraEconomiaBateria === 'V' ? 'Ativo' : 'Inativo'}
          />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo label="Situação" value={situacaoDescricao} />
        </Col>
        <Col lg="3" md="4" sm="6" xs="12">
          <DisplayInfo
            label="Situação Energia"
            value={situacaoEnergiaDescricao}
          />
        </Col>
      </Row>
    );
  };

  return <>{data && !loading ? renderDetalhe() : renderLoading()}</>;
};

Detalhe.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

Detalhe.defaultProps = {
  data: {},
};

export default Detalhe;
