import { sumYears } from '../helpers/date';
import isDateGreaterThan from './isDateGreaterThan';

const isAgeLessThan = (value, minAge) => {
  const now = new Date();
  const valueDate = sumYears(value, minAge);

  return isDateGreaterThan(valueDate, now);
};

export default isAgeLessThan;
