export const erroInterno = 'Ocorreu um erro interno. Tente novamente.';
export const emailSenhaInvalidos = 'Email ou senha inválidos.';
export const sessaoExpirou = 'A sessão expirou. Faça o login novamente.';
export const campoObrigatorio = 'Campo obrigatório.';
export const mostrarLinhas = 'Mostrar Linhas:';
export const internalError = 'Ocorreu um erro interno. Tente novamente.';
export const saveFormError = 'Erro ao salvar o registro.';
export const captchaFormError = 'Resolva o CAPTCHA.';
export const unavailableService = 'Serviço indisponível no momento.';
export const errorOnProcess = name => `Erro ao ${name}.`;
export const descriptionConfirmDialog = label => `Tem certeza que deseja ${label}`;
export const errorOnExport = name => `Erro ao exportar o arquivo ${name}.`;
export const errorOnLoadField = fieldName => `Erro ao carregar o campo ${fieldName}.`;
export const errorOnLoadRecord = name => `Erro ao carregar ${name}.`;
export const errorOnLoadRecords = name => `Erro ao carregar ${name}.`;
export const errorOnSaveRecord = name => `Erro ao salvar o ${name}.`;
export const loadFormError = 'Erro ao carregar o registro.';
export const exportFileSuccess = 'Arquivo exportado com sucesso.';
export const saveFormUsuarioSuccess = 'Usuário salvo com sucesso.';
export const updateFormMinhaContaSuccess = 'Dados atualizados com sucesso.';
export const saveFormRastreadorSuccess = 'Rastreador salvo com sucesso.';
export const saveFormChipSuccess = 'Chip salvo com sucesso.';
export const usuarioInativo = 'Usuário inativo, solicite a ativação ao seu responsável.';
export const usuarioAtivadoSuccess = 'Usuário ativado com sucesso.';
export const usuarioDesativadoSuccess = 'Usuário desativado com sucesso.';
export const usuarioReenvioSenhaSuccess = 'Redefinição de senha enviada para o e-mail.';
export const informePontosAvaliacaoError = 'Informe o(s) Ponto(s) de Avaliação.';
export const permissaoRemovidaSuccess = 'Permissão removida com sucesso.';
export const saveFormPermissaoSuccess = 'Permissão atribuida com sucesso.';
export const periodoPermitido = days => `O período não deve ser superior a ${days} dias.`;
export const contaNaoEncontrada = 'Não foi possível encontrar sua Conta.';
