import { validateSchema } from '../../utils/validate';

const schema = {
  rules: {
    email: {
      required: false,
      email: true,
    },
    cpf: {
      required: false,
      cpf: true,
    },
  },
};

export default (values) => validateSchema(values, schema);
