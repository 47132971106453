import _uniq from 'lodash/uniq';
import { Creatable } from 'react-select';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Form } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import createFilterOptions from 'react-select-fast-filter-options';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import VirtualizedSelect from 'react-virtualized-select';

import 'react-select/dist/react-select.css'; // eslint-disable-line

import Option from './Option';

const ITEM_HEIGHT = 48;

const styles = (theme) => ({
  group: {
    marginBottom: 0,
  },
  '@global': {
    '.Select.is-focused:not(.is-open) > .Select-control, .Select.is-open .Select-control':
      {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 0.2rem ${fade(theme.palette.primary.main, 0.25)}`,
      },
    '.Select-control': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[100],
      borderRadius: '2px !important',
      color: theme.palette.text.primary,
      display: 'flex',
      height: 'auto',
      minHeight: '36px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      '& .Select-input:focus': {
        background: 'inherit',
      },
    },
    '.Select-multi-value-wrapper': {
      alignItems: 'center',
      display: 'inline-flex !important',
      flexWrap: 'wrap',
      width: 'calc(100% - 24px)',
    },
    '.Select.has-value.is-clearable.Select--multi .Select-multi-value-wrapper':
      {
        paddingRight: theme.spacing.unit * 3,
        width: 'calc(100% - 48px)',
      },
    '.Select.has-value.Select--multi .Select-multi-value-wrapper': {
      paddingBottom: 3,
    },
    '.Select-arrow-zone, .Select-clear-zone, .Select--multi .Select-clear-zone':
      {
        alignItems: 'center',
        color: theme.palette.text.secondary,
        display: 'inline-flex',
        justifyContent: 'center',
        width: 24,
      },
    '.Select-clear-zone': {
      '&:hover': {
        color: 'currentColor',
      },
    },
    '.Select-values': {
      display: 'inline-flex',
      marginLeft: 3,
      marginTop: 3,
      maxWidth: '100%',
    },
    '.Select-input': {
      display: 'inline-flex !important',
      height: 34,
      padding: '0 12px',
    },
    '.Select--multi.has-value .Select-input': {
      height: 28,
      marginLeft: 3,
      marginTop: 3,
    },
    '.Select-input > input, .Select-control > *:last-child': {
      padding: 0,
    },
    '.Select-placeholder, .Select--single > .Select-control .Select-value': {
      color: theme.palette.text.primary,
      paddingLeft: 12,
      width: 'calc(100% - 24px)',
    },
    '.Select-placeholder': {
      opacity: 0.42,
    },
    '.Select-menu-outer': {
      backgroundColor: theme.palette.background.paper,
      border: 0,
      boxShadow: theme.shadows[2],
      left: 0,
      maxHeight: `${ITEM_HEIGHT * 4.5}`,
      position: 'absolute',
      top: `calc(100% + ${theme.spacing.unit}px)`,
      width: '100%',
      zIndex: 2,
    },
    '.MuiMenuItem-root': {
      minHeight: '48px !important',
    },
    '.Select-noresults': {
      padding: theme.spacing.unit * 2,
    },

    '.Select.is-disabled > .Select-control': {
      background: theme.palette.grey[50],
    },
    '.Select.is-disabled.Select--multi.has-value .Select-input': {
      display: 'none',
    },
    '.Select.is-disabled .Select-placeholder, .Select.is-disabled .Select-value':
      {
        color: theme.palette.text.disabled,
        opacity: 1,
      },
    '.Select.is-disabled .Select-arrow-zone': {
      opacity: 1,
    },
    '.Select.is-disabled .Select-multi-value-wrapper': {
      display: 'inline-block !important',
    },
  },
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  error: {
    '& .Select-control': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '&.is-focused .Select-control': {
      boxShadow: `0 0 0 0.2rem ${fade(
        theme.palette.error.main,
        0.25
      )} !important`,
    },
  },
  createLabel: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
  selectAll: {
    position: 'absolute',
    top: '50%',
    right: (theme.spacing.unit * 5) / 2,
  },
  selectAllWithClear: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing.unit * 6,
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    transform: 'none',
    fontWeight: 'normal',
  },
});

class Select extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterOptions: createFilterOptions({
        options: props.options,
        labelKey: props.labelKey,
        valueKey: props.valueKey,
      }),
      value: [],
    };
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleFilter(nextProps);
  }

  setFilter = (props) =>
    this.setState({
      filterOptions: createFilterOptions({
        options: props.options,
        labelKey: props.labelKey,
        valueKey: props.valueKey,
      }),
    });

  handleChange = (value) => {
    const { onChange, simpleValue, multiple } = this.props;

    if (multiple && simpleValue) {
      if (value) {
        let newValue = value;
        if (typeof newValue === 'string') {
          newValue = value.split(',');

          const uniqValue = _uniq(newValue);

          this.setState({ value: uniqValue });
          return onChange(uniqValue);
        }

        if (Array.isArray(value) && value.length) {
          const uniqValue = _uniq(value);

          this.setState({ value: uniqValue });
          return onChange(uniqValue);
        }
      } else {
        this.setState({ value: [] });
        return onChange([]);
      }
    } else if (value) {
      this.setState({ value });

      return onChange(value);
    }
    this.setState({ value });
    return onChange('');
  };

  handleFilter = (props) => {
    const { options } = props;
    const { options: optionsProps } = this.props;

    if (options !== optionsProps) {
      this.setFilter(props);
    }
    return null;
  };

  handleInputChange = (value) => {
    const { maxLength, onlyNumbers } = this.props;

    if (value) {
      let newValue = value;
      if (onlyNumbers) {
        newValue = newValue.replace(/[^0-9]+/g, '');
      }
      if (maxLength && newValue.length > maxLength) {
        newValue = newValue.substring(0, maxLength);
      }
      return newValue.toUpperCase();
    }
    return value;
  };

  renderLabel = () => {
    const { name, label, required, classes } = this.props;
    return (
      label && (
        <InputLabel
          error={false}
          shrink
          htmlFor={name}
          classes={{ root: classes.label }}
        >
          {label}
          {required && ' *'}
        </InputLabel>
      )
    );
  };

  filterOptions = (options, props) => {
    const { valueKey, simpleValue } = props;
    const { value: values } = this.state;

    const filterOptions = options;

    let newFilterOptions = [];

    if (Array.isArray(values) && values.length) {
      if (simpleValue) {
        newFilterOptions = filterOptions.filter((option) => {
          const findOption = Number(
            values.find((value) => Number(value) === Number(option[valueKey]))
          );

          if (findOption !== Number(option[valueKey])) {
            return option;
          }
          return null;
        });
      } else {
        newFilterOptions = filterOptions.filter((option) => {
          if (
            values.find(
              (value) => Number(value[valueKey]) === Number(option[valueKey])
            )
          ) {
            const optionValue = values.find(
              (value) => Number(value[valueKey]) === Number(option[valueKey])
            )[valueKey];

            if (Number(optionValue) !== Number(option[valueKey])) {
              return option;
            }
            return null;
          }
          return option;
        });
      }
    } else {
      newFilterOptions = filterOptions;
    }

    if (Array.isArray(newFilterOptions) && newFilterOptions.length) {
      const newOptions = newFilterOptions.map((option) => {
        if (simpleValue) {
          return option[valueKey];
        }
        return option;
      });

      const newValues = [...(values || []), ...newOptions];

      return newValues;
    }
    return values;
  };

  selectAll = (data) => {
    const { options } = data;

    const newValues = this.filterOptions(options, data);

    if (Array.isArray(newValues) && newValues.length) {
      this.handleChange(newValues);
    }
  };

  selectWithFilter = (data) => {
    const { options, filter } = data;

    const newValues = this.filterOptions(filter(options), this.props);

    if (Array.isArray(newValues) && newValues.length) {
      this.handleChange(newValues);
    }
  };

  renderArrow = (arrowProps) =>
    arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  renderPlaceholder = ({
    loading,
    loadingPlaceholder,
    placeholder,
    parentField,
  }) => {
    if (loading) {
      return loadingPlaceholder;
    }

    if (parentField && parentField.label && !parentField.value) {
      return `Selecione o campo ${parentField.label}`;
    }

    return placeholder;
  };

  render() {
    const {
      avatar,
      classes,
      clearable,
      clearAllText,
      clearValueText,
      creatable,
      error,
      inputProps,
      label,
      labelKey,
      multiple,
      name,
      noResultsText,
      options,
      placeholder,
      required,
      valueKey,
      filters,
      onChange,
      loading,
      loadingPlaceholder,
      disabled,
      parentField,
      ...rest
    } = this.props;

    const Label = this.renderLabel;

    const { filterOptions } = this.state;
    const selectComponent = creatable ? { selectComponent: Creatable } : null;

    const newInputProps = inputProps || {};

    const Placeholder = this.renderPlaceholder;
    const isDisabled =
      loading ||
      (parentField && parentField.label && !parentField.value) ||
      disabled;

    let newOptions = options;
    if (!multiple && !clearable) {
      newOptions = Array.isArray(options)
        ? options.map((option) => ({ clearableValue: false, ...option }))
        : options;
    }

    return (
      <Form.Group
        controlId="exampleForm.ControlSelect1"
        className={classes.group}
      >
        <Form.Label>
          <Label />
        </Form.Label>
        <VirtualizedSelect
          arrowRenderer={this.renderArrow}
          className={error ? classes.error : ''}
          filterOptions={filterOptions}
          id={name}
          joinValues={false}
          labelKey={labelKey}
          disabled={isDisabled}
          noResultsText={
            <Typography variant="body2">{noResultsText}</Typography>
          }
          onInputChange={this.handleInputChange}
          optionHeight={ITEM_HEIGHT}
          optionRenderer={Option}
          options={newOptions}
          placeholder={
            <Placeholder
              loading={loading}
              loadingPlaceholder={loadingPlaceholder}
              placeholder={placeholder}
              parentField={parentField}
            />
          }
          onChange={this.handleChange}
          removeSelected
          searchable
          simpleValue
          tabSelectsValue={false}
          valueKey={valueKey}
          {...rest}
          {...selectComponent}
          inputProps={{
            id: `input-${name}`,
            ...newInputProps,
            autoComplete: `new-${name}-${new Date().getTime()}`,
          }}
        />
      </Form.Group>
    );
  }
}

Select.defaultProps = {
  avatar: null,
  clearable: false,
  clearAllText: 'Limpar todos',
  clearValueText: 'Limpar',
  creatable: false,
  disabled: false,
  error: false,
  inputProps: null,
  label: null,
  labelKey: 'label',
  maxLength: null,
  multiple: false,
  name: null,
  noResultsText: 'Nenhum resultado encontrado.',
  onlyNumbers: false,
  placeholder: 'Selecione',
  loadingPlaceholder: 'Carregando...',
  promptTextCreator: null,
  required: null,
  parentField: null,
  valueKey: 'value',
  labelSelectAll: 'Selecionar Todos',
  filters: null,
  value: [],
  disableSelectAll: false,
  simpleValue: true,
  loading: false,
};

Select.propTypes = {
  avatar: PropTypes.node,
  classes: PropTypes.object.isRequired,
  clearable: PropTypes.bool,
  clearAllText: PropTypes.string,
  clearValueText: PropTypes.string,
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  simpleValue: PropTypes.bool,
  maxLength: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noResultsText: PropTypes.string,
  options: PropTypes.array.isRequired,
  onlyNumbers: PropTypes.bool,
  placeholder: PropTypes.string,
  promptTextCreator: PropTypes.func,
  required: PropTypes.bool,
  valueKey: PropTypes.string,
  labelSelectAll: PropTypes.string,
  filters: PropTypes.array,
  parentField: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  disableSelectAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  loadingPlaceholder: PropTypes.string,
  loading: PropTypes.bool,
};

export default withStyles(styles, { name: 'XVirtualizedSelect' })(Select);
