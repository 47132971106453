import { Card, Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Button } from '../../../../components';
import { montarParamsFiltroPaginacao } from '../../../utils/pagination';
import { throwFirstError } from '../../../utils/error';
import * as Apis from '../../Api';
import * as SnackbarDucks from '../../../shared/snackbar/SnackbarDucks';
import constants from '../../../shared/constants';
import Form from './Form';
import List from './List';
import paths from '../../../auth/paths';
import EconomiaBateriaDialog from './dialog/EconomiaBateriaDialog';
import UltraEconomiaBateriaDialog from './dialog/UltraEconomiaBateriaDialog';

const formName = 'RastreadorListarScreen';

const PAGINATION = {
  last: false,
  totalElements: 0,
  totalPages: 0,
  first: true,
  numberOfElements: 10,
  size: 10,
  number: 0,
};

const ORDER = {
  orderBy: 'id',
  orderDirection: constants.DEFAULT.DIRECAO.asc,
};

class Screen extends Component {
  state = {
    loading: false,
    dados: null,
    pagination: PAGINATION,
    order: ORDER,
    filtros: {},
    item: null,
    openEconomiaBateria: false,
    openUltraEconomiaBateria: false,
  };

  handleSubmit = async (values) => {
    await this.setState({ pagination: PAGINATION, order: ORDER });
    this.fetchMeusRastreadoresByFiltros(values);
  };

  fetchMeusRastreadoresByFiltros = async (filtros) => {
    this.setState({ loading: true });
    let { pagination, dados } = this.state;
    const { throwSnackbar } = this.props;
    const { order } = this.state;

    try {
      const params = montarParamsFiltroPaginacao({
        filtros,
        pagination,
        order,
      });
      const newParams = { ...params };
      const response = await Apis.fetchMeusRastreadoresByFiltros(
        newParams,
        constants.SETUP.defaultDelay.normal
      );
      const { content, ...paginationResponse } = response.data;
      pagination = paginationResponse;
      dados = content;
    } catch (error) {
      if (!throwFirstError(error)) {
        throwSnackbar(constants.MESSAGES.unavailableService);
      }
    }

    this.setState({
      loading: false,
      dados,
      pagination,
      filtros,
    });
  };

  handlePaginationChangePage = async (event, page) => {
    const { pagination, filtros } = this.state;
    await this.setState({ pagination: { ...pagination, number: page } });
    this.fetchMeusRastreadoresByFiltros(filtros);
  };

  handlePaginationChangeRowsPerPage = async (event) => {
    const { pagination, filtros } = this.state;
    await this.setState({
      pagination: { ...pagination, size: event.target.value },
    });
    this.fetchMeusRastreadoresByFiltros(filtros);
  };

  handleOpenEconomiaBateria = (item) => {
    this.setState({ openEconomiaBateria: true, item });
  };

  handleCloseEconomiaBateria = () => {
    this.setState({ openEconomiaBateria: false, item: null });
  };

  handleEconomiaBateria = async () => {
    const { throwSnackbar } = this.props;
    const { item, filtros } = this.state;
    try {
      await Apis.toggleEconomiaBateria(item);
      const situacao = item.economiaBateria === 'V' ? 'inativada' : 'ativada';
      throwSnackbar(`Economia de bateria ${situacao} com sucesso.`);
      this.fetchMeusRastreadoresByFiltros(filtros);
    } catch (error) {
      throwSnackbar(
        'Erro ao tentar ativar/inativar economia de bateria, tente novamente.'
      );
    } finally {
      this.handleCloseEconomiaBateria();
    }
  };

  handleOpenUltraEconomiaBateria = (item) => {
    this.setState({ openUltraEconomiaBateria: true, item });
  };

  handleCloseUltraEconomiaBateria = () => {
    this.setState({ openUltraEconomiaBateria: false, item: null });
  };

  handleUltraEconomiaBateria = async () => {
    const { throwSnackbar } = this.props;
    const { item, filtros } = this.state;
    try {
      await Apis.toggleUltraEconomiaBateria(item);
      const situacao =
        item.ultraEconomiaBateria === 'V' ? 'inativada' : 'ativada';
      throwSnackbar(`Ultra economia de bateria ${situacao} com sucesso.`);
      this.fetchMeusRastreadoresByFiltros(filtros);
    } catch (error) {
      throwSnackbar(
        'Erro ao tentar ativar/inativar ultra economia de bateria, tente novamente.'
      );
    } finally {
      this.handleCloseUltraEconomiaBateria();
    }
  };

  handleClean = () => {
    const { reset } = this.props;
    reset();
  };

  handleNovo = () => {
    const { history } = this.props;
    history.push(paths.MEUS_RASTREADORES_CADASTRAR.fullPath);
  };

  renderDialogEconomiaBateria = () => {
    const { openEconomiaBateria, item } = this.state;
    return (
      openEconomiaBateria && (
        <EconomiaBateriaDialog
          text={item.name}
          show={openEconomiaBateria}
          situacao={
            item.economiaBateria === 'V'
              ? 'inativar economia de bateria'
              : 'ativar economia de bateria'
          }
          onClose={this.handleCloseEconomiaBateria}
          onConfirm={this.handleEconomiaBateria}
        />
      )
    );
  };

  renderDialogUltraEconomiaBateria = () => {
    const { openUltraEconomiaBateria, item } = this.state;
    return (
      openUltraEconomiaBateria && (
        <UltraEconomiaBateriaDialog
          text={item.name}
          show={openUltraEconomiaBateria}
          situacao={
            item.ultraEconomiaBateria === 'V'
              ? 'inativar ultra economia de bateria'
              : 'ativar ultra economia de bateria'
          }
          onClose={this.handleCloseUltraEconomiaBateria}
          onConfirm={this.handleUltraEconomiaBateria}
        />
      )
    );
  };

  renderBotaoNovo = () => (
    <Row>
      <Col xs="12">
        <Button
          className="m-3"
          variant="success"
          onClick={this.handleNovo}
          disabled={this.state.loading}
        >
          <AddIcon />
        </Button>
      </Col>
    </Row>
  );

  render() {
    const { pagination, dados, order, loading } = this.state;
    const { handleSubmit } = this.props;
    const Novo = this.renderBotaoNovo;
    const EconomiaBateriaDialogConfirm = this.renderDialogEconomiaBateria;
    const UltraEconomiaBateriaDialogConfirm = this.renderDialogUltraEconomiaBateria;
    return (
      <>
        <Container fluid>
          <EconomiaBateriaDialogConfirm />
          <UltraEconomiaBateriaDialogConfirm />

          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Pesquisa</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form
                    {...this.props}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    onSubmit={this.handleSubmit}
                    onClean={this.handleClean}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-0">
                  <List
                    {...this.props}
                    loading={loading}
                    data={dados}
                    onOpenEconomiaBateria={this.handleOpenEconomiaBateria}
                    onOpenUltraEconomiaBateria={
                      this.handleOpenUltraEconomiaBateria
                    }
                    pagination={pagination}
                    order={order}
                    onChangePagination={this.handlePaginationChangePage}
                    onChangeNumberItems={this.handlePaginationChangeRowsPerPage}
                  />
                  <Novo />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Screen.propTypes = {
  reset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  throwSnackbar: PropTypes.func.isRequired,
  usuario: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: {},
  usuario: state.login.usuario,
});

const mapDispatchToProps = {
  throwSnackbar: SnackbarDucks.throwSnackbar,
};

const form = reduxForm({
  form: formName,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Screen));
