import { axiosPrivate } from '../../config/axios';
import { pMinDelay } from '../utils/promise';

const urlRest = '/api/rastreadores';
const urlUsuariosRastreadores = '/api/usuarios/rastreadores';
const urlRestLocalizacao = '/api/localizacoes';
const urlRestBateria = '/api/baterias';

export const fetchRastreadoresByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlRest, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};

export const fetchLocalizacoesByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlRestLocalizacao, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};

export const fetchBateriasByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlRestBateria, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};

export const fetchMeusRastreadoresByFiltros = (filtro, delay = 0) => {
  const request = axiosPrivate.get(urlUsuariosRastreadores, {
    params: {
      ...filtro,
    },
  });
  return pMinDelay(request, delay);
};

export const getRastreador = (id, delay = 0) => {
  const request = axiosPrivate.get(`${urlRest}/${id}`);
  return pMinDelay(request, delay);
};

export const getDetalheCompletoRastreador = (id, delay = 0) => {
  const request = axiosPrivate.get(`${urlRest}/${id}/detalhe`);
  return pMinDelay(request, delay);
};

export const getUsuarioRastreador = (id, delay = 0) => {
  const request = axiosPrivate.get(`${urlUsuariosRastreadores}/${id}`);
  return pMinDelay(request, delay);
};

export const saveRastreador = (values, delay = 0) => {
  const request = axiosPrivate.post(urlRest, values);
  return pMinDelay(request, delay);
};

export const salvaVinculoRastreador = (values, delay = 0) => {
  const request = axiosPrivate.post(urlUsuariosRastreadores, values);
  return pMinDelay(request, delay);
};

export const toggleAtivoInativo = (data, delay = 0) => {
  if (data.situacaoCodigo === 'INATIVO') {
    return pMinDelay(axiosPrivate.put(`${urlRest}/ativar/${data.id}`), delay);
  }
  return pMinDelay(axiosPrivate.delete(`${urlRest}/inativar/${data.id}`), delay);
};

export const toggleEconomiaBateria = (data, delay = 0) => {
  if (data.economiaBateria === 'V') {
    return pMinDelay(axiosPrivate.put(`${urlUsuariosRastreadores}/inativar-economia-bateria/${data.id}`), delay);
  }
  return pMinDelay(axiosPrivate.put(`${urlUsuariosRastreadores}/ativar-economia-bateria/${data.id}`), delay);
};

export const toggleUltraEconomiaBateria = (data, delay = 0) => {
  if (data.ultraEconomiaBateria === 'V') {
    return pMinDelay(axiosPrivate.put(`${urlUsuariosRastreadores}/inativar-ultra-economia-bateria/${data.id}`), delay);
  }
  return pMinDelay(axiosPrivate.put(`${urlUsuariosRastreadores}/ativar-ultra-economia-bateria/${data.id}`), delay);
};
