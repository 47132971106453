import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Form extends PureComponent {
  renderTotalLocalizacoes = () => {
    const { data } = this.props;
    const { totalLocalizacoes } = data;
    if (totalLocalizacoes > 10000) {
      return '+10000';
    }
    return `+${totalLocalizacoes}`;
  };

  render() {
    const { data } = this.props;
    const { totalUsuarios, totalRastreadores, totalLocalizacoes, totalChips } =
      data;

    return (
      <>
        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-single-02 text-warning" />
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category">Usuários</p>
                    <Card.Title as="h4">{totalUsuarios}</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-square-pin text-success" />
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category">Rastreadores</p>
                    <Card.Title as="h4">{totalRastreadores}</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-pin-3 text-danger" />
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category">Localizações</p>
                    <Card.Title as="h4">{totalLocalizacoes}</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-mobile text-primary" />
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category">Chips</p>
                    <Card.Title as="h4">{totalChips}</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  }
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Form;
